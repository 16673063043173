import React, { useEffect } from "react";
import {
  Anchor,
  Button,
  Loader,
  PasswordInput,
  Text,
  TextInput,
  useMantineColorScheme,
} from "@mantine/core";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useLazyConfirmEmailQuery, useLazyGetUserDataQuery, useLazySignInQuery } from "@/redux/api";
import GoogleAuthButton from "@/components/SignIn/components/GoogleAuthButton.tsx";
import { useMediaQuery } from "@mantine/hooks";
import ThemeService from "@/services/theme.ts";
import { Controller, useForm } from "react-hook-form";
import { LocalStorageService } from "@/services";
import { ROUTES } from "@/constants";
import { Alert } from "@/components/New";
import { FormLayout } from "@/components/New/components";
import { getErrorMessage } from "@/utils";

export const SignIn: React.FC = () => {
  const { colorScheme } = useMantineColorScheme();

  const [
    confirmEmail,
    { data: confirmEmailData, error: confirmEmailError, isFetching: isConfirmEmailFetching },
  ] = useLazyConfirmEmailQuery();

  const [getUserData] = useLazyGetUserDataQuery();

  const [signIn, { isFetching }] = useLazySignInQuery();

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const heightMatched = useMediaQuery("(min-height: 56.25em)");

  useEffect(() => {
    const token = searchParams.get("token");

    if (token) {
      confirmEmail(token);
    }
  }, [confirmEmail, searchParams]);

  const {
    clearErrors,
    control,
    formState: { errors },
    getValues,
    setError,
    handleSubmit,
  } = useForm<{
    username: string;
    password: string;
    details: string;
  }>();

  useEffect(() => {
    ThemeService.checkAndApplyTheme();
  }, [colorScheme]);

  useEffect(() => {
    if (LocalStorageService.getAuthToken()) {
      getUserData(undefined)
        .unwrap()
        .then((userData) => {
          if (userData) {
            navigate(ROUTES.APP);
          }
        });
    }
  }, [getUserData, navigate]);

  async function onFormSubmit() {
    const { error } = await signIn(getValues());

    if (error) {
      setError("details", {
        type: "manual",
        message: getErrorMessage(error) || "Unknown error",
      });
    } else {
      navigate(ROUTES.DOCUMENTS);
    }
  }

  const inputSize = heightMatched ? "xl" : "lg";

  return (
    <FormLayout>
      <form
        className="grid grid-row-[minmax(0,1fr)_repeat(5,auto)] gap-5"
        onSubmit={(event) => {
          clearErrors();
          handleSubmit(onFormSubmit)(event);
        }}
      >
        <div className="font-medium text-4xl text-ar-black">Welcome Back</div>
        <div className="max-w-[36rem] place-self-center">
          {searchParams.get("token") ? (
            isConfirmEmailFetching ? (
              <Loader type="dots" color="ar-accent" />
            ) : (
              <Alert
                visible={!!(confirmEmailData || confirmEmailError)}
                type={confirmEmailError ? "danger" : "success"}
              >
                {(confirmEmailError as string) || confirmEmailData}
              </Alert>
            )
          ) : null}
        </div>
        <Controller
          name="username"
          control={control}
          render={({ field }) => (
            <TextInput
              {...field}
              label={<span className="text-sm">Username or Email address</span>}
              size={inputSize}
              error={errors.username?.message}
              tabIndex={1}
              className="[&_.mantine-TextInput-label]:text-base"
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <PasswordInput
              {...field}
              label={<span className="text-sm">Password</span>}
              size={inputSize}
              error={errors.password?.message}
              tabIndex={2}
              className="[&_.mantine-PasswordInput-label]:text-base"
            />
          )}
        />
        <div className="flex flex-col justify-center items-center gap-6 mt-2">
          <Button
            type="submit"
            loading={isFetching}
            size={inputSize}
            fullWidth
            color="ar-accent"
            tabIndex={4}
          >
            Sign in
          </Button>
          <GoogleAuthButton size={inputSize} />
        </div>
        <div className="flex flex-col items-center gap-2 mt-4">
          <div className="flex flex-row justify-between items-center gap-2">
            <Text size="md">Forgot password?</Text>
            <Anchor
              component={Link}
              to={`${ROUTES.RESET_PASSWORD}/request`}
              c="ar-link"
              tabIndex={3}
            >
              Reset
            </Anchor>
          </div>
          <div className="flex flex-row justify-between items-center gap-2">
            <Text size="md">Don't have an account?</Text>
            <Anchor component={Link} to={ROUTES.SIGN_UP} c="ar-link">
              Sign up
            </Anchor>
          </div>
        </div>
        {errors.details ? (
          <div className="text-center text-ar-accent my-2">
            <span className="material-symbols-outlined px-2">error</span>
            <span>{errors.details.message}</span>
          </div>
        ) : null}
      </form>
    </FormLayout>
  );
};
