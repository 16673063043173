import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Anchor, Button, Checkbox, Modal, PasswordInput, Popover, TextInput } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { LocalStorageService } from "@/services";
import { useLazyGetUserDataQuery, useLazySignUpQuery } from "@/redux/api";
import { Controller, useForm } from "react-hook-form";
import GoogleAuthButton from "@/components/SignIn/components/GoogleAuthButton.tsx";
import { ROUTES } from "@/constants";
import { FormLayout } from "@/components/New/components";
import { getErrorMessage } from "@/utils";

export const SignUp: React.FC = () => {
  const [signUp] = useLazySignUpQuery();

  const [getUserData] = useLazyGetUserDataQuery();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isSignUpSuccessful, setIsSignUpSuccessful] = useState<boolean>(false);

  const [isAgreementsApplied, setIsAgreementsApplied] = useState<boolean>(false);

  const heightMatched = useMediaQuery("(min-height: 56.25em)");

  const navigate = useNavigate();

  const {
    clearErrors,
    control,
    formState: { errors },
    getValues,
    setError,
    handleSubmit,
  } = useForm<{
    email: string;
    full_name: string;
    is_active: boolean;
    password: string;
    username: string;
    details: string;
  }>({ defaultValues: { is_active: true } });

  async function onFormSubmit() {
    if (!isAgreementsApplied) {
      return;
    }

    setIsLoading(true);

    const { error } = await signUp(getValues());

    if (error) {
      setError("details", {
        type: "manual",
        message: getErrorMessage(error) || "Unknown error",
      });
    } else {
      setIsSignUpSuccessful(true);
    }

    setIsLoading(false);
  }

  useEffect(() => {
    if (LocalStorageService.getAuthToken()) {
      getUserData(undefined)
        .unwrap()
        .then((userData) => {
          if (userData) {
            navigate(ROUTES.APP);
          }
        });
    }
  }, [getUserData, navigate]);

  const inputSize = heightMatched ? "xl" : "lg";

  return (
    <>
      <FormLayout>
        <form
          className="grid grid-row-[minmax(0,1fr)_repeat(9,auto)] gap-5"
          onSubmit={(event) => {
            clearErrors();
            handleSubmit(onFormSubmit)(event);
          }}
        >
          <div className="pb-4 font-medium text-4xl text-ar-dark">Let's get started</div>
          <Controller
            name="username"
            control={control}
            render={({ field }) => (
              <TextInput
                {...field}
                label={<span className="text-sm">Username</span>}
                size="xl"
                required
              />
            )}
          />
          <Controller
            name="full_name"
            control={control}
            render={({ field }) => (
              <TextInput {...field} label={<span className="text-sm">Full name</span>} size="xl" />
            )}
          />
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextInput
                {...field}
                label={<span className="text-sm">Email address</span>}
                size="xl"
                required
                type="email"
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <PasswordInput
                {...field}
                label={<span className="text-sm">Password</span>}
                size="xl"
                required
              />
            )}
          />
          <div className="py-4">
            <Checkbox
              label={
                <span className="text-base">
                  I agree to all the&nbsp;
                  <Link to={ROUTES.TERMS} className="text-ar-link">
                    Terms
                  </Link>
                  &nbsp; and &nbsp;
                  <Link to={ROUTES.PRIVACY} className="text-ar-link">
                    Privacy policy
                  </Link>
                </span>
              }
              color="ar-accent"
              checked={isAgreementsApplied}
              onChange={() => setIsAgreementsApplied((prevState) => !prevState)}
            />
          </div>
          <Popover
            width={300}
            position="bottom"
            withArrow
            shadow="md"
            disabled={isAgreementsApplied}
          >
            <Popover.Target>
              <Button type="submit" loading={isLoading} size={inputSize} color="ar-accent">
                Sign up
              </Button>
            </Popover.Target>
            <Popover.Dropdown style={{ pointerEvents: "none" }}>
              <div className="flex flex-row justify-center items-center gap-4">
                <span className="material-symbols-outlined text-ar-accent">info</span>
                <span className="text-center">
                  Please, read and agree Terms and Privacy policy before
                </span>
              </div>
            </Popover.Dropdown>
          </Popover>
          <GoogleAuthButton size={inputSize} />
          <div className="text-center mt-4">
            <span className="mr-1">Have an account?</span>
            <Anchor component={Link} to={ROUTES.SIGN_IN} c="ar-link">
              Sign in
            </Anchor>
          </div>
          {errors.details ? (
            <div className="text-center text-ar-accent my-2">
              <span className="material-symbols-outlined px-2">error</span>
              <span>{errors.details.message}</span>
            </div>
          ) : null}
        </form>
      </FormLayout>
      <Modal
        opened={isSignUpSuccessful}
        withCloseButton={false}
        radius="md"
        centered
        onClose={() => {}}
      >
        <div className="flex justify-center items-center flex-col gap-8 p-4">
          <h1 className="text-2xl text-center">Your account has been created successfully!</h1>
          <div className="text-xl text-center">
            Please check your email to activate your account.
          </div>
          <p className="text-center">
            You may now sign in to the site. However, please note that the full functionality of the
            site is only available after your account has been activated. If you do not see an email
            message from us within a few minutes, please check your spam folder. In case you have
            any questions or need assistance, please contact us at &nbsp;
            <Anchor href="mailto:support@aracor.ai">support@aracor.ai</Anchor>.
          </p>
          <Button
            radius="md"
            size={inputSize}
            color="ar-accent"
            component={Link}
            to={ROUTES.SIGN_IN}
          >
            Proceed to Sign In
          </Button>
        </div>
      </Modal>
    </>
  );
};
