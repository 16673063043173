import React from "react";
import { NavLink } from "@mantine/core";
import clsx from "clsx/lite";

interface MenuItemProps {
  label: string;
  rightSection?: React.ReactNode;
  active?: boolean;
  className?: string;
  variant?: string;
  onClick: () => void;
}

const MenuItem = ({
  active,
  label,
  rightSection,
  variant = "filled",
  className = "",
  onClick,
}: MenuItemProps) => (
  <NavLink
    active={active}
    label={label}
    rightSection={rightSection}
    variant={variant}
    color="ar-accent"
    className={clsx("rounded-md", !active && "hover:!bg-ar-gray/10", className)}
    onClick={onClick}
  />
);

export default MenuItem;
