import { notifications } from "@mantine/notifications";
import debounce from "lodash/debounce";

const DEBOUNCE_TIME = 3000;

export const debouncedNotifications = {
  show: debounce(notifications.show, DEBOUNCE_TIME, {
    leading: true,
    trailing: false,
  }),
};
