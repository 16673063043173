import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

import {
  CSSVariablesResolver,
  MantineProvider,
  createTheme,
  localStorageColorSchemeManager,
} from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";

import store from "@/redux/store";
import * as Sentry from "@sentry/react";

import "./index.css";
import "@mantine/core/styles.css";
import "@mantine/notifications/styles.css";
import {
  CustomNextButton,
  CustomPrevButton,
  stepsData,
} from "./components/New/components/General/components/UserGuideTour";
import { TourProvider } from "@reactour/tour";
import Navigation from "@/router";

const aracorDefaultLight = createTheme({
  colors: {
    "ar-accent": [
      "#ffecea",
      "#fad9d7",
      "#edb2ae",
      "#e28882",
      "#D04035", // main
      "#d34f44",
      "#d14238",
      "#b9342a",
      "#a62c24",
      "#92221c",
    ],
    "ar-dark": [
      "#f6f4f4",
      "#e7e7e7",
      "#cccccc",
      "#b0b0b0",
      "#3C3431", // main
      "#8d8987",
      "#88817e",
      "#766e6b",
      "#6a615e",
      "#5f534e",
    ],
    "ar-beige": [
      "#fff6e8",
      "#f9ecd6",
      "#f1d6ab",
      "#e9c07d",
      "#F7E6CA", // main
      "#dfa13d",
      "#de9b2f",
      "#c48622",
      "#af771a",
      "#98660e",
    ],
    "ar-gray": [
      "#f1f4ff",
      "#e4e6ee",
      "#c8cad4",
      "#aaadba",
      "#B6B9C4", // main
      "#808597",
      "#777d91",
      "#656b7f",
      "#595f73",
      "#4a5168",
    ],
    "ar-link": [
      "#eaf1ff",
      "#d4dffd",
      "#a9bbf3",
      "#7a96ea",
      "#6672AF", // main
      "#3962de",
      "#2b58dd",
      "#1d49c5",
      "#1440b1",
      "#01379d",
    ],
    "ar-turquoise": [
      "#e0fffd",
      "#cffaf6",
      "#a5f2ea",
      "#77eade",
      "#40E0D0", // main
      "#38dfce",
      "#24ddcb",
      "#06c4b3",
      "#00ae9f",
      "#009789",
    ],
    "ar-brown": [
      "#fff2ed",
      "#ede5e2",
      "#d4cac7",
      "#bbada8",
      "#7F6961", // main
      "#99857e",
      "#937d75",
      "#816a62",
      "#745e56",
      "#684f46",
    ],
    "ar-red": [
      "#ffedeb",
      "#f8d8d6",
      "#eeafab",
      "#e6827c",
      "#B3261E", // main
      "#db463c",
      "#d9392f",
      "#c12c22",
      "#ad251d",
      "#971a16",
    ],
  },
});

const colorSchemeManager = localStorageColorSchemeManager({
  key: "theme", // for compatibility with tailwindcss
});

const resolver: CSSVariablesResolver = (theme) => ({
  variables: {
    "--mantine-line-height": "1.5rem",
  },
  light: {
    "--mantine-color-deep-orange": theme.other.deepOrangeLight,
  },
  dark: {
    "--mantine-color-deep-orange": theme.other.deepOrangeDark,
  },
});

Sentry.init({
  dsn: "https://6e64ae213beb7b0109bb5601db9717da@o4505964268224512.ingest.us.sentry.io/4506432829652992",
  integrations: [Sentry.replayIntegration()],
  // Session Replay settings
  // This sets the sample rate at 10%. You may want to change it to 100% while in development
  // and then sample at a lower rate in production.
  replaysSessionSampleRate: 0.1,
  // If you're not already sampling the entire session, change the sample rate to 100% when
  // sampling sessions where errors occur.
  replaysOnErrorSampleRate: 1.0,
  enabled:
    process.env.VITE_APP_MODE === "production" || process.env.VITE_APP_MODE === "development",
  environment: process.env.VITE_APP_MODE === "production" ? "prod" : "dev",
});

ReactDOM.createRoot(document.getElementById("root") as Element).render(
  <React.StrictMode>
    <Provider store={store}>
      <TourProvider
        steps={stepsData}
        showDots={false}
        showBadge={false}
        styles={{
          popover: (base) => ({
            ...base,
            borderRadius: 20,
          }),
          controls: (base) => ({ ...base, marginTop: 40, justifyContent: "flexStart", gap: 20 }),
        }}
        prevButton={CustomPrevButton}
        nextButton={CustomNextButton}
        position="right"
      >
        <MantineProvider
          theme={aracorDefaultLight}
          colorSchemeManager={colorSchemeManager}
          cssVariablesResolver={resolver}
          classNamesPrefix="mantine"
        >
          <Notifications position="bottom-center" />
          <ModalsProvider modalProps={{ centered: true, padding: "lg", radius: "md", size: "sm" }}>
            <Navigation />
          </ModalsProvider>
        </MantineProvider>
      </TourProvider>
    </Provider>
  </React.StrictMode>
);
