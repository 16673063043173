import React, { useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import throttle from "lodash/throttle";
import { EFontSize } from "@common/types";

interface StreamMessageProps {
  reader: ReadableStreamDefaultReader<Uint8Array> | null;
  fontSize?: EFontSize;
  onTypingDone: () => void;
}

const delay = () => new Promise((resolve) => setTimeout(resolve, 0));

const StreamMessage: React.FC<StreamMessageProps> = ({
  reader,
  fontSize = EFontSize.base,
  onTypingDone,
}) => {
  const [displayedText, setDisplayedText] = useState<string>("");
  const buffer = useRef<string>("");
  const isStreamDone = useRef<boolean>(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(
    throttle(
      () => {
        if (!reader) return;

        let bufferPosition: number = 0;

        buffer.current = "";
        isStreamDone.current = false;
        setDisplayedText("");

        (async () => {
          while (true) {
            const { done, value } = await reader.read();

            if (done) {
              isStreamDone.current = true;
              break;
            }

            buffer.current += new TextDecoder().decode(value);
          }
        })();

        let innerAnimationFrameId = 0;

        const topAnimationFrameId = requestAnimationFrame(async function typeText() {
          const bufferLength = buffer.current.length;

          while (bufferPosition < bufferLength) {
            setDisplayedText(
              buffer.current.slice(0, bufferPosition) + buffer.current.charAt(bufferPosition)
            );

            bufferPosition++;
            await delay();
          }

          if (isStreamDone.current) {
            if (bufferLength && bufferLength === bufferPosition) {
              if (topAnimationFrameId) cancelAnimationFrame(topAnimationFrameId);
              if (innerAnimationFrameId) cancelAnimationFrame(innerAnimationFrameId);

              setDisplayedText(buffer.current);

              onTypingDone();
            }
          } else {
            innerAnimationFrameId = requestAnimationFrame(typeText);
          }
        });
      },
      1000,
      { leading: true, trailing: false }
    ),
    [reader]
  );
  const fontSizeClassMap: Record<EFontSize, string> = {
    xs: "prose-xs",
    sm: "prose-sm",
    base: "prose-base",
    lg: "prose-lg",
    xl: "prose-xl",
  };

  return displayedText ? (
    <div className="bg-white rounded-tr-xl rounded-br-xl rounded-bl-xl p-2 text-base">
      <div
        className={`prose ${fontSizeClassMap[fontSize || "base"]} prose-strong:text-[--mantine-color-text] text-[color:var(--mantine-color-text)] pl-1 py-1`}
      >
        <ReactMarkdown
          className="after:content-['❚'] after:animate-pulse"
          components={{
            pre: ({ children }) => <p>{children}</p>,
            code: ({ children }) => <>{children}</>,
            p: ({ children }) => <span>{children}</span>,
          }}
        >
          {displayedText}
        </ReactMarkdown>
      </div>
    </div>
  ) : null;
};

export default StreamMessage;
