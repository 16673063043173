import React, { useCallback } from "react";
import { ActionIcon, ScrollArea, Switch, Tabs, Tooltip } from "@mantine/core";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import {
  documentsIndexApi,
  useDeleteChatHistoryMutation,
  useGetAppSettingsQuery,
  useGetUserSettingsQuery,
} from "@/redux/api";
import {
  getIsRedactView,
  getIsSelectedDocumentRedacted,
  getMode,
  getSelectedDocument,
  setHighlightedReference,
  setIsDocumentLoaded,
  setIsRedactView,
  setMode,
} from "@/redux/slices";
import { useAppDispatch, useAppSelector } from "@/redux/hooks.ts";
import { LocalStorageService, QaService } from "@/services";
import { ModeEnum } from "@common/types";
import ChatSettings from "./components/ChatSettings";

export const ChatHeader: React.FC = React.memo(() => {
  const dispatch = useAppDispatch();

  const mode = useAppSelector(getMode);

  const selectedDocument = useAppSelector(getSelectedDocument);
  const { data: appSettings } = useGetAppSettingsQuery(undefined);

  const { data: userSettings } = useGetUserSettingsQuery();

  const isRedactView = useAppSelector(getIsRedactView);

  const isSelectedDocumentRedacted = useAppSelector(getIsSelectedDocumentRedacted);

  const { isFetching: isCheckAndPrepareCollectionFetching } =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore : should be described as a generic type
    documentsIndexApi.endpoints.checkAndPrepareCollection.useQueryState(
      selectedDocument?.collection_id
    );

  const [deleteChatHistory] = useDeleteChatHistoryMutation();

  const handleDownloadChatHistory = useCallback(async () => {
    if (selectedDocument) {
      await QaService.downloadChatHistory(selectedDocument);
    }
  }, [selectedDocument]);

  const handleModeChange = (mode: string | null) => dispatch(setMode(mode));

  const handleRedactModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setIsDocumentLoaded(false));
    dispatch(setHighlightedReference(null));
    dispatch(setIsRedactView(event.target.checked));
  };

  const handleClearChatHistory = useCallback(async () => {
    modals.openConfirmModal({
      title: <span className="text-xl">Clear Chat History</span>,
      children: (
        <div className="flex flex-col gap-2">
          <p>Are you sure you would like to clear history for the current documents?</p>
          <p>
            Make sure to download and back up your chat history beforehand if you would like to save
            it.
          </p>
        </div>
      ),
      labels: {
        confirm: "Delete",
        cancel: "Cancel",
      },
      confirmProps: { color: "red" },
      closeOnConfirm: false,
      onConfirm: async () => {
        if (selectedDocument) {
          const notificationId = notifications.show({
            loading: true,
            title: "Clearing history",
            message: "Your chat history data will be cleared soon...",
            autoClose: false,
            withCloseButton: false,
          });

          await deleteChatHistory(selectedDocument.collection_id);

          notifications.hide(notificationId);

          modals.closeAll();

          notifications.show({
            title: "Clearing history",
            color: "green",
            message: "Chat history was successfully deleted",
          });
        }
      },
    });
  }, [selectedDocument]); // eslint-disable-line react-hooks/exhaustive-deps

  const tabClassName = `
    !text-white !text-lg !tracking-wide
    data-[active=true]:!border-b-4
    data-[active=true]:!border-b-ar-white
    aria-[selected=false]:!border-b-ar-dark
    data-[active=true]:!text-ar-white
    data-[active=true]:font-bold
    hover:bg-white/10`;

  return (
    <div className="font-['National'] flex flex-row justify-between items-center gap-2 p-4 overflow-hidden flex-wrap">
      <ScrollArea type="never" offsetScrollbars>
        <Tabs
          defaultValue={mode}
          color="white"
          radius={0}
          className="!w-full"
          onChange={handleModeChange}
        >
          <Tabs.List grow className="!flex-nowrap">
            <Tabs.Tab value={ModeEnum.BRIEF} className={tabClassName}>
              <span className="uppercase">{ModeEnum.BRIEF}</span>
            </Tabs.Tab>
            <Tabs.Tab value={ModeEnum.REDLINE} className={tabClassName}>
              <span className="uppercase">{ModeEnum.REDLINE}</span>
            </Tabs.Tab>
            {appSettings?.FEATURE_DUE_DILIGENCE_SUMMARY_ENABLED &&
              !userSettings?.is_demo_account && (
                <Tabs.Tab value={ModeEnum.SUMMARY} className={tabClassName}>
                  <span className="uppercase">{ModeEnum.SUMMARY}</span>
                </Tabs.Tab>
              )}
            {appSettings?.FEATURE_DUE_DILIGENCE_COMPARE_ENABLED &&
              !userSettings?.is_demo_account && (
                <Tabs.Tab value={ModeEnum.COMPARISON} className={tabClassName}>
                  <span className="uppercase">{ModeEnum.COMPARISON}</span>
                </Tabs.Tab>
              )}
          </Tabs.List>
        </Tabs>
      </ScrollArea>
      <div id="actionButtons" className="flex flex-row justify-between items-center w-full">
        {process.env.VITE_IS_REDACTION_ENABLED === "true" ? (
          <div className="flex flex-row justify-center items-center ml-2">
            <Tooltip
              label={
                "Switch between redacted and non-redacted document view. Only available on documents for which redaction was enabled during document upload. You may need to wait until document processing has finished to switch to the redacted view. Redacted view of scanned documents is currently not available."
              }
              multiline={true}
              w={250}
            >
              <div className="flex flex-row justify-center gap-2 items-center">
                <span
                  className="text-white"
                  // className={`text-white ${!isSelectedDocumentRedacted || isCheckAndPrepareCollectionFetching ? "text-white text-opacity-30" : ""}`}
                >
                  Redacted
                </span>
                <label className="flex flex-row flex-nowrap">
                  <Switch
                    checked={isRedactView}
                    color={LocalStorageService.isOldUI() ? "violet" : "ar-accent"}
                    disabled={!isSelectedDocumentRedacted || isCheckAndPrepareCollectionFetching}
                    size="xs"
                    className="self-end pb-0.5 !cursor-pointer"
                    onChange={handleRedactModeChange}
                  />
                </label>
              </div>
            </Tooltip>
          </div>
        ) : null}
        <div className="flex gap-2">
          {mode === ModeEnum.BRIEF ? (
            <>
              <Tooltip label="Download Chat History">
                <ActionIcon variant="transparent" onClick={handleDownloadChatHistory}>
                  <span className="material-symbols-outlined text-white">download</span>
                </ActionIcon>
              </Tooltip>
              <Tooltip label="Clear Chat History">
                <ActionIcon variant="transparent" onClick={handleClearChatHistory}>
                  <span className="material-symbols-outlined text-white">ink_eraser</span>
                </ActionIcon>
              </Tooltip>
              <ChatSettings mode={ModeEnum.BRIEF} />
            </>
          ) : null}
          {mode === ModeEnum.REDLINE ? (
            <>
              <ChatSettings mode={ModeEnum.REDLINE} />
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
});
