import { useEffect, useState } from "react";
import { UserService } from "@/services";
import { ResponseState } from "@common/types";
import { Link, useSearchParams } from "react-router-dom";
import { Button, Loader } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { ROUTES } from "@/constants";

export const EmailConfirmed = () => {
  const [searchParams] = useSearchParams();

  const [message, setMessage] = useState("Confirming your email...");

  const [isConfirmationSuccessful, setIsConfirmationSuccessful] = useState<boolean>(false);

  const heightMatched = useMediaQuery("(min-height: 56.25em)");

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const confirmEmail = async () => {
      const token = searchParams.get("token");

      if (!token) {
        setMessage("No email confirmation token provided.");
        return;
      }

      try {
        setIsLoading(true);
        const confirmEmailResponse = await UserService.confirmEmail(token);
        if (confirmEmailResponse.state === ResponseState.success) {
          setMessage("Your email address has been confirmed!");
          setIsConfirmationSuccessful(true);
        }
      } catch (error) {
        setMessage((error as Error).message);
      } finally {
        setIsLoading(false);
      }
    };

    confirmEmail();
  }, [searchParams]);

  const inputSize = heightMatched ? "xl" : "lg";

  return (
    <section className="grid grid-cols-[minmax(0,1fr)_40rem_minmax(0,1fr)] h-dvh w-dvw bg-ar-dark">
      <div className="h-12 w-1/2 m-4 border-b border-b-white/10">
        <Link to="/" className="font-['FinancierText'] text-2xl">
          <span className="text-white">Aracor</span>
          <span className="text-ar-accent">.</span>
        </Link>
      </div>
      <div
        className="
        self-center place-items-center
        flex flex-col gap-8
        px-0 py-10
        my-16
        bg-white dark:bg-slate-700
        rounded-lg
        overflow-y-auto"
      >
        <div className="text-2xl">{message}</div>
        {isLoading ? <Loader type="dots" color="ar-accent" className="mt-4" /> : null}
        {isConfirmationSuccessful ? (
          <Button radius="md" size={inputSize} color="ar-accent" component={Link} to={ROUTES.SIGN_IN}>
            Proceed to Sign In
          </Button>
        ) : null}
      </div>
    </section>
  );
};
