import { useLocation, useNavigate } from "react-router-dom";
import { useGetUserSettingsQuery, useLazyLogoutQuery } from "@/redux/api";
import MenuItem from "./components/MenuItem";
import { ROUTES } from "@/constants";

export const Menu = () => {
  const { pathname } = useLocation();

  const navigate = useNavigate();

  const [logout] = useLazyLogoutQuery();

  const { data: userSettings } = useGetUserSettingsQuery();

  const isActive = (id: string, itemPathname: string) =>
    (id == "home" && (pathname == ROUTES.APP || pathname == `${ROUTES.APP}/`)) ||
    (id != "home" && pathname.startsWith(itemPathname));

  if (!userSettings) return null;

  return (
    <div
      className="flex flex-col justify-between items-stretch font-semibold 
    [&_.mantine-NavLink-label]:text-lg !text-white"
    >
      <div className="flex flex-col gap-2">
        <MenuItem
          active={isActive("home", ROUTES.APP)}
          label="Home"
          rightSection={<span className="material-symbols-outlined text-white">dashboard</span>}
          onClick={() => {
            navigate(ROUTES.APP);
          }}
        />
        <MenuItem
          active={isActive("documents", ROUTES.DOCUMENTS)}
          label="Files & Folders"
          rightSection={<span className="material-symbols-outlined text-white">home_storage</span>}
          className="first-step"
          onClick={() => {
            navigate(ROUTES.DOCUMENTS);
          }}
        />
        {import.meta.env.VITE_APP_MODE !== "production" &&
          !userSettings?.is_demo_account && (
            <>
              <MenuItem
                active={isActive("research", ROUTES.RESEARCH)}
                label="Research"
                rightSection={
                  <span className="material-symbols-outlined text-white">manage_search</span>
                }
                onClick={() => {
                  navigate(ROUTES.RESEARCH);
                }}
              />
              <MenuItem
                active={isActive("diligence", ROUTES.DILIGENCE)}
                label="Due Diligence"
                rightSection={
                  <span className="material-symbols-outlined text-white">text_compare</span>
                }
                onClick={() => {
                  navigate(ROUTES.DILIGENCE);
                }}
              />
              <MenuItem
                active={isActive("help", ROUTES.HELP)}
                label="Legal Help"
                rightSection={<span className="material-symbols-outlined text-white">gavel</span>}
                onClick={() => {
                  navigate(ROUTES.HELP);
                }}
              />
            </>
          )}
      </div>
      <div className="flex flex-col gap-2">
        <MenuItem
          active={isActive("word_plugin", ROUTES.WORD)}
          label="MS Word Plugin"
          rightSection={
            <span
              className={`material-symbols-outlined ${
                isActive("settings", ROUTES.SETTINGS) ? "text-white" : "text-white/50"
              }`}
            >
              other_admission
            </span>
          }
          variant="filled"
          className={`${!isActive("settings", ROUTES.WORD) ? "!text-white/50" : ""} second-step`}
          onClick={() => navigate(ROUTES.WORD)}
        />
        <MenuItem
          active={isActive("settings", ROUTES.SETTINGS)}
          label="Settings"
          rightSection={
            <span
              className={`material-symbols-outlined ${
                isActive("settings", ROUTES.SETTINGS) ? "text-white" : "text-white/50"
              }`}
            >
              settings
            </span>
          }
          className={`${!isActive("settings", ROUTES.SETTINGS) ? "!text-white/50" : ""} second-step`}
          onClick={() => navigate(ROUTES.SETTINGS)}
        />
        <MenuItem
          label="Logout"
          rightSection={<span className="material-symbols-outlined text-white/50">logout</span>}
          variant="subtle"
          className="!text-white/50"
          onClick={async () => {
            await logout(undefined);
            navigate(ROUTES.HOME);
          }}
        />
      </div>
    </div>
  );
};
