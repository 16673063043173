import React from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";

const Tos: React.FC = React.memo(() => {
  return (
    <Worker workerUrl="/pdf.worker.min.js">
      <div className="[&_.rpv-core\_\_inner-pages]:!overflow-hidden">
        <Viewer fileUrl="/documents/tos.pdf" />
      </div>
    </Worker>
  );
});

export default Tos;
