import { Dispatch, SetStateAction } from "react";
import {
  IconCircleArrowLeft,
  IconCircleArrowRight,
  IconCircleArrowLeftFilled,
  IconCircleArrowRightFilled,
} from "@tabler/icons-react";

interface CustomButtonProps {
  setCurrentStep: Dispatch<SetStateAction<number>>;
  currentStep: number;
  stepsLength: number;
}

export const CustomNextButton: React.FC<CustomButtonProps> = ({
  setCurrentStep,
  stepsLength,
  currentStep,
}) => {
  const isLastStep = currentStep === stepsLength - 1;

  return (
    <div
      onClick={() => {
        if (!isLastStep) {
          setCurrentStep((prev) => prev + 1);
        }
      }}
    >
      {isLastStep ? <IconCircleArrowRight size={24} /> : <IconCircleArrowRightFilled size={24} />}
    </div>
  );
};

export const CustomPrevButton: React.FC<CustomButtonProps> = ({ setCurrentStep, currentStep }) => {
  const isFirstStep = currentStep === 0;

  return (
    <div onClick={() => setCurrentStep((prev: number) => Math.max(prev - 1, 0))}>
      {isFirstStep ? <IconCircleArrowLeft size={24} /> : <IconCircleArrowLeftFilled size={24} />}
    </div>
  );
};

export const stepsData = [
  {
    selector: ".first-step",
    content: "This is main navigation panel to switch between the platform models",
  },
  {
    selector: ".second-step",
    content: "This is the secondary navigation panel to navigate to settings or logout",
  },
];

