import api from "./api.ts";
import { ApiPayload, ForceOnlyOfficeSaveResponse, PrepareCollectionResponse } from "@common/types";
import { RootState } from "@/redux/store.ts";
import { setChatNotification } from "@/redux/slices";

export const documentsIndexApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    callPrepareCollectionStep: builder.query({
      query: (url) => ({
        url,
        method: "POST",
      }),
      transformResponse: (response: ApiPayload<PrepareCollectionResponse>) => response?.data,
    }),
    getIsCollectionPrepared: builder.query<boolean, string | undefined>({
      query: (collection_id) => ({
        url: "index/is_collection_prepared",
        params: {
          collection_id,
        },
      }),
      transformResponse: (response: ApiPayload<boolean>) => response?.data,
    }),
    prepareCollection: builder.query({
      query: (collection_id) => ({
        url: "index/prepare_collection",
        method: "POST",
        params: {
          collection_id,
        },
      }),
      transformResponse: (response: ApiPayload<PrepareCollectionResponse>) => response?.data,
    }),
    forceOnlyOfficeSave: builder.query({
      query: (collection_id) => ({
        url: "index/force_onlyoffice_save",
        method: "POST",
        params: {
          collection_id,
        },
      }),
      transformResponse: (response: ApiPayload<ForceOnlyOfficeSaveResponse>) => response?.data,
    }),
    checkAndPrepareCollection: builder.query<boolean, string | undefined>({
      async queryFn(collection_id, { getState, dispatch }) {
        try {
          await dispatch(
            documentsIndexApi.endpoints.forceOnlyOfficeSave.initiate(collection_id, {
              forceRefetch: true,
            })
          );

          const {
            data,
            isSuccess: isCollectionPreparedSuccess,
            isError: isCollectionPreparedError,
          } = await dispatch(
            documentsIndexApi.endpoints.getIsCollectionPrepared.initiate(collection_id, {
              forceRefetch: true,
            })
          );

          const isCollectionPrepared = data as boolean;

          if (isCollectionPreparedError) {
            (() => {
              throw new Error();
            })();
          }

          const { documents } = <RootState>getState();
          const isRedacted = documents.selectedDocument?.is_redacted;

          if (isCollectionPreparedSuccess && !isCollectionPrepared && collection_id) {
            dispatch(
              setChatNotification({
                id: collection_id,
                message: isRedacted ? "Redacting documents..." : "Processing documents...",
                progress: true,
              })
            );

            const {
              data: collection,
              isSuccess: isPrepareCollectionSuccess,
              isError: isPrepareCollectionError,
            } = await dispatch(
              documentsIndexApi.endpoints.prepareCollection.initiate(collection_id, {
                forceRefetch: true,
              })
            );

            if (isPrepareCollectionError) {
              (() => {
                throw new Error();
              })();
            }

            let nextUrl;
            let nextName;

            if (isPrepareCollectionSuccess) {
              nextUrl = collection?.next_url;
              nextName = collection?.next_name;

              while (nextUrl) {
                if (nextName) {
                  dispatch(
                    setChatNotification({
                      id: collection_id,
                      message: `${nextName}...`,
                      progress: true,
                    })
                  );
                }

                // @ts-expect-error - need to find and apply types in right way or reorder basic api structure
                const { data, isSuccess } = await dispatch(
                  documentsIndexApi.endpoints.callPrepareCollectionStep.initiate(nextUrl, {
                    forceRefetch: true,
                  })
                );

                if (isSuccess) {
                  nextUrl = data?.next_url;
                  nextName = data.next_name;
                } else {
                  nextUrl = null;
                  nextName = null;
                }
              }

              // Re-fetch the user file tree if the collection was redacted. This is needed because
              // redaction might have happened during the collection pre-processing stage, and the
              // document's `redacted_url` field should be filled in / updated. If we don't do this,
              // then the user will not be able to view the redacted view of the document for
              // newly uploaded documents.
              // This file tree re-fetching might be unnecessary in the future when we implement a
              // separate redaction tab/tool.
              if (isRedacted) {
                dispatch(
                  api.util.invalidateTags([
                    { type: "Documents", id: documents.selectedDocument?.id },
                  ])
                );
              }

              return { data: true };
            }
          }

          return { data: isCollectionPrepared };
        } catch {
          return {
            error: {
              error: "An error occurred while preparing collection.",
              status: "CUSTOM_ERROR",
            },
          };
        } finally {
          if (collection_id) {
            dispatch(setChatNotification({ id: collection_id, message: null }));
          }
        }
      },
    }),
  }),
});

export const { useLazyCheckAndPrepareCollectionQuery } = documentsIndexApi;
