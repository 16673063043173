import React, { useCallback, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/redux/hooks.ts";
import { throttle } from "lodash";
import { getLastMarkupProcessingTimestamp, getSelectedDocument } from "@/redux/slices/documents.ts";
import { handleScroll, scrollToTheBottom, SCROLL_BOTTOM_TOLERANCE, getErrorMessage } from "@/utils";
import { ChatNotification } from "@/components/Rio/components";
import {
  clearChatNotifications,
  getFontSize,
  getMode,
  setChatErrorNotification,
  setChatNotification,
  setQuestionText,
} from "@/redux/slices";
import {
  ApiErrorCodes,
  Criteria,
  IChatNotification,
  ReviewCollectionSuggestion,
} from "@common/types";
import {
  reviewApi,
  useLazyGetMarkupCriteriaQuery,
  useAddUserCriteriaMutation,
  useDeleteUserCriteriaMutation,
  useGetUserSettingsQuery,
  useLazyGetReviewSuggestionsQuery,
  useLazyPrepareReviewCollectionQuery,
  useUpdateReviewSuggestionsMutation,
  useLazyGetPlaybookCriteriaQuery,
  useLazyGetPlaybooksQuery,
  useLazyGetUserCriteriaQuery,
  useLazyForceOnlyOfficeSaveQuery,
  useLazyGetIsReviewCollectionPreparedQuery,
  useGetAppSettingsQuery,
} from "@/redux/api";
import {
  Accordion,
  ActionIcon,
  Button,
  Textarea,
  Menu,
  ScrollArea,
  AccordionControl,
} from "@mantine/core";
import { Controller, useForm } from "react-hook-form";
import { FeedbackMessage } from "./components/FeedbackMessage";
import { modals } from "@mantine/modals";
import { AddUserCriteria } from "@/components/modals/AddUserCriteria";
import { MetCriteriaCard, SuggestionCard } from "./components/Redline";
import { notifications } from "@mantine/notifications";
import { LocalStorageService } from "@/services";
import { ChatHeader } from "./ChatHeader";

const Review: React.FC = () => {
  const appDispatch = useAppDispatch();

  const selectedDocument = useAppSelector(getSelectedDocument);

  const { id: document_id } = selectedDocument ?? {};

  const fontSize = useAppSelector(getFontSize);

  const mode = useAppSelector(getMode);

  const lastMarkupProcessingTimestamp = useAppSelector(getLastMarkupProcessingTimestamp);

  const chatScrollableContainer = useRef<HTMLDivElement>(null);

  const chatTempContainerRef = useRef<HTMLDivElement>(null);

  const [metCriteriaHighlightedId, setMetCriteriaHighlightedId] = useState<number | null>(null);

  const [isAutoScrollEnabled, setIsAutoScrollEnabled] = useState<boolean>(true);

  const [isInitialLoadFinished, setIsInitialLoadFinished] = useState<boolean>(false);

  const { data: appSettings } = useGetAppSettingsQuery(undefined);

  const [isOnlyOfficeViewerEnabledForUser] = LocalStorageService.useUserPreference<boolean>(
    LocalStorageService.IS_ONLYOFFICE_ENABLED_KEY,
    false
  );

  const [getPlaybooksQuery, { data: availablePlaybooks, isSuccess: isPlaybooksGotSuccessfully }] =
    useLazyGetPlaybooksQuery();

  const { fulfilledTimeStamp: getUserSettingsFulfilledTimeStamp } = useGetUserSettingsQuery();

  const [getMarkupCriteria] = useLazyGetMarkupCriteriaQuery();

  const [getPlaybookCriteria] = useLazyGetPlaybookCriteriaQuery();

  const [getUserCriteria, { data: userCriteriaList }] = useLazyGetUserCriteriaQuery();

  const [addUserCriteria, { isLoading: isAddCriteriaLoading }] = useAddUserCriteriaMutation();

  const [deleteUserCriteria] = useDeleteUserCriteriaMutation();

  const [getIsReviewCollectionPrepared, { isFetching: isGetCollectionPreparedFetching }] =
    useLazyGetIsReviewCollectionPreparedQuery();

  const [
    getReviewSuggestions,
    {
      currentData: suggestionResult,
      originalArgs,
      isFetching: isSuggestionsFetching,
      isError: isSuggestionsError,
    },
  ] = useLazyGetReviewSuggestionsQuery();

  const [prepareReviewCollection, { isFetching: isPrepareReviewCollectionFetching }] =
    useLazyPrepareReviewCollectionQuery();

  const [updateReviewSuggestions, { isLoading: isUpdateReviewSuggestionsLoading }] =
    useUpdateReviewSuggestionsMutation();

  const [forceOnlyOfficeSave, { isFetching: isForceOnlyOfficeSaveFetching }] =
    useLazyForceOnlyOfficeSaveQuery();

  const { control, getValues, setValue, reset, watch } = useForm<{
    criteriaField: string;
  }>();

  const watchCriteria = watch("criteriaField"); // you can also target specific fields by their names

  const reviewChatHistory = React.useMemo(
    () => reviewApi.endpoints.getReviewChatHistory.select(selectedDocument?.collection_id),
    [selectedDocument]
  );

  useEffect(() => {
    if (originalArgs) {
      appDispatch(
        reviewApi.util.upsertQueryData(
          "getReviewSuggestions",
          { collection_id: originalArgs?.collection_id },
          { suggestions: [], documentWasChanged: false }
        )
      );
    }
  }, [selectedDocument]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsInitialLoadFinished(false);
    const { collection_id, id, document_original_extension } = selectedDocument || {};

    if (collection_id && id && document_original_extension === ".docx") {
      (async () => {
        appDispatch(clearChatNotifications(collection_id));
        try {
          await getUserCriteria();
          const criteria = await getMarkupCriteria(id).unwrap();
          // If there is criteria already, it means that user has worked with this document before,
          // so try to retrieve their pre-existing data
          if (criteria) {
            setValue("criteriaField", criteria);
            await getReviewSuggestions({ collection_id });
          } else if (suggestionResult?.suggestions.length) {
            reset({ criteriaField: "" });
          }

          // Check if the collection is prepared, and prepare it if needed
          await forceOnlyOfficeSave(collection_id);
          const isPrepared = await getIsReviewCollectionPrepared(collection_id).unwrap();
          if (!isPrepared) {
            await prepareReviewCollection(collection_id);
          }
          await getPlaybooksQuery(id);
        } catch (error) {
          if (error) {
            appDispatch(
              setChatErrorNotification({
                id: collection_id,
                actions:
                  getErrorMessage(error) === ApiErrorCodes["COLLECTION_WITH_SINGLE_DOCUMENT_ONLY"]
                    ? []
                    : ["reload", "close"],
                message:
                  getErrorMessage(error) ||
                  "An error occurred while processing your documents. Would you like us to try again? If the problem persists, please try again later or contact us at support@aracor.ai for further assistance.",
                type: "error",
              })
            );
          }
        }
      })();
    }
    setIsInitialLoadFinished(true);
  }, [selectedDocument, mode, getUserSettingsFulfilledTimeStamp, lastMarkupProcessingTimestamp]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    scrollToTheBottom(chatScrollableContainer);
  }, [reviewChatHistory]);

  const resizeChatContainerCallback = useCallback(
    (entries: ResizeObserverEntry[]) => {
      if (entries.length) {
        const chatContainer = entries[0];
        const { scrollTop, scrollHeight, clientHeight } = chatContainer.target;

        if (
          isAutoScrollEnabled &&
          scrollTop + clientHeight >= scrollHeight - SCROLL_BOTTOM_TOLERANCE
        ) {
          scrollToTheBottom(chatScrollableContainer);
        }
      }
    },
    [isAutoScrollEnabled]
  );

  useEffect(() => {
    const chatTempContainer: React.MutableRefObject<HTMLDivElement> =
      chatTempContainerRef as React.MutableRefObject<HTMLDivElement>;

    const resizeObserver = new ResizeObserver(resizeChatContainerCallback);

    if (chatTempContainer.current) {
      resizeObserver.observe(chatTempContainer.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [resizeChatContainerCallback]);

  const handleAccordionChange = (value: string | null) => {
    const suggestionById = suggestionResult?.suggestions.find(({ id }) => id.toString() === value);

    appDispatch(setQuestionText(suggestionById?.search_text ?? null));
    setMetCriteriaHighlightedId(null);
  };

  const handleAcceptSuggestion = (suggestion: ReviewCollectionSuggestion) => {
    updateReviewSuggestions([
      {
        ...suggestion,
        is_accepted: true,
        is_ignored: false,
      },
    ]);
  };

  const handleAcceptAll = () => {
    if (suggestionsGroups) {
      updateReviewSuggestions(
        suggestionsGroups?.issues.map((suggestion) => ({
          ...suggestion,
          is_accepted: true,
          is_ignored: false,
        }))
      );
    }
  };

  const handleDeclineSuggestion = (suggestion: ReviewCollectionSuggestion) => {
    updateReviewSuggestions([
      {
        ...suggestion,
        is_accepted: false,
        is_ignored: true,
      },
    ]);
  };

  const handleDeclineAll = () => {
    if (suggestionsGroups) {
      updateReviewSuggestions(
        suggestionsGroups?.issues.map((suggestion) => ({
          ...suggestion,
          is_accepted: false,
          is_ignored: true,
        }))
      );
    }
  };

  const handleAddUserCriteria = async () => {
    const criteria = getValues("criteriaField");

    if (selectedDocument) {
      await addUserCriteria({
        collection_id: selectedDocument.collection_id,
        document_id: selectedDocument.id,
        user_criteria: criteria,
      });

      await forceOnlyOfficeSave(selectedDocument.collection_id);
      const isPrepared = await getIsReviewCollectionPrepared(
        selectedDocument.collection_id
      ).unwrap();
      if (!isPrepared) {
        await prepareReviewCollection(selectedDocument.collection_id);
      }

      await getReviewSuggestions({ collection_id: selectedDocument.collection_id });
    }
  };

  const handleApplyUserCriteria = async (name: string, criteria: string) => {
    setValue("criteriaField", criteria);

    notifications.show({
      title: "Criteria Loaded",
      message: `Criteria from playbook "${name}" have been loaded.`,
      color: "green",
    });
  };

  const handleDeleteUserCriteria = (
    event: React.MouseEvent<HTMLButtonElement>,
    { id, name }: Criteria
  ) => {
    const currentTagName = event.currentTarget.tagName.toLowerCase();

    if (currentTagName === "svg" || currentTagName === "button") {
      modals.openConfirmModal({
        title: <span className="text-xl">Delete Custom Playbook</span>,
        children: <p>Are you sure you would like to delete the playbook named "{name}"?</p>,
        labels: { confirm: "Delete", cancel: "Cancel" },
        confirmProps: { color: "red" },
        onConfirm: () => {
          notifications.show({
            id: "DELETE_CUSTOM_PLAYBOOK_IN_PROGRESS",
            loading: true,
            title: "Deleting",
            message: `Deleting playbook "${name}"...`,
          });
          deleteUserCriteria(id);
          setValue("criteriaField", "");
          notifications.hide("DELETE_CUSTOM_PLAYBOOK_IN_PROGRESS");
          notifications.show({
            title: "Playbook Deleted",
            message: `Playbook "${name}" has been deleted.`,
            color: "green",
          });
        },
      });
    }
  };

  const handleGetPlaybookCriteria = async (playbookType: string) => {
    notifications.show({
      id: "GET_PLAYBOOK_CRITERIA_IN_PROGRESS",
      loading: true,
      title: "Loading",
      message: `Loading criteria for playbook "${playbookType}"...`,
    });

    const playbookCriteria = await getPlaybookCriteria({
      playbook_type: playbookType,
    }).unwrap();

    if (playbookCriteria) {
      setValue("criteriaField", playbookCriteria);
    }

    notifications.hide("GET_PLAYBOOK_CRITERIA_IN_PROGRESS");

    notifications.show({
      title: "Criteria Loaded",
      message: `Criteria from playbook "${playbookType}" have been loaded.`,
      color: "green",
    });
  };

  const handleMetCriteriaClick = useCallback(
    ({ search_text, id }: ReviewCollectionSuggestion) => {
      if (search_text || search_text !== "NA") {
        appDispatch(setQuestionText(search_text));
        setMetCriteriaHighlightedId(id);
      } else {
        appDispatch(setQuestionText(null));
      }
    },
    [appDispatch]
  );

  const handleSaveUserCriteria = async () => {
    if (document_id) {
      modals.open({
        title: "Add Custom Playbook",
        children: <AddUserCriteria documentId={document_id} />,
      });
    }
  };

  let notification: IChatNotification;

  const isRedacted = selectedDocument?.is_redacted;

  if (isPrepareReviewCollectionFetching) {
    notification = {
      message: isRedacted ? "Redacting documents..." : "Processing documents...",
      progress: true,
    };
  } else if (isSuggestionsFetching) {
    notification = {
      message: "Suggestions loading... (this may take a few minutes)",
      progress: true,
    };
  } else if (isUpdateReviewSuggestionsLoading) {
    notification = {
      message: "Updating suggestions...",
      progress: true,
    };
  } else if (isAddCriteriaLoading) {
    notification = {
      message: "Adding new playbook...",
      progress: true,
    };
  } else if (isForceOnlyOfficeSaveFetching) {
    notification = {
      message: "Saving document changes...",
      progress: true,
    };
  } else if (isGetCollectionPreparedFetching) {
    notification = {
      message: "Checking document status...",
      progress: true,
    };
  } else {
    notification = {
      message: null,
    };
  }

  if (selectedDocument?.collection_id) {
    appDispatch(setChatNotification({ id: selectedDocument?.collection_id, ...notification }));
  }

  const isDocx = selectedDocument?.document_original_extension === ".docx";

  const isSuggestionsReady = isDocx && suggestionResult?.suggestions.length && !isSuggestionsError;

  const suggestionsGroups = suggestionResult?.suggestions.reduce<{
    met: ReviewCollectionSuggestion[];
    issues: ReviewCollectionSuggestion[];
  }>(
    (acc, currentValue) => {
      if (currentValue.type_of_change == "NoChange") {
        acc.met.push(currentValue);
      } else {
        acc.issues.push(currentValue);
      }

      return acc;
    },
    { met: [], issues: [] }
  );

  const fontSizeClass = `text-${fontSize}`;

  return (
    <div className="grid grid-rows-[auto_auto_auto_1fr] h-full min-h-full w-full min-w-full bg-ar-dark rounded-l-xl pb-2">
      <ChatHeader />
      {isDocx ? (
        <div className="grid grid-rows-[1fr_auto] gap-2 px-4">
          <Controller
            name="criteriaField"
            control={control}
            render={({ field }) => (
              <Textarea
                {...field}
                color="white"
                placeholder="Enter your criteria or select a playbook from the dropdown. Then click the 'Get Suggestions' button to load suggestions for your document"
                size="lg"
                className="[&_.mantine-Textarea-wrapper]:bg-transparent [&_textarea]:font-['Commissioner'] [&_textarea]:h-32 [&_textarea:focus]:h-96 [&_textarea:focus]:transition-all"
              />
            )}
          />
          <div className="flex flex-row justify-center items-center gap-2 pb-4 w-full">
            <Menu position="bottom-end" shadow="md" zIndex={150}>
              <Menu.Target>
                <Button
                  className="flex-1"
                  disabled={!isPlaybooksGotSuccessfully}
                  rightSection={
                    <span className="material-symbols-outlined">keyboard_arrow_down</span>
                  }
                  justify="space-between"
                  fullWidth
                  variant="default"
                >
                  <span className="text-sm">Playbooks</span>
                </Button>
              </Menu.Target>
              <Menu.Dropdown>
                <ScrollArea h={300} type="auto" className="pr-3">
                  {userCriteriaList?.length ? (
                    <>
                      <Menu.Label className="!font-medium !text-ar-brown">Custom</Menu.Label>
                      {userCriteriaList?.map((criteria) => {
                        const { id, name, criteria: description } = criteria;

                        return (
                          <div key={id || name} className="flex justify-between items-center gap-2">
                            <Menu.Item
                              key={criteria.name}
                              onClick={() => handleApplyUserCriteria(name, description)}
                              className="pl-6 font-medium ml-2"
                            >
                              {name}
                            </Menu.Item>
                            <ActionIcon
                              variant="subtle"
                              size="xs"
                              onClick={(event) => handleDeleteUserCriteria(event, criteria)}
                            >
                              <span className="material-symbols-outlined text-ar-red">close</span>
                            </ActionIcon>
                          </div>
                        );
                      })}
                    </>
                  ) : null}
                  <Menu.Item>
                    <Button
                      className="flex-1 w-full !text-ar-dark"
                      variant="outline"
                      color="ar-dark"
                      rightSection={<span className="material-symbols-outlined">add</span>}
                      onClick={handleSaveUserCriteria}
                    >
                      Add custom playbook
                    </Button>
                  </Menu.Item>
                  {availablePlaybooks?.length ? (
                    <>
                      {availablePlaybooks
                        ?.slice()
                        .sort((a, b) =>
                          a.is_highlighted === b.is_highlighted ? 0 : a.is_highlighted ? -1 : 1
                        )
                        .map(({ contract_type, playbooks }) => (
                          <>
                            <Menu.Label className="!font-medium !text-ar-brown">
                              {contract_type}
                            </Menu.Label>
                            {playbooks.map((playbookName) => (
                              <Menu.Item
                                key={playbookName}
                                className="pl-6 font-medium ml-2"
                                onClick={() => handleGetPlaybookCriteria(playbookName)}
                              >
                                {playbookName}
                              </Menu.Item>
                            ))}
                          </>
                        ))}
                    </>
                  ) : null}
                </ScrollArea>
              </Menu.Dropdown>
            </Menu>

            <Button
              className="flex-1"
              variant="outline"
              color="ar-brown"
              rightSection={<span className="material-symbols-outlined">mark_unread_chat_alt</span>}
              disabled={
                !watchCriteria ||
                !isInitialLoadFinished ||
                isForceOnlyOfficeSaveFetching ||
                isGetCollectionPreparedFetching ||
                isPrepareReviewCollectionFetching ||
                isSuggestionsFetching ||
                isAddCriteriaLoading
              }
              onClick={handleAddUserCriteria}
            >
              Get Suggestions
            </Button>
          </div>
        </div>
      ) : (
        <div className="bg-red-200 p-4 m-4 rounded-xl">
          This functionality is available for DOCX files only
        </div>
      )}
      {selectedDocument?.collection_id ? (
        <ChatNotification collectionId={selectedDocument?.collection_id} />
      ) : null}
      {isSuggestionsReady ? (
        <>
          <div
            ref={chatScrollableContainer}
            onScroll={throttle(
              () => handleScroll(chatScrollableContainer, setIsAutoScrollEnabled),
              250
            )}
            className={`relative flex flex-col flex-1 overflow-y-auto mx-4 ${fontSizeClass}`}
          >
            <Accordion unstyled className={suggestionsGroups?.met.length ? "" : "hidden"}>
              <Accordion.Item value="met">
                <AccordionControl
                  chevron={<span className="material-symbols-outlined">arrow_drop_down</span>}
                  className="grid grid-cols-[1fr_auto] gap-2 [&_.mantine-Accordion-chevron]:self-center [&_.mantine-Accordion-chevron]:order-last bg-white p-4 rounded-[4px] w-full"
                >
                  <div className="grid grid-cols-[auto_1fr] gap-2 row-span-2">
                    <div>
                      <span className="material-symbols-outlined text-ar-turquoise">
                        check_circle
                      </span>
                    </div>
                    <div className="justify-self-start flex flex-col justify-start items-start">
                      <div className="pb-2 font-medium">
                        {suggestionsGroups?.met.length} criteria met
                      </div>
                    </div>
                  </div>
                </AccordionControl>
                <Accordion.Panel className="pl-4">
                  {suggestionsGroups?.met.map((criteria) => (
                    <MetCriteriaCard
                      key={criteria.id}
                      criteria={criteria}
                      isHighlighted={metCriteriaHighlightedId === criteria.id}
                      onClick={handleMetCriteriaClick}
                    />
                  ))}
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
            {appSettings?.FEATURE_ONLYOFFICE_ENABLED && isOnlyOfficeViewerEnabledForUser ? (
              <Accordion unstyled defaultValue="issues" chevron={<></>}>
                <Accordion.Item value="issues">
                  <AccordionControl
                    chevron={<span className="material-symbols-outlined">arrow_drop_down</span>}
                    className="grid grid-cols-[1fr_auto] gap-2 [&_.mantine-Accordion-chevron]:self-center [&_.mantine-Accordion-chevron]:order-last bg-white p-4 my-2 rounded-[4px] w-full"
                  >
                    <div className="grid grid-cols-[auto_1fr] gap-2 row-span-2">
                      <div>
                        <span className="material-symbols-outlined text-ar-red">check_circle</span>
                      </div>
                      <div className="justify-self-start flex flex-col justify-start items-start">
                        <div className="pb-2 font-medium">
                          {suggestionsGroups?.issues.length} issues found
                        </div>
                      </div>
                    </div>
                  </AccordionControl>
                </Accordion.Item>
              </Accordion>
            ) : (
              <Accordion unstyled defaultValue="issues">
                <Accordion.Item value="issues">
                  <AccordionControl
                    chevron={<span className="material-symbols-outlined">arrow_drop_down</span>}
                    className="grid grid-cols-[1fr_auto] gap-2 [&_.mantine-Accordion-chevron]:self-center [&_.mantine-Accordion-chevron]:order-last bg-white p-4 my-2 rounded-[4px] w-full"
                  >
                    <div className="grid grid-cols-[auto_1fr] gap-2 row-span-2">
                      <div>
                        <span className="material-symbols-outlined text-ar-red">check_circle</span>
                      </div>
                      <div className="justify-self-start flex flex-col justify-start items-start">
                        <div className="pb-2 font-medium">
                          {suggestionsGroups?.issues.length} issues found
                        </div>
                      </div>
                    </div>
                  </AccordionControl>
                  <Accordion.Panel className="pl-4">
                    <Accordion
                      chevron={<span className="material-symbols-outlined">arrow_drop_down</span>}
                      className="[&_.mantine-Accordion-chevron]:w-6 [&_.mantine-Accordion-chevron]:m-0.5 w-full"
                      onChange={handleAccordionChange}
                    >
                      {suggestionsGroups?.issues.map((suggestion) => (
                        <SuggestionCard
                          key={suggestion.id}
                          suggestion={suggestion}
                          onAccept={handleAcceptSuggestion}
                          onDecline={handleDeclineSuggestion}
                        />
                      ))}
                    </Accordion>
                  </Accordion.Panel>
                </Accordion.Item>
              </Accordion>
            )}
            {appSettings?.FEATURE_ONLYOFFICE_ENABLED && isOnlyOfficeViewerEnabledForUser ? null : (
              <div className="grid grid-cols-2 gap-2 pt-4 mb-10">
                <Button
                  variant="outline"
                  rightSection={<span className="material-symbols-outlined text-white">close</span>}
                  onClick={handleDeclineAll}
                  className="!border-ar-red"
                >
                  <span className="text-white text-base">Decline All</span>
                </Button>
                <Button
                  variant="outline"
                  rightSection={<span className="material-symbols-outlined text-white">check</span>}
                  onClick={handleAcceptAll}
                  className="!border-ar-turquoise"
                >
                  <span className="text-white text-base">Accept All</span>
                </Button>
              </div>
            )}
            <FeedbackMessage />
          </div>
        </>
      ) : null}
    </div>
  );
};

export default Review;
