import api from "@/api.ts";

import { mapToResponsePayload, normalizeResponseError } from "@/utils";
import { ApiPayload, ResponsePayload, User, UserSettings } from "@common/types";

const fetchUserData = async (): Promise<ResponsePayload<User>> => {
  try {
    const { data } = await api.get<ApiPayload<User>>("/users/me", {
      headers: {
        accept: "application/json",
      },
    });

    return mapToResponsePayload(data);
  } catch (error: unknown) {
    throw new Error(normalizeResponseError(error));
  }
};

const fetchUsers = async (): Promise<User[] | null> => {
  try {
    const { data } = await api.get("/users/read_users", {
      headers: {
        accept: "application/json",
      },
    });

    return typeof data === "object" ? data : null;
  } catch {
    return null;
  }
};

const requestEmailConfirmation = async (): Promise<ResponsePayload> => {
  try {
    const { data } = await api.post<ApiPayload<void>>("/users/request_email_confirmation", {
      headers: {
        accept: "application/json",
      },
    });

    return mapToResponsePayload(data);
  } catch (error: unknown) {
    throw new Error(normalizeResponseError(error));
  }
};

const confirmEmail = async (token: string): Promise<ResponsePayload> => {
  try {
    const { data } = await api.get<ApiPayload<void>>("/users/confirm_email?token=" + token);

    return mapToResponsePayload(data);
  } catch (error: unknown) {
    throw new Error(normalizeResponseError(error));
  }
};

const updateUserData = async (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore // TODO(Reinis): Remove this later
  updatedEmail?: string,
  updatedFullName?: string
): Promise<ResponsePayload<User>> => {
  try {
    const { data } = await api.put<ApiPayload<User>>("/users/update_user_data", null, {
      headers: {
        accept: "application/json",
      },
      params: {
        // updated_email: updatedEmail, // TODO(Reinis): Add this later
        updated_full_name: updatedFullName,
      },
    });

    return mapToResponsePayload(data);
  } catch (error: unknown) {
    throw new Error(normalizeResponseError(error));
  }
};

const getUserSettings = async (): Promise<ResponsePayload<UserSettings>> => {
  try {
    const { data } = await api.get<ApiPayload<UserSettings>>("/users/get_user_settings", {
      headers: {
        accept: "application/json",
      },
    });

    return mapToResponsePayload(data);
  } catch (error: unknown) {
    throw new Error(normalizeResponseError(error));
  }
};

const updateUserSettings = async (
  settings: UserSettings
): Promise<ResponsePayload<UserSettings>> => {
  try {
    const { data } = await api.put<ApiPayload<UserSettings>>(
      "/users/update_user_settings",
      settings,
      {
        headers: {
          accept: "application/json",
        },
      }
    );

    return mapToResponsePayload(data);
  } catch (error: unknown) {
    throw new Error(normalizeResponseError(error));
  }
};

const UserService = {
  fetchUserData,
  fetchUsers,
  requestEmailConfirmation,
  confirmEmail,
  updateUserData,
  getUserSettings,
  updateUserSettings,
};

export default UserService;
