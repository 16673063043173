import { useGetUserDataQuery } from "@/redux/api";
import { Upload } from "@/components/New/components/General/components/Upload";
import { ActionIcon } from "@mantine/core";
import { useTour } from "@reactour/tour";

export const Home = () => {
  const { data } = useGetUserDataQuery(undefined);

  const { setIsOpen } = useTour();

  return (
    <div className="flex flex-col gap-6 p-16">
      <div className="w-1/2 py-8">
        <span className={`${data ? "opacity-100" : "opacity-0"} transition-opacity`}>
          <span className="text-5xl">
            {data?.full_name ? `Hello, ${data?.full_name}. ` : "Hello."}
            <span>How may Aracor help you today?</span>
            {/* TODO(Ihor, Alexey, Mykola) Add 2 scenarios: for new and recurring users */}
            {/* {data?.full_name ? `Welcome, ${data?.full_name}. ` : "Welcome to Aracor. "} */}
            {/* <span>This is your first time here, so below are the things you can start with</span> */}
          </span>
        </span>
      </div>
      <div className="grid grid-cols-3 gap-4">
        <div
          className="grid grid-cols-[1fr_30%] gap-4 p-5 bg-ar-beige text-ar-dark rounded-xl hover:cursor-pointer"
          onClick={() => setIsOpen(true)}
        >
          <div className="font-semibold text-lg">Run product demo</div>
          <div className="flex flex-row justify-end items-center h-full w-full row-span-2">
            <ActionIcon
              variant="filled"
              size="xl"
              radius="xl"
              color="white"
              className="hover:!bg-ar-gray/40"
            >
              <span className="material-symbols-outlined text-ar-dark">tooltip_2</span>
            </ActionIcon>
          </div>
          <div className="text-ar-brown">A quick interactive onboarding to show you around</div>
        </div>
        <div className="grid grid-cols-[1fr_30%] gap-4 p-5 bg-ar-beige text-ar-dark rounded-xl hover:cursor-pointer">
          <div className="font-semibold text-lg">Watch Instructional Videos</div>
          <div className="flex flex-row justify-end items-center h-full w-full row-span-2">
            <ActionIcon
              variant="filled"
              size="xl"
              radius="xl"
              color="white"
              className="hover:!bg-ar-gray/40"
            >
              <span className="material-symbols-outlined text-ar-dark">play_arrow</span>
            </ActionIcon>
          </div>
          <div className="text-ar-brown">Product discovery with help of the people behind it</div>
        </div>
        <div className="grid grid-cols-[1fr_30%] gap-4 p-5 bg-ar-beige text-ar-dark rounded-xl hover:cursor-pointer">
          <div className="font-semibold text-lg">See what’s new</div>
          <div className="flex flex-row justify-end items-center h-full w-full row-span-2">
            <ActionIcon
              variant="filled"
              size="xl"
              radius="xl"
              color="white"
              className="hover:!bg-ar-gray/40"
            >
              <span className="material-symbols-outlined text-ar-dark">description</span>
            </ActionIcon>
          </div>
          <div className="text-ar-brown">
            The latest release checklist with up-to-date platform’s features showcase
          </div>
        </div>
      </div>
      <Upload />
    </div>
  );
};
