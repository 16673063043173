import { rem } from "@mantine/core";
import React from "react";

interface AddressBookIconProps extends React.ComponentPropsWithoutRef<"svg"> {
  size?: number | string;
}

export function Logo({ size, style, ...others }: AddressBookIconProps) {
  return (
    <svg
      width="19"
      height="14"
      viewBox="0 0 19 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: rem(size), height: rem(size), ...style }}
      {...others}
    >
      <path
        d="M6.924 2.022L5.186 7.016H8.706L6.924 2.022ZM5.252 12.076V13H0.808V12.076H2.216L6.55 0.196H8.244L12.71 12.076H14.074V13H8.926V12.076H10.532L9.036 7.918H4.856L3.404 12.076H5.252ZM15.2538 11.79C15.2538 10.998 15.8918 10.36 16.6838 10.36C17.4758 10.36 18.1138 10.998 18.1138 11.79C18.1138 12.582 17.4758 13.22 16.6838 13.22C15.8918 13.22 15.2538 12.582 15.2538 11.79Z"
        fill="white"
      />
    </svg> 
  );
}
