import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getSelectedDocument } from "@/redux/slices/documents.ts";
import {
  useLazyCheckIsComparisonCollectionPreparedQuery,
  usePrepareComparisonCollectionMutation,
  useLazyGetCollectionComparisonQuery,
} from "@/redux/api";
import DueDiligenceContent from "./components/DueDiligenceTabContent";
import { DEFAULT_ERROR_MESSAGE } from "@/components/common/constants";

const Comparison: React.FC = () => {
  const selectedDocument = useSelector(getSelectedDocument);
  const [isLoading, setIsLoading] = useState(false);
  const [errorText, setErrorText] = useState("");

  const [checkIsComparisonCollectionPrepared] = useLazyCheckIsComparisonCollectionPreparedQuery();
  const [prepareComparisonCollection] = usePrepareComparisonCollectionMutation();
  const [getCollectionComparison, { currentData: comparisonData }] =
    useLazyGetCollectionComparisonQuery();

  const { collection_id: collectionId } = selectedDocument ?? {};

  const getComparison = async (collectionId: string | undefined) => {
    if (collectionId) {
      setIsLoading(true);
      try {
        const isComparisonCollectionPrepared = await checkIsComparisonCollectionPrepared({
          collectionId,
        }).unwrap();
        if (!isComparisonCollectionPrepared) {
          await prepareComparisonCollection({ collectionId }).unwrap();
        }

        await getCollectionComparison({ collectionId }).unwrap();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        setErrorText(e.data.data.detail[0] || DEFAULT_ERROR_MESSAGE);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (collectionId) {
      getComparison(collectionId);
    }
  }, [collectionId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DueDiligenceContent
      isLoading={isLoading}
      errorText={errorText}
      text={comparisonData?.comparison_content}
      setErrorText={setErrorText}
      handleRetry={() => getComparison(collectionId)}
    />
  );
};

export default Comparison;
