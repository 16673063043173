import api from "./api.ts";

import { ApiPayload, ChatResponse, IQaChatHistory } from "@common/types";
import { setReferencesForMessage, setChatNotification, setReferences } from "@/redux/slices";

export const qaApi = api.injectEndpoints({
  endpoints: (builder) => ({
    chat: builder.mutation<ChatResponse, { collection_id: string; query: string }>({
      query: ({ collection_id, query }) => ({
        url: "qa/chat",
        method: "POST",
        params: {
          collection_id,
          query,
        },
      }),
      async onQueryStarted({ collection_id }, { dispatch, queryFulfilled }) {
        const queryResult = await queryFulfilled;

        if (queryResult) {
          dispatch(
            qaApi.util.updateQueryData(
              "getChatHistory",
              collection_id,
              (draft: IQaChatHistory[]) => {
                draft.push(queryResult.data.query_message);
              }
            )
          );
        }
      },
      transformResponse: (response: ApiPayload<ChatResponse>) => response?.data ?? null,
    }),
    deleteChatHistory: builder.mutation({
      query: (collection_id) => ({
        url: "qa/delete_chat_history",
        method: "DELETE",
        params: {
          collection_id,
        },
      }),
      invalidatesTags: ["Chat"],
    }),
    getChatHistory: builder.query({
      query: (collection_id) => ({
        url: "qa/get_chat_history",
        params: {
          collection_id,
        },
      }),
      transformResponse: (response: ApiPayload<IQaChatHistory[]>) => response?.data ?? [],
      providesTags: (data) =>
        data
          ? [...data.map(({ id }) => ({ type: "Chat" as const, id })), { type: "Chat", id: "LIST" }]
          : [{ type: "Chat", id: "LIST" }],
      async onQueryStarted(collection_id, { dispatch, queryFulfilled }) {
        dispatch(
          setChatNotification({
            id: collection_id,
            message: "Loading chat history...",
            progress: true,
          })
        );

        const { data } = await queryFulfilled;

        dispatch(setChatNotification({ id: collection_id, message: null }));
        const references = data?.map(({ references }) => references ?? []).flat();
        dispatch(setReferences(references));
      },
    }),
    getChatMessageReferences: builder.query<
      IQaChatHistory | null,
      { message_id: number; collection_id?: string; refresh?: boolean }
    >({
      query: ({ message_id, refresh }) => ({
        url: "qa/get_chat_message_references",
        params: {
          message_id,
          refresh,
        },
      }),
      async onQueryStarted(
        { collection_id, message_id, refresh },
        { dispatch, queryFulfilled, getCacheEntry }
      ) {
        const queryResult = await queryFulfilled;

        if (queryResult) {
          dispatch(
            qaApi.util.updateQueryData(
              "getChatHistory",
              collection_id,
              (draft: IQaChatHistory[]) => {
                if (refresh) {
                  const cache = getCacheEntry();
                  const indexToUpdate = draft.findIndex(({ id }) => id === message_id);

                  if (indexToUpdate > -1 && draft?.length) {
                    draft[indexToUpdate] = {
                      ...(queryResult.data as IQaChatHistory),
                      references: cache?.data?.references ?? [],
                    };
                  }
                } else {
                  draft.push(queryResult.data as IQaChatHistory);
                }
              }
            )
          );
          const references = queryResult.data?.references ?? [];
          const messageId = queryResult.data?.id;
          if (messageId) {
            dispatch(setReferencesForMessage({ messageId: messageId, references: references }));
          }
        }
      },
      transformResponse: (response: ApiPayload<IQaChatHistory>) => response?.data ?? null,
    }),
    getSuggestedQuestions: builder.query({
      query: ({ collection_id }) => ({
        url: "qa/get_suggested_questions",
        params: {
          collection_id,
        },
      }),
      transformResponse: (response: ApiPayload<string[]>) => response?.data ?? [],
      providesTags: ["SuggestedQuestions"],
    }),
  }),
});

export const {
  useChatMutation,
  useDeleteChatHistoryMutation,
  useLazyGetChatHistoryQuery,
  useLazyGetChatMessageReferencesQuery,
  useLazyGetSuggestedQuestionsQuery,
} = qaApi;
