import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
  useRef,
  useMemo,
} from "react";
import {
  ActionIcon,
  Loader,
  Menu,
  ScrollArea,
  Textarea,
  Skeleton,
  Tooltip,
  Button,
} from "@mantine/core";
import api, {
  useAddFavoriteQuestionMutation,
  useGetFavoriteQuestionsQuery,
  useRemoveFavoriteQuestionMutation,
} from "@/redux/api";
import { Question } from "@common/types";
import { useAppSelector } from "@/redux/hooks.ts";
import { getSelectedDocument } from "@/redux/slices";
import { Controller, UseFormReturn } from "react-hook-form";

interface ExplainInputProps {
  disabled: boolean;
  form: UseFormReturn<{ question: string }>;
  onKeyDown: (event: React.KeyboardEvent<HTMLTextAreaElement>) => Promise<void>;
  onSendQuery: (scrollDown?: boolean) => Promise<void>;
  processingMessage?: string;
}

export const ExplainInput = forwardRef<HTMLTextAreaElement, ExplainInputProps>(
  ({ disabled, form, onKeyDown, onSendQuery, processingMessage }, ref) => {
    const {
      control,
      formState: { isDirty },
      setValue,
    } = form;
    const innerRef = useRef<HTMLTextAreaElement>(null);

    const currentQuestionId = useRef<number>(0);

    const selectedDocument = useAppSelector(getSelectedDocument);

    const {
      data: favoriteQuestionsData,
      isLoading: isFavoriteQuestionsLoading,
      isFetching: isFavoriteQuestionsFetching,
      fulfilledTimeStamp: favoriteQuestionsFulfilledTimeStamp,
    } = useGetFavoriteQuestionsQuery(undefined);

    const {
      data: suggestedQuestionsData,
      isLoading: isSuggestedQuestionsLoading,
      isFetching: isSuggestedQuestionsFetching,
    } =
      // @ts-expect-error : should be described as a generic type
      api.endpoints.getSuggestedQuestions.useQueryState({
        collection_id: selectedDocument?.collection_id,
      });

    const [addFavoriteQuestion] = useAddFavoriteQuestionMutation();

    const [removeFavoriteQuestion, { isLoading: isRemoveFavoriteQuestionLoading }] =
      useRemoveFavoriteQuestionMutation();

    const [theSameQuestions, setTheSameQuestions] = useState<
      Map<string, { isSelected: boolean; id?: number }>
    >(new Map());

    const [isSuggestedMenuOpened, setIsSuggestedMenuOpened] = useState(false);
    const [isFavoritesMenuOpened, setIsFavoritesMenuOpened] = useState(false);

    useEffect(() => {
      if (favoriteQuestionsData && suggestedQuestionsData) {
        const theSameQuestions = suggestedQuestionsData?.reduce(
          (acc: Map<string, { isSelected: boolean; id?: number }>, value: string) => {
            const isTheSameQuestion = favoriteQuestionsData.find(
              ({ question }: Question) => question === value
            );
            acc.set(value, { isSelected: !!isTheSameQuestion, id: isTheSameQuestion?.id });
            return acc;
          },
          new Map()
        );

        setTheSameQuestions(theSameQuestions);
      }
    }, [favoriteQuestionsFulfilledTimeStamp]); // eslint-disable-line react-hooks/exhaustive-deps

    useImperativeHandle<HTMLTextAreaElement | null, HTMLTextAreaElement | null>(
      ref,
      () => innerRef.current
    );

    const handleQuestionClick = (question: string) => {
      setValue("question", question, { shouldDirty: true });
    };

    const handleRemoveFavoriteQuestion = (event: React.MouseEvent<HTMLDivElement>, id: number) => {
      event.stopPropagation();
      currentQuestionId.current = id;
      removeFavoriteQuestion({ question_id: id });
    };

    const handleSendQuestionClick = (event: React.MouseEvent<HTMLDivElement>, question: string) => {
      event.stopPropagation();

      setIsSuggestedMenuOpened(false);
      setIsFavoritesMenuOpened(false);

      if (question) {
        setValue("question", question);
        onSendQuery(true);
      }
    };

    const handleSuggestedQuestionStarClick = async (
      event: React.MouseEvent<HTMLDivElement>,
      question: string,
      id?: number
    ) => {
      event.stopPropagation();

      if (question) {
        if (id) {
          await removeFavoriteQuestion({ question_id: id });
        } else {
          await addFavoriteQuestion({ question: question });
        }
      }
    };

    const favoriteQuestions = favoriteQuestionsData?.map(({ id, question }: Question) => {
      return (
        <Menu.Item
          key={id}
          leftSection={
            isRemoveFavoriteQuestionLoading && currentQuestionId.current === id ? (
              <Loader size="xs" color="ar-accent" />
            ) : (
              <Tooltip label="Remove from Favorites" openDelay={1000}>
                <div
                  className="relative group/favorites-star"
                  onClick={(event) => handleRemoveFavoriteQuestion(event, id)}
                >
                  <span
                    className="material-symbols-outlined absolute top-0 right-0 group-hover/favorites-star:invisible text-ar-accent"
                    style={{ fontVariationSettings: '"FILL" 1' }}
                  >
                    star
                  </span>
                  <span className="material-symbols-outlined invisible group-hover/favorites-star:visible text-ar-accent">
                    star_half
                  </span>
                </div>
              </Tooltip>
            )
          }
          rightSection={
            <div className="max-h-6 flex items-center invisible group-hover/favorites-send:visible">
              <Tooltip label="Send" openDelay={1000}>
                <div
                  className="hover:bg-indigo-200 rounded-sm"
                  onClick={(event) => handleSendQuestionClick(event, question)}
                >
                  <span className="material-symbols-outlined text-ar-accent">send</span>
                </div>
              </Tooltip>
            </div>
          }
          className="group/favorites-send"
          onClick={() => handleQuestionClick(question)}
        >
          {question}
        </Menu.Item>
      );
    });

    const suggestedQuestions = useMemo(
      () =>
        suggestedQuestionsData?.map((question: string, index: number) => {
          const id = theSameQuestions.has(question)
            ? theSameQuestions.get(question)?.id
            : undefined;

          return (
            <Menu.Item
              key={index}
              leftSection={
                <div onClick={(event) => handleSuggestedQuestionStarClick(event, question, id)}>
                  {id ? (
                    <Tooltip label="Remove from Favorites" openDelay={1000}>
                      <div className="relative group/suggested-star">
                        <span
                          className="material-symbols-outlined absolute top-0 right-0 group-hover/suggested-star:invisible text-ar-accent"
                          style={{ fontVariationSettings: '"FILL" 1' }}
                        >
                          star
                        </span>
                        <span className="material-symbols-outlined invisible group-hover/suggested-star:visible text-ar-accent">
                          star_half
                        </span>
                      </div>
                    </Tooltip>
                  ) : (
                    <Tooltip label="Add to Favorites" openDelay={1000}>
                      <div className="relative group/suggested-star">
                        <span className="material-symbols-outlined absolute top-0 right-0 group-hover/suggested-star:invisible text-ar-accent">
                          star
                        </span>
                        <span
                          className="material-symbols-outlined invisible group-hover/suggested-star:visible text-ar-accent"
                          style={{ fontVariationSettings: '"FILL" 1' }}
                        >
                          star
                        </span>
                      </div>
                    </Tooltip>
                  )}
                </div>
              }
              rightSection={
                <div className="max-h-6 flex items-center invisible group-hover/suggested-send:visible">
                  <Tooltip label="Send" openDelay={1000}>
                    <div
                      className="hover:bg-indigo-200 rounded-sm"
                      onClick={(event) => handleSendQuestionClick(event, question)}
                    >
                      <span className="material-symbols-outlined text-ar-accent">send</span>
                    </div>
                  </Tooltip>
                </div>
              }
              className="group/suggested-send"
              onClick={() => handleQuestionClick(question)}
            >
              {question}
            </Menu.Item>
          );
        }),
      [suggestedQuestionsData, theSameQuestions] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const isFavoriteQuestionsEmpty = !Array.isArray(favoriteQuestions) || !favoriteQuestions.length;

    const isSuggestedQuestionsEmpty =
      !Array.isArray(suggestedQuestions) || !suggestedQuestions.length;

    return (
      <>
        <Controller
          name="question"
          control={control}
          render={({ field }) => (
            <>
              <div className="bg-white rounded-md flex flex-row justify-between items-center">
                <Textarea
                  {...field}
                  ref={innerRef}
                  size="xl"
                  radius="md"
                  placeholder="Type Question"
                  autosize
                  maxRows={4}
                  className="
                    w-full
                    [&_.mantine-Textarea-input::placeholder]:text-base
                    [&_.mantine-Textarea-input::placeholder]:text-stone-500
                  [&_.mantine-Textarea-input]:border-none
                  [&_.mantine-Input-section]:w-28 [&_.mantine-TextInput-input]:!pr-28
                  [&_::-webkit-scrollbar]:w-0.5 [&_::-webkit-scrollbar-thumb]:!bg-slate-400"
                  onKeyDown={onKeyDown}
                />
                <div className="grid grid-cols-[1fr_auto] h-9 px-0.5 mr-2">
                  <Tooltip label="Send to RIO" openDelay={1000}>
                    <ActionIcon
                      disabled={disabled || !isDirty}
                      variant="transparent"
                      size="lg"
                      radius="md"
                      color="mediumPurple"
                      aria-label="Send"
                      className="data-[disabled=true]:!border-none"
                      onClick={() => onSendQuery(true)}
                    >
                      <span className="material-symbols-outlined text-ar-dark">send</span>
                    </ActionIcon>
                  </Tooltip>
                </div>
              </div>
              <div className="flex gap-2 justify-self-start justify-start items-center opacity-50 pl-4 text-white">
                {processingMessage ? (
                  <>
                    <Loader size="xs" color="ar-accent" />
                    {processingMessage}
                  </>
                ) : null}
              </div>
            </>
          )}
        />
        <div className="w-full flex space-x-2 mt-4">
          <Menu
            opened={isSuggestedMenuOpened}
            position="top-start"
            shadow="xl"
            onChange={setIsSuggestedMenuOpened}
          >
            <Menu.Target>
              <Button
                rightSection={
                  <span className="material-symbols-outlined">keyboard_arrow_down</span>
                }
                size="md"
                justify="space-between"
                fullWidth
                variant="default"
              >
                <span className="text-sm">Suggested</span>
              </Button>
            </Menu.Target>
            <Menu.Dropdown>
              <ScrollArea.Autosize mih={200} mah={400} type="auto">
                <Menu.Label>
                  <div className="flex flex-row justify-start items-center gap-2 font-semibold uppercase">
                    <span>Suggested Questions</span>
                    {isSuggestedQuestionsFetching ? <Loader size="xs" color="ar-accent" /> : null}
                  </div>
                </Menu.Label>
                {isSuggestedQuestionsLoading ? (
                  <>
                    {[...Array(4)].map((_, index) => (
                      <Menu.Item key={index}>
                        <Skeleton height={8} radius="xl" />
                      </Menu.Item>
                    ))}
                  </>
                ) : isSuggestedQuestionsEmpty ? (
                  <Menu.Label>
                    <span className="italic">No Suggested Questions available yet</span>
                  </Menu.Label>
                ) : (
                  suggestedQuestions
                )}
              </ScrollArea.Autosize>
            </Menu.Dropdown>
          </Menu>
          <Menu
            opened={isFavoritesMenuOpened}
            position="top-start"
            shadow="xl"
            onChange={setIsFavoritesMenuOpened}
          >
            <Menu.Target>
              <Button
                rightSection={
                  <span className="material-symbols-outlined">keyboard_arrow_down</span>
                }
                size="md"
                justify="space-between"
                fullWidth
                variant="default"
              >
                <span className="text-sm">Favourite</span>
              </Button>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Label>
                <div className="flex flex-row justify-start items-center gap-2 font-semibold uppercase">
                  <span>Favorite Questions</span>
                  {isFavoriteQuestionsFetching ? <Loader size="xs" color="ar-accent" /> : null}
                </div>
              </Menu.Label>
              <ScrollArea.Autosize mih={200} mah={400} type="auto">
                {isFavoriteQuestionsLoading ? (
                  <>
                    {[...Array(4)].map((_, index) => (
                      <Menu.Item key={index}>
                        <Skeleton height={8} radius="xl" />
                      </Menu.Item>
                    ))}
                  </>
                ) : isFavoriteQuestionsEmpty ? (
                  <Menu.Label>
                    <span className="italic">No Favorite Questions added yet</span>
                  </Menu.Label>
                ) : (
                  favoriteQuestions
                )}
              </ScrollArea.Autosize>
            </Menu.Dropdown>
          </Menu>
        </div>
      </>
    );
  }
);
