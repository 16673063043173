import { Card, Checkbox, HoverCard } from "@mantine/core";
import React from "react";

interface CheckboxCardProps {
  children: React.ReactNode;
  disabled?: boolean;
  onClick: () => void;
  checked: boolean;
  label: string;
  tooltip: React.ReactNode;
}

const CheckboxCard = ({
  children,
  disabled,
  onClick,
  checked,
  tooltip,
  label,
}: CheckboxCardProps) => (
  <Card className="!px-2 !bg-white hover:!bg-ar-dark/10 !border-none" radius="md">
    <div className="h-full p-4">
      <HoverCard>
        <div className="flex justify-between mb-2">
          <Checkbox
            disabled={disabled}
            color="#000000"
            onChange={onClick}
            checked={checked}
            label={label}
            classNames={{
              label: "font-semibold !text-ar-dark text-lg !cursor-pointer",
              input: "!cursor-pointer",
            }}
          />
          <HoverCard.Target>
            <span className="place-content-start material-symbols-outlined text-ar-dark">info</span>
          </HoverCard.Target>
        </div>
        <HoverCard.Dropdown>
          <div className="max-w-72">{tooltip}</div>
        </HoverCard.Dropdown>
      </HoverCard>
      <div className="pl-8">{children}</div>
    </div>
  </Card>
);

export default CheckboxCard;
