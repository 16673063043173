import { Link } from "react-router-dom";
import { ScrollArea } from "@mantine/core";
import { ROUTES } from "@/constants";

const markup = {
  __html:
    '<!DOCTYPE html><!--[if IE]>  <html class="ie"> <![endif]-->\n' +
    "<html>\n" +
    "\t<head>\n" +
    '\t\t<meta charset="utf-8" />\n' +
    "\t\t<title>\n" +
    "\t\t</title>\n" +
    '\t\t<link rel="stylesheet" type="text/css" href="terms_files/style.css" />\n' +
    "\t</head>\n" +
    "\t<body>\n" +
    '\t\t<div class="stl_02">\n' +
    '\t\t\t<div class="stl_03">\n' +
    '\t\t\t\t<object data="terms_files/img_01.svg" type="image/svg+xml" class="stl_04" style="position:absolute; width:51em; height:66em;">\n' +
    '\t\t\t\t\t<embed src="terms_files/img_01.svg" type="image/svg+xml" />\n' +
    "\t\t\t\t</object>\n" +
    "\t\t\t</div>\n" +
    '\t\t\t<div class="stl_view">\n' +
    '\t\t\t\t<div class="stl_05 stl_06">\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:18.25em;top:6.4371em;"><span class="stl_07 stl_08 stl_09" style="word-spacing:0.0212em;">ARACOR TERMS OF SERVICE &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:6.0083em;top:8.7538em;z-index:67;"><span class="stl_10 stl_08 stl_11" style="word-spacing:0.0544em;">PLEASE READ THESE TERMS OF SERVICE CAREFULL</span><span class="stl_10 stl_08 stl_12">Y</span><span class="stl_10 stl_08 stl_13" style="word-spacing:0.0369em;">. </span><span class="stl_10 stl_08 stl_14">B</span><span class="stl_10 stl_08 stl_13" style="word-spacing:0.0457em;">Y </span><span class="stl_15 stl_08 stl_16" style="word-spacing:0.0337em;">USING OUR SERVICES, YOU &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:6.0083em;top:10.0788em;"><span class="stl_15 stl_08 stl_17" style="word-spacing:-0.0308em;">AGREE </span><span class="stl_10 stl_08 stl_18" style="word-spacing:-0.0178em;">TO THESE TERMS AND CONDITIONS. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:6.0083em;top:12.3954em;"><span class="stl_15 stl_08 stl_17" style="word-spacing:0.0283em;">These Terms of Service constitute an agreement (this “Agreement”) by and between Aracor Inc., &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:6.0083em;top:13.7204em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.1914em;">a corporation whose principal place of business is 3524 Silverside Road Suite 35B, City of &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:6.0083em;top:15.0371em;"><span class="stl_15 stl_08 stl_20" style="word-spacing:0.0513em;">Wilmington, County of New Castle, 19810-4929, United States of America (“Aracor”) and you, &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:6.0083em;top:16.3621em;"><span class="stl_15 stl_08 stl_21" style="word-spacing:0.0249em;">each a “Party” and collectively the “Parties”. This Agreement is effective as of the date you start &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:6.0083em;top:17.6788em;"><span class="stl_15 stl_08 stl_22" style="word-spacing:-0.0042em;">using Aracor Services (as defined below) (the “Effective Date”). &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:6.0083em;top:22.3204em;"><span class="stl_07 stl_08 stl_21" style="word-spacing:0.0068em;">Article I. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:12.0083em;top:22.3204em;"><span class="stl_07 stl_08 stl_23">Introduction &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:8.2583em;top:24.6454em;"><span class="stl_15 stl_08 stl_24" style="word-spacing:0.6689em;">I.1. This</span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.3258em;">&nbsp;</span><span class="stl_15 stl_08 stl_24" style="word-spacing:0.3788em;">Agreement governs the provision and use of the services including any &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:25.9621em;"><span class="stl_15 stl_08 stl_21" style="word-spacing:-0.0045em;">integrations (the “Services”) between Aracor and you. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:8.2583em;top:28.2871em;z-index:639;"><span class="stl_15 stl_08 stl_13" style="word-spacing:0.667em;">I.2. </span><span class="stl_15 stl_08 stl_25">Y</span><span class="stl_15 stl_08 stl_13">o</span><span class="stl_15 stl_08 stl_13">u</span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.123em;">&nbsp;</span><span class="stl_15 stl_08 stl_26" style="word-spacing:0.1218em;">must be at least 18 years old or the minimum age required in your country to &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:29.6038em;"><span class="stl_15 stl_08 stl_21" style="word-spacing:0.0362em;">consent to use the Services. Before accessing or using the Services you represent and &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:30.9288em;"><span class="stl_15 stl_08 stl_27" style="word-spacing:-0.0046em;">warrant that you have read and consent to the Agreement. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:8.2583em;top:33.2454em;"><span class="stl_15 stl_08 stl_28" style="word-spacing:0.6692em;">I.3. Aracor</span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.0411em;">&nbsp;</span><span class="stl_15 stl_08 stl_24" style="word-spacing:0.0324em;">reserves the right to modify this Agreement or the Services at any time and at &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:34.5704em;z-index:914;"><span class="stl_15 stl_08 stl_20" style="word-spacing:0.0401em;">its sole discretion. </span><span class="stl_15 stl_08 stl_25">Y</span><span class="stl_15 stl_08 stl_13">o</span><span class="stl_15 stl_08 stl_24" style="word-spacing:0.0494em;">ur sole remedies for such modification will be terminating your &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:35.8871em;"><span class="stl_15 stl_08 stl_28" style="word-spacing:-0.0124em;">account and this Agreement. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:6.0083em;top:38.2121em;"><span class="stl_07 stl_08 stl_29" style="word-spacing:0.0081em;">Article II. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:12.0083em;top:38.2121em;"><span class="stl_07 stl_08 stl_30">Services &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:8.2583em;top:40.5288em;"><span class="stl_15 stl_08 stl_22" style="word-spacing:0.3351em;">II.1. Subject</span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.0537em;">&nbsp;</span><span class="stl_15 stl_08 stl_26" style="word-spacing:0.0431em;">to the terms of this Agreement, Aracor hereby grants to you a non-exclusive, &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:41.8538em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.0991em;">non-transferable right to use the Aracor Services, and Aracor will provide you with &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:43.1704em;"><span class="stl_15 stl_08 stl_27" style="word-spacing:0.001em;">access to such Services at its sole discretion. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:8.2583em;top:45.4954em;"><span class="stl_15 stl_08 stl_27" style="word-spacing:0.335em;">II.2. The</span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.0796em;">&nbsp;</span><span class="stl_15 stl_08 stl_31" style="word-spacing:0.0848em;">Services may include features which integrates the Services with a third party’s &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:46.8121em;"><span class="stl_15 stl_08 stl_27" style="word-spacing:0.2991em;">applications, software, or services (the “Third-Party Applications”) or otherwise &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:48.1371em;"><span class="stl_15 stl_08 stl_26" style="word-spacing:0.5069em;">allows the Services to interoperate with such Third-Party Applications (the &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:49.4538em;"><span class="stl_15 stl_08 stl_26" style="word-spacing:-0.0007em;">“Integrations”). Aracor disclaims any and all liability relating to the availability or use &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:50.7788em;"><span class="stl_15 stl_08 stl_22" style="word-spacing:-0.0048em;">of such Third-Party Applications and the functionality of such Integrations. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:6.0083em;top:53.0954em;"><span class="stl_07 stl_08 stl_28" style="word-spacing:0.0074em;">Article III.</span><span class="stl_07 stl_08 stl_13" style="word-spacing:1.1461em;">&nbsp;</span><span class="stl_07 stl_08 stl_26" style="word-spacing:0.0023em;">Restrictions &amp; Responsibilities &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:8.2583em;top:55.4204em;z-index:1607;"><span class="stl_15 stl_08 stl_13" style="word-spacing:0.001em;">III.1. </span><span class="stl_15 stl_08 stl_25">Y</span><span class="stl_15 stl_08 stl_13">o</span><span class="stl_15 stl_08 stl_27" style="word-spacing:0.002em;">u will not, and will not attempt to, directly or indirectly: &nbsp;</span></div>\n' +
    "\t\t\t\t</div>\n" +
    "\t\t\t</div>\n" +
    "\t\t</div>\n" +
    '\t\t<div class="stl_02">\n' +
    '\t\t\t<div class="stl_view">\n' +
    '\t\t\t\t<div class="stl_05 stl_06">\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:6.0954em;"><span class="stl_15 stl_08 stl_26" style="word-spacing:0.7263em;">i. reverse</span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.2048em;">&nbsp;</span><span class="stl_15 stl_08 stl_20" style="word-spacing:0.2056em;">engineer, decompile, disassemble or otherwise attempt to discover the &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:12.0083em;top:7.4204em;"><span class="stl_15 stl_08 stl_27" style="word-spacing:0.0807em;">source code, object code or underlying structure, ideas, know-how or algorithms &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:12.0083em;top:8.7371em;"><span class="stl_15 stl_08 stl_21" style="word-spacing:0.1904em;">relevant to the Services or any software, documentation or data related to the &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:12.0083em;top:10.0621em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.0027em;">Services (“Software”); &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:12.3788em;"><span class="stl_15 stl_08 stl_32" style="word-spacing:0.3516em;">ii. modify, translate, or create derivative works based on the Services or any &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:12.0083em;top:13.7038em;"><span class="stl_15 stl_08 stl_22" style="word-spacing:0.0261em;">Software (except to the extent expressly permitted by Aracor or authorized within &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:12.0083em;top:15.0204em;"><span class="stl_15 stl_08 stl_33" style="word-spacing:-0.0016em;">the Services); &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:17.3454em;"><span class="stl_15 stl_08 stl_21" style="word-spacing:0.1121em;">iii. access, store, distribute or transmit any thing or device (including any software, &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:12.0083em;top:18.6621em;"><span class="stl_15 stl_08 stl_21" style="word-spacing:0.3688em;">code, file or program) which is intended to: prevent, impair or otherwise &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:12.0083em;top:19.9871em;"><span class="stl_15 stl_08 stl_21" style="word-spacing:0.1597em;">adversely affect the operation of any computer software, hardware or network, &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:12.0083em;top:21.3038em;"><span class="stl_15 stl_08 stl_27" style="word-spacing:0.1585em;">any telecommunications service, equipment or network or any other service or &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:12.0083em;top:22.6288em;"><span class="stl_15 stl_08 stl_24" style="word-spacing:0.0398em;">device; prevent, impair or otherwise adversely affect access to or the operation of &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:12.0083em;top:23.9454em;"><span class="stl_15 stl_08 stl_26" style="word-spacing:0.0437em;">any program or data, including the reliability of any program or data (whether by &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:12.0083em;top:25.2704em;"><span class="stl_15 stl_08 stl_21" style="word-spacing:0.3739em;">re-arranging, altering or erasing the program or data in whole or part or &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:12.0083em;top:26.5871em;"><span class="stl_15 stl_08 stl_26" style="word-spacing:0.0844em;">otherwise); or adversely affect the user experience, including, but not limited to, &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:12.0083em;top:27.9121em;"><span class="stl_15 stl_08 stl_34" style="word-spacing:0.1953em;">worms, Trojan horses, viruses and other similar things or devices through the &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:12.0083em;top:29.2288em;"><span class="stl_15 stl_08 stl_26">Services; &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:31.5538em;"><span class="stl_15 stl_08 stl_35" style="word-spacing:0.3135em;">iv. access</span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.0616em;">&nbsp;</span><span class="stl_15 stl_08 stl_27" style="word-spacing:0.0498em;">all or any part of the Services in order to build a product or service which &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:12.0083em;top:32.8704em;"><span class="stl_15 stl_08 stl_21" style="word-spacing:0.004em;">competes with the Services; &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:35.1954em;"><span class="stl_15 stl_08 stl_36" style="word-spacing:0.5909em;">v. deploy</span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.2679em;">&nbsp;</span><span class="stl_15 stl_08 stl_21" style="word-spacing:0.2609em;">any software applications to run automated tasks on the Software or &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:12.0083em;top:36.5121em;"><span class="stl_15 stl_08 stl_26">Services; &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:38.8371em;"><span class="stl_15 stl_08 stl_37" style="word-spacing:0.2295em;">vi. use</span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.0074em;">&nbsp;</span><span class="stl_15 stl_08 stl_30" style="word-spacing:-0.0008em;">the Services or Software in a manner that, in the reasonable opinion of Aracor, &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:12.0083em;top:40.1538em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.0019em;">is abusive, illegal, or threatens the integrity or performance of the Services; &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:42.4788em;"><span class="stl_15 stl_08 stl_21" style="word-spacing:0.0438em;">vii. upload Customer Content (as hereinafter defined) to the Services which infringes &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:12.0083em;top:43.7954em;"><span class="stl_15 stl_08 stl_27" style="word-spacing:0.0021em;">on the intellectual property rights of a third party; &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.2083em;top:46.1204em;"><span class="stl_15 stl_08 stl_29" style="word-spacing:-0.0221em;">viii. upload</span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.1655em;">&nbsp;</span><span class="stl_15 stl_08 stl_27" style="word-spacing:0.1631em;">any personal information of children under 13 or the applicable age of &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:11.7083em;top:47.4371em;"><span class="stl_15 stl_08 stl_22" style="word-spacing:0.1758em;">digital consent or allow minors to use our Services without consent from their &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:11.7083em;top:48.7621em;"><span class="stl_15 stl_08 stl_26" style="word-spacing:0.0035em;">parent or guardian. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:8.2583em;top:51.0788em;z-index:1580;"><span class="stl_15 stl_08 stl_13" style="word-spacing:0.001em;">III.2. </span><span class="stl_15 stl_08 stl_25">Y</span><span class="stl_15 stl_08 stl_13">o</span><span class="stl_15 stl_08 stl_13">u</span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.382em;">&nbsp;</span><span class="stl_15 stl_08 stl_26" style="word-spacing:0.3815em;">represent, covenant, and warrant that you will use the Services only in &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:52.4038em;"><span class="stl_15 stl_08 stl_22" style="word-spacing:0.3657em;">compliance with all applicable laws and regulations. Although Aracor has no &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:53.7204em;"><span class="stl_15 stl_08 stl_38" style="word-spacing:0.0201em;">obligation to monitor your use of the Services, Aracor retains the right do so and may &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:55.0454em;"><span class="stl_15 stl_08 stl_28" style="word-spacing:0.1067em;">prohibit any use of the Services it believes (in Aracor’s sole discretion) may be (or &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:56.3621em;"><span class="stl_15 stl_08 stl_21" style="word-spacing:0.0034em;">alleged to be) in violation of the foregoing. &nbsp;</span></div>\n' +
    "\t\t\t\t</div>\n" +
    "\t\t\t</div>\n" +
    "\t\t</div>\n" +
    '\t\t<div class="stl_02">\n' +
    '\t\t\t<div class="stl_03">\n' +
    '\t\t\t\t<object data="terms_files/img_02.svg" type="image/svg+xml" class="stl_04" style="position:absolute; width:51em; height:66em;">\n' +
    '\t\t\t\t\t<embed src="terms_files/img_02.svg" type="image/svg+xml" />\n' +
    "\t\t\t\t</object>\n" +
    "\t\t\t</div>\n" +
    '\t\t\t<div class="stl_view">\n' +
    '\t\t\t\t<div class="stl_05 stl_06">\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:8.2583em;top:6.0954em;"><span class="stl_15 stl_08 stl_13" style="word-spacing:0.001em;">III.3. If</span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.282em;">&nbsp;</span><span class="stl_15 stl_08 stl_27" style="word-spacing:0.2883em;">you use the Services to process personal data, you must (a) provide legally &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:7.4204em;"><span class="stl_15 stl_08 stl_27" style="word-spacing:0.0169em;">adequate privacy notices and obtain necessary consents for the processing of personal &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:8.7371em;"><span class="stl_15 stl_08 stl_26" style="word-spacing:0.0067em;">data by the Services, (b) process personal data in accordance with applicable laws and &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:10.0621em;"><span class="stl_15 stl_08 stl_39">regulations. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:8.2583em;top:12.3788em;z-index:292;"><span class="stl_15 stl_08 stl_13" style="word-spacing:0.001em;">III.4. </span><span class="stl_15 stl_08 stl_25">Y</span><span class="stl_15 stl_08 stl_13">o</span><span class="stl_15 stl_08 stl_27" style="word-spacing:0.1014em;">u must provide accurate and up-to-date account information. </span><span class="stl_15 stl_08 stl_25">Y</span><span class="stl_15 stl_08 stl_13">o</span><span class="stl_15 stl_08 stl_22" style="word-spacing:0.1062em;">u are responsible &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:13.7038em;"><span class="stl_15 stl_08 stl_22" style="word-spacing:0.1386em;">for all activities that occur under your account. </span><span class="stl_15 stl_08 stl_40">Y</span><span class="stl_15 stl_08 stl_13">o</span><span class="stl_15 stl_08 stl_28" style="word-spacing:0.1456em;">u may not make account access &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:15.0204em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.3168em;">credentials available to third parties, share individual login credentials between &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:16.3454em;z-index:510;"><span class="stl_15 stl_08 stl_21" style="word-spacing:0.1995em;">multiple users on an account, or resell or lease access to your account. </span><span class="stl_15 stl_08 stl_25">Y</span><span class="stl_15 stl_08 stl_13">o</span><span class="stl_15 stl_08 stl_30" style="word-spacing:0.2006em;">u will &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:17.6621em;"><span class="stl_15 stl_08 stl_21" style="word-spacing:0.0354em;">promptly notify us if you become aware of any unauthorized access to or use of your &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:18.9871em;"><span class="stl_15 stl_08 stl_26" style="word-spacing:0.0027em;">account or our Services. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:6.0083em;top:23.6288em;z-index:614;"><span class="stl_07 stl_08 stl_21" style="word-spacing:0.0068em;">Article I</span><span class="stl_07 stl_08 stl_41">V</span><span class="stl_07 stl_08 stl_41">. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:12.0083em;top:23.6288em;"><span class="stl_07 stl_08 stl_42" style="word-spacing:-0.0184em;">Customer Content &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:8.2583em;top:25.9454em;z-index:636;"><span class="stl_15 stl_08 stl_13">I</span><span class="stl_15 stl_08 stl_41">V</span><span class="stl_15 stl_08 stl_13">.</span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.075em;">1. </span><span class="stl_15 stl_08 stl_25">Y</span><span class="stl_15 stl_08 stl_13">o</span><span class="stl_15 stl_08 stl_27" style="word-spacing:0.0213em;">u may provide input to the Services (“Input”) and receive output from the Services &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:27.2704em;"><span class="stl_15 stl_08 stl_24" style="word-spacing:0.283em;">based on the Input (“Output”, together “Customer Content”). </span><span class="stl_15 stl_08 stl_40">Y</span><span class="stl_15 stl_08 stl_13">o</span><span class="stl_15 stl_08 stl_38" style="word-spacing:0.2866em;">u (a) retain all &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:28.5871em;"><span class="stl_15 stl_08 stl_43" style="word-spacing:0.1331em;">ownership rights in Input and (b) own all Output. We hereby assign to you all our &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:29.9121em;"><span class="stl_15 stl_08 stl_44" style="word-spacing:0.0071em;">right, title, and interest, if any, in and to Output. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:8.2583em;top:32.2288em;z-index:889;"><span class="stl_15 stl_08 stl_13">I</span><span class="stl_15 stl_08 stl_41">V</span><span class="stl_15 stl_08 stl_13">.</span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.075em;">2. </span><span class="stl_15 stl_08 stl_25">Y</span><span class="stl_15 stl_08 stl_13">o</span><span class="stl_15 stl_08 stl_19" style="word-spacing:0.092em;">u are responsible for all Input and represent and warrant that you have all rights, &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:33.5538em;"><span class="stl_15 stl_08 stl_26" style="word-spacing:0.144em;">licenses, and permissions required to provide Input to the Services. </span><span class="stl_15 stl_08 stl_40">Y</span><span class="stl_15 stl_08 stl_13">o</span><span class="stl_15 stl_08 stl_28" style="word-spacing:0.1452em;">u are solely &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:34.8704em;"><span class="stl_15 stl_08 stl_22" style="word-spacing:0.1673em;">responsible for all use of the Outputs and evaluating the Output for accuracy and &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:36.1954em;"><span class="stl_15 stl_08 stl_22" style="word-spacing:0.5052em;">appropriateness for your use case, including by utilizing human review as &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:37.5121em;"><span class="stl_15 stl_08 stl_22" style="word-spacing:0.0022em;">appropriate. Aracor will have no responsibility or liability for (i) the accuracy of Input &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:38.8371em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.1709em;">and any other data uploaded by you, or (ii) your usage of Outputs or reliance on &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:40.1538em;"><span class="stl_15 stl_08 stl_39">Outputs. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:8.2583em;top:42.4788em;z-index:1313;"><span class="stl_15 stl_08 stl_13">I</span><span class="stl_15 stl_08 stl_41">V</span><span class="stl_15 stl_08 stl_13">.</span><span class="stl_15 stl_08 stl_38" style="word-spacing:0.1896em;">3. Aracor uses Customer Content in certain ways to provide the Services, including &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:43.7954em;"><span class="stl_15 stl_08 stl_27" style="word-spacing:0.094em;">passing Inputs through natural language AI models. Under this Agreement, “natural &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:45.1204em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.1196em;">language AI models” mean neural network machine learning models, including but &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:46.4371em;"><span class="stl_15 stl_08 stl_26" style="word-spacing:0.2031em;">not limited to OpenAI APIs and ChatGPT Enterprise, used by Aracor to process &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:47.7621em;"><span class="stl_15 stl_08 stl_22" style="word-spacing:0.053em;">Customer Content. When Aracor uses these natural language AI models, it is subject &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:49.0788em;"><span class="stl_15 stl_08 stl_18" style="word-spacing:0.1329em;">to their business terms of OpenAI and other such service providers. To learn more &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:50.4038em;"><span class="stl_15 stl_08 stl_27">about &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:14.4893em;top:50.4038em;"><span class="stl_15 stl_08 stl_13">how &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:17.9783em;top:50.4038em;"><span class="stl_15 stl_08 stl_13">Open &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:21.8503em;top:50.4038em;"><span class="stl_15 stl_08 stl_45">AI &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:24.6723em;top:50.4038em;"><span class="stl_15 stl_08 stl_13">uses &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:28.1613em;top:50.4038em;"><span class="stl_15 stl_08 stl_28">Customer &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:33.8073em;top:50.4038em;"><span class="stl_15 stl_08 stl_42">Content, &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:38.9863em;top:50.4038em;"><span class="stl_15 stl_08 stl_24">please &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:43.2503em;top:50.4038em;"><span class="stl_15 stl_08 stl_38">visit &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:51.7204em;"><a href="https://openai.com/policies/business-terms" target="_blank"><span class="stl_46 stl_08 stl_13">https://openai.com/policies/business-terms</span></a><span class="stl_15 stl_08 stl_38" style="word-spacing:0.3651em;">. Aracor disclaims any and all liability &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:53.0454em;"><span class="stl_15 stl_08 stl_47" style="word-spacing:0.0007em;">relating to the Open AI’s use of Customer Content. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:8.2583em;top:55.3621em;z-index:1891;"><span class="stl_15 stl_08 stl_13">I</span><span class="stl_15 stl_08 stl_41">V</span><span class="stl_15 stl_08 stl_13">.</span><span class="stl_15 stl_08 stl_34" style="word-spacing:0.0446em;">4. Aracor will: &nbsp;</span></div>\n' +
    "\t\t\t\t</div>\n" +
    "\t\t\t</div>\n" +
    "\t\t</div>\n" +
    '\t\t<div class="stl_02">\n' +
    '\t\t\t<div class="stl_view">\n' +
    '\t\t\t\t<div class="stl_05 stl_06">\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:6.0954em;"><span class="stl_15 stl_08 stl_26" style="word-spacing:0.7263em;">i. process</span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.1015em;">&nbsp;</span><span class="stl_15 stl_08 stl_26" style="word-spacing:0.1006em;">Customer Content as needed through the natural language AI models in &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:12.0083em;top:7.4204em;"><span class="stl_15 stl_08 stl_31" style="word-spacing:0.0055em;">order to deliver the Service’s core functionality to you; &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:9.7371em;"><span class="stl_15 stl_08 stl_48" style="word-spacing:0.4534em;">ii. only</span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.1577em;">&nbsp;</span><span class="stl_15 stl_08 stl_29" style="word-spacing:0.1586em;">process, transfer, and/or store Customer Content using secure, reasonable, &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:12.0083em;top:11.0621em;"><span class="stl_15 stl_08 stl_39" style="word-spacing:-0.0002em;">and appropriate mechanisms; &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:13.3788em;"><span class="stl_15 stl_08 stl_26" style="word-spacing:0.243em;">iii. exercise reasonable efforts to prevent unauthorized disclosure or exposure of &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:12.0083em;top:14.7038em;"><span class="stl_15 stl_08 stl_26" style="word-spacing:0.0035em;">Customer Content; and &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:17.0204em;"><span class="stl_15 stl_08 stl_31" style="word-spacing:0.1883em;">iv. comply with applicable data privacy laws in the jurisdictions in which Aracor &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:12.0083em;top:18.3454em;"><span class="stl_15 stl_08 stl_21" style="word-spacing:0.0035em;">does business. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:8.2917em;top:20.6621em;z-index:390;"><span class="stl_15 stl_08 stl_13">I</span><span class="stl_15 stl_08 stl_41">V</span><span class="stl_15 stl_08 stl_13">.</span><span class="stl_15 stl_08 stl_48" style="word-spacing:0.0255em;">5. Large Language Model Training. Customer Content is not used by Aracor to train the &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5667em;top:21.8121em;"><span class="stl_15 stl_08 stl_21" style="word-spacing:0.0155em;">natural language AI models and Aracor has opted out of all available options to do so &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5667em;top:22.9621em;"><span class="stl_15 stl_08 stl_28" style="word-spacing:0.0074em;">where applicable. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:8.2917em;top:25.1121em;z-index:550;"><span class="stl_15 stl_08 stl_13">I</span><span class="stl_15 stl_08 stl_41">V</span><span class="stl_15 stl_08 stl_13">.</span><span class="stl_15 stl_08 stl_24" style="word-spacing:0.003em;">6. Notwithstanding anything else in this Article 4, Aracor may: &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:27.4371em;"><span class="stl_15 stl_08 stl_14" style="word-spacing:0.731em;">i. use</span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.0321em;">&nbsp;</span><span class="stl_15 stl_08 stl_26" style="word-spacing:0.0221em;">Customer Content in anonymized and aggregated form, to train its internal AI &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:12.0083em;top:28.7538em;"><span class="stl_15 stl_08 stl_22" style="word-spacing:0.0676em;">models, improve or optimize the performance of the Services, and/or power new &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:12.0083em;top:30.0788em;"><span class="stl_15 stl_08 stl_21" style="word-spacing:-0.0101em;">or existing AI features; and &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:32.3954em;"><span class="stl_15 stl_08 stl_26" style="word-spacing:0.3574em;">ii. collect and analyze non-identifying analytics data relating to your use and &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:12.0083em;top:33.7204em;"><span class="stl_15 stl_08 stl_27" style="word-spacing:0.2469em;">performance of various aspects of the Services, including your related usage &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:12.0083em;top:35.0371em;"><span class="stl_15 stl_08 stl_21" style="word-spacing:0.2391em;">metrics to use such data to improve and enhance the Services and for other &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:12.0083em;top:36.3621em;"><span class="stl_15 stl_08 stl_26" style="word-spacing:0.0992em;">statistical, developmental, diagnostic and corrective purposes in connection with &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:12.0083em;top:37.6788em;"><span class="stl_15 stl_08 stl_49">it. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:8.2583em;top:40.0038em;z-index:1038;"><span class="stl_15 stl_08 stl_13">I</span><span class="stl_15 stl_08 stl_41">V</span><span class="stl_15 stl_08 stl_13">.</span><span class="stl_15 stl_08 stl_19" style="word-spacing:0.0071em;">7. If you provide us with feedback regarding the Services (“Feedback”), you grant us the &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:41.3204em;"><span class="stl_15 stl_08 stl_26" style="word-spacing:0.027em;">right to use and exploit Feedback without restriction or compensation. Aracor has not &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:42.6454em;"><span class="stl_15 stl_08 stl_21" style="word-spacing:0.0105em;">agreed to and does not agree to treat as confidential any Feedback, and nothing in this &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:43.9621em;"><span class="stl_15 stl_08 stl_24" style="word-spacing:0.0517em;">Agreement or in the Parties’ dealings arising out of or related to this Agreement will &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:45.2871em;"><span class="stl_15 stl_08 stl_24" style="word-spacing:0.0593em;">restrict Aracor’s right to use, profit from, disclose, publish, keep secret, or otherwise &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:46.6038em;z-index:1476;"><span class="stl_15 stl_08 stl_29" style="word-spacing:0.3103em;">exploit Feedback, without compensating or crediting you. For greater certainty, &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:47.9288em;"><span class="stl_15 stl_08 stl_22" style="word-spacing:0.0029em;">Feedback will not be considered your trade secret. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:6.0083em;top:52.8121em;z-index:1528;"><span class="stl_07 stl_08 stl_30" style="word-spacing:-0.0093em;">Article </span><span class="stl_07 stl_08 stl_41">V</span><span class="stl_07 stl_08 stl_41">. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:12.0083em;top:52.8121em;"><span class="stl_07 stl_08 stl_18" style="word-spacing:0.0041em;">Proprietary Rights &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:8.2583em;top:55.1371em;z-index:1547;"><span class="stl_15 stl_08 stl_41">V</span><span class="stl_15 stl_08 stl_13">.</span><span class="stl_15 stl_08 stl_29" style="word-spacing:0.411em;">1. Aracor</span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.0745em;">&nbsp;</span><span class="stl_15 stl_08 stl_21" style="word-spacing:0.0721em;">shall own and retain all right, title and interest in and to (a) the Services and &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:56.4538em;"><span class="stl_15 stl_08 stl_39" style="word-spacing:0.0007em;">Software, including all improvements, enhancements or modifications thereto, (b) any &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:57.7788em;"><span class="stl_15 stl_08 stl_22" style="word-spacing:0.1023em;">software, applications, inventions or other technology developed in connection with &nbsp;</span></div>\n' +
    "\t\t\t\t</div>\n" +
    "\t\t\t</div>\n" +
    "\t\t</div>\n" +
    '\t\t<div class="stl_02">\n' +
    '\t\t\t<div class="stl_view">\n' +
    '\t\t\t\t<div class="stl_05 stl_06">\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:6.0954em;"><span class="stl_15 stl_08 stl_27" style="word-spacing:0.0591em;">its provision of the Services or support including Integrations, and (c) all intellectual &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:7.4204em;"><span class="stl_15 stl_08 stl_26" style="word-spacing:0.1482em;">property rights related to any of the foregoing, including that which is partially or &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:8.7371em;"><span class="stl_15 stl_08 stl_21" style="word-spacing:0.0047em;">completely derived from the provision of the Services. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:8.2583em;top:11.0621em;z-index:198;"><span class="stl_15 stl_08 stl_41">V</span><span class="stl_15 stl_08 stl_13">.</span><span class="stl_15 stl_08 stl_50" style="word-spacing:0.4108em;">2. This</span><span class="stl_15 stl_08 stl_13" style="word-spacing:-0.0047em;">&nbsp;</span><span class="stl_15 stl_08 stl_27" style="word-spacing:0.0422em;">Agreement does not grant you any intellectual property license or rights in or to &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:12.3788em;"><span class="stl_15 stl_08 stl_28" style="word-spacing:0.1296em;">Services or any of its components, except to the limited extent that such rights are &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:13.7038em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:-0.0033em;">necessary for your use of the Services as specifically authorized by this Agreement. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:6.0083em;top:16.0204em;"><span class="stl_07 stl_08 stl_50" style="word-spacing:-0.0091em;">Article VI.</span><span class="stl_07 stl_08 stl_13" style="word-spacing:1.2204em;">&nbsp;</span><span class="stl_07 stl_08 stl_22">Confidentiality &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:8.2583em;top:18.3454em;"><span class="stl_15 stl_08 stl_21" style="word-spacing:0.0711em;">VI.1. Each party (the “Receiving Party”) understands that the other party (the “Disclosing &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:19.6621em;"><span class="stl_15 stl_08 stl_26" style="word-spacing:0.1981em;">Party”) has disclosed or may disclose business, technical or financial information &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:20.9871em;"><span class="stl_15 stl_08 stl_29" style="word-spacing:0.2153em;">relating to the Disclosing Party’s business (hereinafter referred to as “Proprietary &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:22.3038em;"><span class="stl_15 stl_08 stl_27" style="word-spacing:0.1584em;">Information” of the Disclosing Party).</span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.5635em;">&nbsp;</span><span class="stl_15 stl_08 stl_39" style="word-spacing:0.1443em;">Proprietary Information of Aracor includes &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:23.6288em;"><span class="stl_15 stl_08 stl_39" style="word-spacing:0.3163em;">non-public information regarding features, functionality and performance of the &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:24.9454em;"><span class="stl_15 stl_08 stl_26" style="word-spacing:0.6234em;">Services. </span><span class="stl_15 stl_08 stl_40">Y</span><span class="stl_15 stl_08 stl_13">o</span><span class="stl_15 stl_08 stl_13">ur</span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.198em;">&nbsp;</span><span class="stl_15 stl_08 stl_26" style="word-spacing:0.2073em;">Proprietary Information includes Customer Content or other non- &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:26.2704em;"><span class="stl_15 stl_08 stl_22" style="word-spacing:-0.0018em;">public data provided by you to Aracor to enable the provision of the Services. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:8.2583em;top:28.5871em;"><span class="stl_15 stl_08 stl_26" style="word-spacing:-0.0098em;">VI.2. The Receiving Party shall: &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:29.9121em;"><span class="stl_15 stl_08 stl_31" style="word-spacing:0.7288em;">i. take</span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.007em;">&nbsp;</span><span class="stl_15 stl_08 stl_19" style="word-spacing:0.0014em;">reasonable precautions to protect such Proprietary Information, and &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:31.2288em;"><span class="stl_15 stl_08 stl_50" style="word-spacing:0.4528em;">ii. not</span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.0386em;">&nbsp;</span><span class="stl_15 stl_08 stl_22" style="word-spacing:0.0426em;">use or divulge to any third person any such Proprietary Information except as &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:12.0083em;top:32.5538em;"><span class="stl_15 stl_08 stl_22" style="word-spacing:0.2052em;">is necessary to facilitate the provision, improvement, and enhancement of the &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:12.0083em;top:33.8704em;"><span class="stl_15 stl_08 stl_26">Services. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:8.2583em;top:35.1954em;"><span class="stl_15 stl_08 stl_30" style="word-spacing:-0.0473em;">VI.3. The</span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.1475em;">&nbsp;</span><span class="stl_15 stl_08 stl_22" style="word-spacing:0.1469em;">Disclosing Party agrees that the foregoing shall not apply with respect to any &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:36.5121em;"><span class="stl_15 stl_08 stl_26" style="word-spacing:0.1273em;">information after five (5) years following the disclosure thereof or any information &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:37.8371em;"><span class="stl_15 stl_08 stl_38" style="word-spacing:0.0053em;">that the Receiving Party can document &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:39.1538em;"><span class="stl_15 stl_08 stl_49" style="word-spacing:0.7307em;">i. is</span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.0047em;">&nbsp;</span><span class="stl_15 stl_08 stl_20" style="word-spacing:0.0073em;">or becomes generally available to the public, or &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:41.4788em;"><span class="stl_15 stl_08 stl_51" style="word-spacing:0.4573em;">ii. was</span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.0055em;">&nbsp;</span><span class="stl_15 stl_08 stl_37" style="word-spacing:0.0062em;">in its possession or known by it prior to receipt from the Disclosing Party, or &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:43.7954em;"><span class="stl_15 stl_08 stl_52" style="word-spacing:0.182em;">iii. was</span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.0053em;">&nbsp;</span><span class="stl_15 stl_08 stl_32" style="word-spacing:0.0062em;">rightfully disclosed to it without restriction by a third party, or &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:46.1204em;"><span class="stl_15 stl_08 stl_53" style="word-spacing:0.3314em;">iv. was</span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.1366em;">&nbsp;</span><span class="stl_15 stl_08 stl_19" style="word-spacing:0.1164em;">independently developed without use of any Proprietary Information of the &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:12.0083em;top:47.4371em;"><span class="stl_15 stl_08 stl_26" style="word-spacing:0.0014em;">Disclosing Party or &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:49.7621em;z-index:1658;"><span class="stl_15 stl_08 stl_54" style="word-spacing:0.6368em;">v. is</span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.0368em;">&nbsp;</span><span class="stl_15 stl_08 stl_55" style="word-spacing:0.0104em;">required to be disclosed by law. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:8.2583em;top:52.0788em;"><span class="stl_15 stl_08 stl_34" style="word-spacing:-0.0469em;">VI.4. Aracor</span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.1916em;">&nbsp;</span><span class="stl_15 stl_08 stl_42" style="word-spacing:0.1861em;">shall be permitted to disclose, announce or publicize the existence of this &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:53.4038em;"><span class="stl_15 stl_08 stl_26" style="word-spacing:0.0187em;">Agreement and the provision of Services by Aracor to you on an anonymous basis on &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:54.7204em;"><span class="stl_15 stl_08 stl_24" style="word-spacing:-0.004em;">Aracor\'s website and in Aracor\'s marketing materials. &nbsp;</span></div>\n' +
    "\t\t\t\t</div>\n" +
    "\t\t\t</div>\n" +
    "\t\t</div>\n" +
    '\t\t<div class="stl_02">\n' +
    '\t\t\t<div class="stl_view">\n' +
    '\t\t\t\t<div class="stl_05 stl_06">\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:6.0083em;top:6.0954em;z-index:12;"><span class="stl_07 stl_08 stl_38" style="word-spacing:-0.0098em;">Article VII.</span><span class="stl_07 stl_08 stl_13" style="word-spacing:0.829em;">&nbsp;</span><span class="stl_07 stl_08 stl_56">T</span><span class="stl_07 stl_08 stl_57" style="word-spacing:0.0067em;">erm and Termination &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.5083em;top:8.4204em;"><span class="stl_15 stl_08 stl_38">VII.1. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:12.0083em;top:8.4204em;"><span class="stl_15 stl_08 stl_24" style="word-spacing:0.0492em;">This Agreement applies to the provision of and access to the Services for as long &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.3083em;top:9.7371em;"><span class="stl_15 stl_08 stl_50" style="word-spacing:0.1284em;">as you use the Services. However, either party may terminate this Agreement and the &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.3083em;top:11.0621em;"><span class="stl_15 stl_08 stl_27" style="word-spacing:0.0018em;">provision of, and access to, the Services at any time and without cause. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:6.0083em;top:13.3788em;"><span class="stl_07 stl_08 stl_29" style="word-spacing:-0.0088em;">Article VIII.</span><span class="stl_07 stl_08 stl_13" style="word-spacing:0.4465em;">&nbsp;</span><span class="stl_07 stl_08 stl_58" style="word-spacing:0.0073em;">Warranty and Disclaimer &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.8083em;top:15.7038em;"><span class="stl_15 stl_08 stl_21" style="word-spacing:1.2356em;">VIII.1. THE</span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.1879em;">&nbsp;</span><span class="stl_15 stl_08 stl_16" style="word-spacing:0.1946em;">SERVICES ARE PROVIDED “AS IS,” WITH NO REPRESENT</span><span class="stl_15 stl_08 stl_25">A</span><span class="stl_15 stl_08 stl_49">T</span><span class="stl_15 stl_08 stl_47">ION &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.0583em;top:17.0204em;"><span class="stl_15 stl_08 stl_59" style="word-spacing:0.5323em;">OR </span><span class="stl_15 stl_08 stl_60">W</span><span class="stl_15 stl_08 stl_45">A</span><span class="stl_15 stl_08 stl_38" style="word-spacing:0.5217em;">RRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.0583em;top:18.3454em;z-index:419;"><span class="stl_15 stl_08 stl_61" style="word-spacing:0.3866em;">WITHOUT LIMIT</span><span class="stl_15 stl_08 stl_62">A</span><span class="stl_15 stl_08 stl_49">T</span><span class="stl_15 stl_08 stl_33" style="word-spacing:0.3766em;">ION IMPLIED </span><span class="stl_15 stl_08 stl_63">W</span><span class="stl_15 stl_08 stl_45">A</span><span class="stl_15 stl_08 stl_64" style="word-spacing:0.3966em;">RRANTIES OF MERCHANTABILIT</span><span class="stl_15 stl_08 stl_41">Y</span><span class="stl_15 stl_08 stl_41">, &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.0583em;top:19.6621em;"><span class="stl_15 stl_08 stl_64" style="word-spacing:0.5044em;">FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT OF &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.0583em;top:20.9871em;"><span class="stl_15 stl_08 stl_43" style="word-spacing:0.6381em;">INTELLECTUAL PROPERTY RIGHTS, OR ANY IMPLIED </span><span class="stl_15 stl_08 stl_63">W</span><span class="stl_15 stl_08 stl_45">A</span><span class="stl_15 stl_08 stl_17">RRANTY &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.0583em;top:22.3038em;"><span class="stl_15 stl_08 stl_65" style="word-spacing:0.9677em;">ARISING FROM ST</span><span class="stl_15 stl_08 stl_62">A</span><span class="stl_15 stl_08 stl_49">T</span><span class="stl_15 stl_08 stl_26" style="word-spacing:0.9504em;">UTE, COURSE OF DEALING, COURSE OF &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.0583em;top:23.6288em;"><span class="stl_15 stl_08 stl_30" style="word-spacing:-0.0007em;">PERFORMANCE, OR USAGE OF TRADE. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.8083em;top:25.9454em;"><span class="stl_15 stl_08 stl_24" style="word-spacing:1.2359em;">VIII.2. WITHOUT</span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.4381em;">&nbsp;</span><span class="stl_15 stl_08 stl_21" style="word-spacing:0.4422em;">LIMITING THE GENERALITY OF THE FOREGOING: (A) &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.0583em;top:27.2704em;"><span class="stl_15 stl_08 stl_42" style="word-spacing:0.0311em;">ARACOR DOES NOT REPRESENT OR </span><span class="stl_15 stl_08 stl_63">W</span><span class="stl_15 stl_08 stl_45">A</span><span class="stl_15 stl_08 stl_20" style="word-spacing:0.0146em;">RRANT TH</span><span class="stl_15 stl_08 stl_62">A</span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.0052em;">T </span><span class="stl_15 stl_08 stl_49">T</span><span class="stl_15 stl_08 stl_66" style="word-spacing:0.0555em;">HE SERVICES WILL &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.0583em;top:28.5871em;"><span class="stl_15 stl_08 stl_24" style="word-spacing:-0.002em;">PRODUCE ACCUR</span><span class="stl_15 stl_08 stl_62">A</span><span class="stl_15 stl_08 stl_67">T</span><span class="stl_15 stl_08 stl_68" style="word-spacing:0.0605em;">E OR RELE</span><span class="stl_15 stl_08 stl_41">V</span><span class="stl_15 stl_08 stl_45">A</span><span class="stl_15 stl_08 stl_44" style="word-spacing:0.0426em;">NT CONTENT FOR YOU, OR OTHERWISE &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.0583em;top:29.9121em;"><span class="stl_15 stl_08 stl_26" style="word-spacing:0.2744em;">PERFORM WITHOUT INTERRUPTION OR ERROR; (B) ARACOR CANNOT &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.0583em;top:31.2288em;"><span class="stl_15 stl_08 stl_22" style="word-spacing:0.3713em;">GUARANTEE TH</span><span class="stl_15 stl_08 stl_62">A</span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.3542em;">T </span><span class="stl_15 stl_08 stl_49">T</span><span class="stl_15 stl_08 stl_55" style="word-spacing:0.3895em;">HE SERVICES ARE SECURE FROM HACKING OR &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.0583em;top:32.5538em;"><span class="stl_15 stl_08 stl_24" style="word-spacing:0.1525em;">OTHER UNAUTHORIZED INTRUSION, OR TH</span><span class="stl_15 stl_08 stl_62">A</span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.1372em;">T </span><span class="stl_15 stl_08 stl_69">C</span><span class="stl_15 stl_08 stl_30" style="word-spacing:0.1537em;">USTOMER USAGE D</span><span class="stl_15 stl_08 stl_62">A</span><span class="stl_15 stl_08 stl_70">TA &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.0583em;top:33.8704em;"><span class="stl_15 stl_08 stl_28" style="word-spacing:-0.0148em;">WILL REMAIN PRI</span><span class="stl_15 stl_08 stl_41">V</span><span class="stl_15 stl_08 stl_25">A</span><span class="stl_15 stl_08 stl_49">T</span><span class="stl_15 stl_08 stl_22" style="word-spacing:0.0009em;">E OR SECURE UPON SUCH HACKING OR INTRUSION; &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.0583em;top:35.1954em;"><span class="stl_15 stl_08 stl_26" style="word-spacing:0.1273em;">(C) ARACOR DOES NOT REPRESENT TH</span><span class="stl_15 stl_08 stl_62">A</span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.1202em;">T </span><span class="stl_15 stl_08 stl_49">T</span><span class="stl_15 stl_08 stl_19" style="word-spacing:0.138em;">HE OUTPUT GENER</span><span class="stl_15 stl_08 stl_62">A</span><span class="stl_15 stl_08 stl_67">T</span><span class="stl_15 stl_08 stl_31" style="word-spacing:0.1547em;">ED BY &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.0583em;top:36.5121em;"><span class="stl_15 stl_08 stl_71" style="word-spacing:0.5979em;">THE SERVICES</span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.1607em;">&nbsp;</span><span class="stl_15 stl_08 stl_38" style="word-spacing:0.1516em;">WILL BE S</span><span class="stl_15 stl_08 stl_62">A</span><span class="stl_15 stl_08 stl_49">T</span><span class="stl_15 stl_08 stl_72" style="word-spacing:0.138em;">ISFACTORY IN ANY </span><span class="stl_15 stl_08 stl_63">W</span><span class="stl_15 stl_08 stl_73" style="word-spacing:0.1531em;">AY TO YOU; AND (D) &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.0583em;top:37.8371em;"><span class="stl_15 stl_08 stl_24" style="word-spacing:0.2494em;">ARACOR HAS NO CONTROL OVER THE OPER</span><span class="stl_15 stl_08 stl_62">A</span><span class="stl_15 stl_08 stl_49">T</span><span class="stl_15 stl_08 stl_19" style="word-spacing:0.2551em;">ION OF THE N</span><span class="stl_15 stl_08 stl_62">A</span><span class="stl_15 stl_08 stl_49">T</span><span class="stl_15 stl_08 stl_17">URAL &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.0583em;top:39.1538em;"><span class="stl_15 stl_08 stl_26" style="word-spacing:0.3495em;">LANGUAGE AI MODELS OR THE CONTINUED </span><span class="stl_15 stl_08 stl_41">A</span><span class="stl_15 stl_08 stl_41">V</span><span class="stl_15 stl_08 stl_45">A</span><span class="stl_15 stl_08 stl_26" style="word-spacing:0.3273em;">ILABILITY OF ANY &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.0583em;top:40.4788em;"><span class="stl_15 stl_08 stl_45">N</span><span class="stl_15 stl_08 stl_62">A</span><span class="stl_15 stl_08 stl_49">T</span><span class="stl_15 stl_08 stl_22" style="word-spacing:-0.031em;">URAL LANGUAGE AI MODEL. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.8083em;top:42.7954em;"><span class="stl_15 stl_08 stl_21" style="word-spacing:1.2356em;">VIII.3. THE</span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.2629em;">&nbsp;</span><span class="stl_15 stl_08 stl_29" style="word-spacing:0.2512em;">OUTPUT IS INTENDED TO PROVIDE PRACTICAL AND USEFUL &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.0583em;top:44.1204em;"><span class="stl_15 stl_08 stl_19">INFORM</span><span class="stl_15 stl_08 stl_62">A</span><span class="stl_15 stl_08 stl_49">T</span><span class="stl_15 stl_08 stl_42" style="word-spacing:0.2695em;">ION ON THE SUBJECT M</span><span class="stl_15 stl_08 stl_62">A</span><span class="stl_15 stl_08 stl_49">T</span><span class="stl_15 stl_08 stl_22" style="word-spacing:0.2698em;">TER COVERED BASED ON YOUR &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.0583em;top:45.4371em;"><span class="stl_15 stl_08 stl_74" style="word-spacing:0.0512em;">INPUT. WHILE SUCH OUTPUT MAY CONCERN ISSUES REL</span><span class="stl_15 stl_08 stl_62">A</span><span class="stl_15 stl_08 stl_49">T</span><span class="stl_15 stl_08 stl_75" style="word-spacing:0.0502em;">ED TO LEGAL &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.0583em;top:46.7621em;"><span class="stl_15 stl_08 stl_76" style="word-spacing:0.3428em;">SERVICES OR DOCUMENTS, SUCH CONTENT IS NOT FORMAL LEGAL &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.0583em;top:48.0788em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.3755em;">ADVICE. YOU WILL NOT RE</span><span class="stl_15 stl_08 stl_77">L</span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.3668em;">Y </span><span class="stl_15 stl_08 stl_59">O</span><span class="stl_15 stl_08 stl_74" style="word-spacing:0.3902em;">N ANY OUTPUT OF THE SERVICES &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.0583em;top:49.4038em;"><span class="stl_15 stl_08 stl_49" style="word-spacing:0.6824em;">WITHOUT SEEKING ADVICE OF, AND/OR VETTING ANY OUTPUT &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.0583em;top:50.7204em;"><span class="stl_15 stl_08 stl_50" style="word-spacing:0.5858em;">THROUGH, A DU</span><span class="stl_15 stl_08 stl_77">L</span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.6008em;">Y </span><span class="stl_15 stl_08 stl_49">L</span><span class="stl_15 stl_08 stl_78" style="word-spacing:0.6324em;">ICENSED AND QUALIFIED LAWYER IN THE &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.0583em;top:52.0454em;"><span class="stl_15 stl_08 stl_38" style="word-spacing:0.0636em;">APPLICABLE SUBJECT M</span><span class="stl_15 stl_08 stl_62">A</span><span class="stl_15 stl_08 stl_49">T</span><span class="stl_15 stl_08 stl_22" style="word-spacing:0.0437em;">TER AND JURISDICTION. ARACOR EXPRESS</span><span class="stl_15 stl_08 stl_77">L</span><span class="stl_15 stl_08 stl_77">Y &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.0583em;top:53.3621em;"><span class="stl_15 stl_08 stl_14" style="word-spacing:0.22em;">DISCLAIMS ALL LIABILITY IN RESPECT OF YOUR ACTIONS TAKEN OR &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.0583em;top:54.6871em;"><span class="stl_15 stl_08 stl_79" style="word-spacing:0.1918em;">NOT TAKEN BASED ON ANY OUTPUT, OR OTHERWISE IN CONNECTION &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.0583em;top:56.0038em;"><span class="stl_15 stl_08 stl_80" style="word-spacing:0.0869em;">WITH YOUR USE OF THE SERVICES. ARACOR’S PROVISION OF SERVICES, &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.0583em;top:57.3288em;"><span class="stl_15 stl_08 stl_29" style="word-spacing:0.0265em;">INCLUDING ALL REL</span><span class="stl_15 stl_08 stl_25">A</span><span class="stl_15 stl_08 stl_49">T</span><span class="stl_15 stl_08 stl_55" style="word-spacing:0.0556em;">ED OUTPUT, ARE FOR GENERAL INFORM</span><span class="stl_15 stl_08 stl_62">A</span><span class="stl_15 stl_08 stl_49">T</span><span class="stl_15 stl_08 stl_19">IONAL &nbsp;</span></div>\n' +
    "\t\t\t\t</div>\n" +
    "\t\t\t</div>\n" +
    "\t\t</div>\n" +
    '\t\t<div class="stl_02">\n' +
    '\t\t\t<div class="stl_view">\n' +
    '\t\t\t\t<div class="stl_05 stl_06">\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.0583em;top:6.0954em;z-index:50;"><span class="stl_15 stl_08 stl_48" style="word-spacing:0.0452em;">PURPOSES ON</span><span class="stl_15 stl_08 stl_77">L</span><span class="stl_15 stl_08 stl_41" style="word-spacing:0.2673em;">Y. </span><span class="stl_15 stl_08 stl_59">Y</span><span class="stl_15 stl_08 stl_21" style="word-spacing:-0.004em;">OU ACKNOWLEDGE AND AGREES TH</span><span class="stl_15 stl_08 stl_62">A</span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.0042em;">T </span><span class="stl_15 stl_08 stl_49">T</span><span class="stl_15 stl_08 stl_81" style="word-spacing:0.0514em;">HEY DO NOT, &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.0583em;top:7.4204em;"><span class="stl_15 stl_08 stl_38" style="word-spacing:-0.0262em;">AND ARE NOT INTENDED TO, CONSTITUTE FORMAL LEGAL ADVICE. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.8083em;top:9.7371em;"><span class="stl_15 stl_08 stl_28" style="word-spacing:1.2361em;">VIII.4. YOU</span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.7599em;">&nbsp;</span><span class="stl_15 stl_08 stl_82" style="word-spacing:0.7576em;">UNDERSTAND TH</span><span class="stl_15 stl_08 stl_62">A</span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.7122em;">T </span><span class="stl_15 stl_08 stl_59">Y</span><span class="stl_15 stl_08 stl_42" style="word-spacing:0.733em;">OU ARE RESPONSIBLE FOR ALL &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.0583em;top:11.0621em;"><span class="stl_15 stl_08 stl_83" style="word-spacing:0.2738em;">DECISIONS MADE, ACTIONS TAKEN, AND FAILURES TO TAKE ACTION &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.0583em;top:12.3788em;"><span class="stl_15 stl_08 stl_75" style="word-spacing:0.5206em;">BASED ON YOUR USE OF THE SERVICES, WHICH USES N</span><span class="stl_15 stl_08 stl_62">A</span><span class="stl_15 stl_08 stl_49">T</span><span class="stl_15 stl_08 stl_84">URAL &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.0583em;top:13.7038em;"><span class="stl_15 stl_08 stl_24" style="word-spacing:0.4944em;">LANGUAGE AI MODELS TH</span><span class="stl_15 stl_08 stl_62">A</span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.4952em;">T </span><span class="stl_15 stl_08 stl_45">G</span><span class="stl_15 stl_08 stl_20">ENER</span><span class="stl_15 stl_08 stl_62">A</span><span class="stl_15 stl_08 stl_67">T</span><span class="stl_15 stl_08 stl_38" style="word-spacing:0.5172em;">E PREDICTIONS BASED ON &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.0583em;top:15.0204em;"><span class="stl_15 stl_08 stl_85">PA</span><span class="stl_15 stl_08 stl_49">T</span><span class="stl_15 stl_08 stl_17" style="word-spacing:0.238em;">TERNS IN D</span><span class="stl_15 stl_08 stl_62">A</span><span class="stl_15 stl_08 stl_70">T</span><span class="stl_15 stl_08 stl_45">A</span><span class="stl_15 stl_08 stl_29" style="word-spacing:0.225em;">. OUTPUT GENER</span><span class="stl_15 stl_08 stl_25">A</span><span class="stl_15 stl_08 stl_49">T</span><span class="stl_15 stl_08 stl_26" style="word-spacing:0.2112em;">ED BY N</span><span class="stl_15 stl_08 stl_62">A</span><span class="stl_15 stl_08 stl_49">T</span><span class="stl_15 stl_08 stl_86" style="word-spacing:0.1776em;">URAL LANGUAGE AI &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.0583em;top:16.3454em;"><span class="stl_15 stl_08 stl_28" style="word-spacing:0.785em;">MODELS IS PROBABILISTIC AND SHOULD BE E</span><span class="stl_15 stl_08 stl_41">V</span><span class="stl_15 stl_08 stl_45">A</span><span class="stl_15 stl_08 stl_27">LU</span><span class="stl_15 stl_08 stl_62">A</span><span class="stl_15 stl_08 stl_67">T</span><span class="stl_15 stl_08 stl_87" style="word-spacing:0.7976em;">ED FOR &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.0583em;top:17.6621em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.3301em;">ACCURACY AS APPROPRI</span><span class="stl_15 stl_08 stl_62">A</span><span class="stl_15 stl_08 stl_49">T</span><span class="stl_15 stl_08 stl_20" style="word-spacing:0.4055em;">E FOR YOUR USE CASE, INCLUDING BY &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.0583em;top:18.9871em;z-index:467;"><span class="stl_15 stl_08 stl_88" style="word-spacing:0.0102em;">ENSURING QUALIFIED LAWYER REVIEW OF SUCH OUTPUT. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:6.0083em;top:23.6288em;"><span class="stl_07 stl_08 stl_29" style="word-spacing:0.0081em;">Article IX.</span><span class="stl_07 stl_08 stl_13" style="word-spacing:1.2045em;">&nbsp;</span><span class="stl_07 stl_08 stl_38" style="word-spacing:0.0059em;">Liability and Indemnity &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:8.2583em;top:25.9454em;"><span class="stl_15 stl_08 stl_20" style="word-spacing:0.0136em;">IX.1. Neither Aracor, nor its officers, directors, employees, agents, successors, subsidiaries, &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:27.2704em;"><span class="stl_15 stl_08 stl_38" style="word-spacing:0.5906em;">distributors, affiliates are responsible or liable for any special, incidental, &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:28.5871em;"><span class="stl_15 stl_08 stl_27" style="word-spacing:0.088em;">consequential, punitive, or other indirect damages that result from the use of, or the &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:29.9121em;"><span class="stl_15 stl_08 stl_24" style="word-spacing:0.0905em;">inability to use, Services, even if Aracor has been advised of the possibility of such &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:31.2288em;"><span class="stl_15 stl_08 stl_21" style="word-spacing:0.0604em;">damages. In no event shall Aracor\'s total liability to you for all damages, losses, and &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:32.5538em;"><span class="stl_15 stl_08 stl_27" style="word-spacing:0.3043em;">causes of action resulting from your use of Services, whether in contract, tort &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:33.8704em;"><span class="stl_15 stl_08 stl_27" style="word-spacing:0.0022em;">(including, but not limited to, negligence) or otherwise, exceed one dollar ($1.00). &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:8.2583em;top:36.1954em;"><span class="stl_15 stl_08 stl_48" style="word-spacing:0.0188em;">IX.2. To the extent that any jurisdiction does not allow the exclusion or limitation of direct, &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:37.5121em;"><span class="stl_15 stl_08 stl_27" style="word-spacing:0.1717em;">incidental or consequential damages, portions of the above limitation or exclusion &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:38.8371em;z-index:1175;"><span class="stl_15 stl_08 stl_89" style="word-spacing:0.0191em;">may not apply. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:8.2583em;top:41.1538em;"><span class="stl_15 stl_08 stl_50" style="word-spacing:-0.0461em;">IX.3. By</span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.3654em;">&nbsp;</span><span class="stl_15 stl_08 stl_29" style="word-spacing:0.3683em;">using the Services, you agree to indemnify Aracor, its officers, directors, &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:42.4788em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.252em;">employees, successors, agents, distributors, and affiliates for any and all claims, &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:43.7954em;"><span class="stl_15 stl_08 stl_27" style="word-spacing:0.1388em;">damages, losses, and causes of action (including reasonable attorneys\' fees) arising &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:45.1204em;"><span class="stl_15 stl_08 stl_21" style="word-spacing:0.0024em;">out of or relating to the &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:46.4371em;"><span class="stl_15 stl_08 stl_14" style="word-spacing:0.731em;">i. use</span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.0081em;">&nbsp;</span><span class="stl_15 stl_08 stl_33" style="word-spacing:-0.0007em;">of the Services; &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:48.7621em;"><span class="stl_15 stl_08 stl_34" style="word-spacing:0.4532em;">ii. breach</span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.0086em;">&nbsp;</span><span class="stl_15 stl_08 stl_22" style="word-spacing:-0.0162em;">of this Agreement; or &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:51.0788em;"><span class="stl_15 stl_08 stl_28" style="word-spacing:0.1772em;">iii. provision</span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.0011em;">&nbsp;</span><span class="stl_15 stl_08 stl_26" style="word-spacing:0.0037em;">of, or interaction with, the Customer Content. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:6.0083em;top:53.4038em;"><span class="stl_07 stl_08 stl_21" style="word-spacing:0.0068em;">Article X. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:12.0083em;top:53.4038em;"><span class="stl_07 stl_08 stl_90" style="word-spacing:0.0142em;">Force Majeure &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:8.2583em;top:55.7204em;"><span class="stl_15 stl_08 stl_17" style="word-spacing:0.2865em;">X.1. Aracor</span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.0837em;">&nbsp;</span><span class="stl_15 stl_08 stl_24" style="word-spacing:0.0739em;">shall have no liability to you under this Agreement if it is prevented from or &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:57.0454em;"><span class="stl_15 stl_08 stl_21" style="word-spacing:0.106em;">delayed in performing its obligations under this Agreement, or from carrying on its &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:58.3621em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.2953em;">business, by acts, events, omissions or accidents beyond its reasonable control, &nbsp;</span></div>\n' +
    "\t\t\t\t</div>\n" +
    "\t\t\t</div>\n" +
    "\t\t</div>\n" +
    '\t\t<div class="stl_02">\n' +
    '\t\t\t<div class="stl_view">\n' +
    '\t\t\t\t<div class="stl_05 stl_06">\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:6.0954em;"><span class="stl_15 stl_08 stl_22" style="word-spacing:0.0399em;">including, without limitation, strikes, lock-outs or other industrial disputes, epidemic, &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:7.4204em;"><span class="stl_15 stl_08 stl_26" style="word-spacing:0.0481em;">pandemic, failure of a utility service or transport or telecommunications network, act &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:8.7371em;"><span class="stl_15 stl_08 stl_34" style="word-spacing:0.1201em;">of God, war, riot, civil commotion, malicious damage, compliance with any law or &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:10.0621em;"><span class="stl_15 stl_08 stl_28" style="word-spacing:0.2021em;">governmental order, rule, regulation or direction, accident, breakdown of plant or &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:11.3788em;"><span class="stl_15 stl_08 stl_48" style="word-spacing:0.0054em;">machinery, fire, flood, storm or default of suppliers or sub-contractors. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:6.0083em;top:13.7038em;"><span class="stl_07 stl_08 stl_50" style="word-spacing:0.0079em;">Article XI.</span><span class="stl_07 stl_08 stl_13" style="word-spacing:1.2034em;">&nbsp;</span><span class="stl_07 stl_08 stl_19">Miscellaneous &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:8.2583em;top:16.0204em;"><span class="stl_15 stl_08 stl_38" style="word-spacing:-0.0479em;">XI.1. If</span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.225em;">&nbsp;</span><span class="stl_15 stl_08 stl_19" style="word-spacing:0.2201em;">any provision of this Agreement is found to be unenforceable or invalid, that &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:17.3454em;"><span class="stl_15 stl_08 stl_26" style="word-spacing:0.0807em;">provision will be limited or eliminated to the minimum extent necessary so that this &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:18.6621em;"><span class="stl_15 stl_08 stl_38" style="word-spacing:0.0041em;">Agreement will otherwise remain in full force and effect and enforceable. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:8.2583em;top:20.9871em;"><span class="stl_15 stl_08 stl_22" style="word-spacing:0.1063em;">XI.2. This Agreement is not assignable, transferable or sublicensable by you except with &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:22.3038em;"><span class="stl_15 stl_08 stl_50" style="word-spacing:0.101em;">Aracor’s prior written consent. Aracor may transfer and assign any of its rights and &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:23.6288em;"><span class="stl_15 stl_08 stl_22" style="word-spacing:-0.0079em;">obligations under this Agreement without consent. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:8.2583em;top:25.9454em;"><span class="stl_15 stl_08 stl_26" style="word-spacing:0.0316em;">XI.3. This Agreement is the complete and exclusive statement of the mutual understanding &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:27.2704em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.1254em;">of the parties and supersedes and cancels all previous written and oral agreements, &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:28.5871em;"><span class="stl_15 stl_08 stl_26" style="word-spacing:0.3142em;">communications and other understandings relating to the subject matter of this &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:29.9121em;"><span class="stl_15 stl_08 stl_22" style="word-spacing:0.1505em;">Agreement, and that all waivers and modifications must be in a writing signed by &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:31.2288em;"><span class="stl_15 stl_08 stl_39" style="word-spacing:0.0002em;">both parties, except as otherwise provided herein. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:8.2583em;top:33.5538em;"><span class="stl_15 stl_08 stl_32" style="word-spacing:-0.0459em;">XI.4. No</span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.1581em;">&nbsp;</span><span class="stl_15 stl_08 stl_48" style="word-spacing:0.157em;">agency, partnership, joint venture, or employment is created as a result of this &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:34.8704em;"><span class="stl_15 stl_08 stl_38" style="word-spacing:0.17em;">Agreement and you do not have any authority of any kind to bind Aracor in any &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:36.1954em;z-index:1260;"><span class="stl_15 stl_08 stl_78" style="word-spacing:0.0125em;">respect whatsoever. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:8.2583em;top:38.5121em;"><span class="stl_15 stl_08 stl_21" style="word-spacing:0.0417em;">XI.5. All notices under this Agreement will be in writing and will be deemed to have been &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:39.8371em;"><span class="stl_15 stl_08 stl_22" style="word-spacing:0.2442em;">duly given when received, if personally delivered; when receipt is electronically &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:41.1538em;"><span class="stl_15 stl_08 stl_26" style="word-spacing:0.0031em;">confirmed, if transmitted by facsimile or e-mail; the day after it is sent, if sent for next &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:42.4788em;"><span class="stl_15 stl_08 stl_27" style="word-spacing:0.1263em;">day delivery by recognized overnight delivery service; and upon receipt, if sent by &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:43.7954em;"><span class="stl_15 stl_08 stl_24" style="word-spacing:0.006em;">certified or registered mail, return receipt requested. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:8.2583em;top:46.1204em;"><span class="stl_15 stl_08 stl_29" style="word-spacing:-0.0469em;">XI.6. This</span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.1604em;">&nbsp;</span><span class="stl_15 stl_08 stl_21" style="word-spacing:0.21em;">Agreement shall be governed by the laws of the State of Delaware without &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:47.4371em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.0375em;">regard to its conflict of laws provisions, and any dispute arising from this Agreement &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.5083em;top:48.7621em;"><span class="stl_15 stl_08 stl_26" style="word-spacing:0.0033em;">shall be exclusively referred to the Delaware courts. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:6.0083em;top:52.4038em;"><span class="stl_15 stl_08 stl_42" style="word-spacing:-0.0011em;">Last Updated: February 29, 2024 &nbsp;</span></div>\n' +
    "\t\t\t\t</div>\n" +
    "\t\t\t</div>\n" +
    "\t\t</div>\n" +
    "\t</body>\n" +
    "</html>",
};

export const Terms = () => {
  return (
    <section className="grid grid-cols-[minmax(0,1fr)_auto_minmax(0,1fr)] h-dvh w-dvw bg-ar-dark">
      <div className="h-12 w-1/2 m-4 border-b border-b-white/10">
        <Link to={ROUTES.APP} className="font-['FinancierText'] text-2xl">
          <span className="text-white">Aracor</span>
          <span className="text-ar-accent">.</span>
        </Link>
      </div>
      <ScrollArea>
        <div dangerouslySetInnerHTML={markup} className="bg-white" />
      </ScrollArea>
    </section>
  );
};
