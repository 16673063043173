import axios, { AxiosError } from "axios";
import get from "lodash/get";
import {
  ApiErrorCodes,
  ApiPayload,
  ApiStatus,
  ResponsePayload,
  ResponseState,
} from "@common/types";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";

export const mapToResponsePayload = <T>(data: ApiPayload<T> | null): ResponsePayload<T> => {
  if (typeof data !== "object") {
    return {
      state: ResponseState.failure,
      message: "INVALID_RESPONSE_DATA",
    };
  }

  switch (data?.status) {
    case ApiStatus.success:
      return {
        state: ResponseState.success,
        message: data?.message || "UNKNOWN_MESSAGE",
        payload: data?.data,
      };
    case ApiStatus.fail: {
      return data?.error_code
        ? {
            state: ResponseState.withErrorHandling,
            message: data?.message || "UNKNOWN_ERROR_MESSAGE",
          }
        : {
            state: ResponseState.withErrorHandling,
            message: "UNKNOWN_RESPONSE_STRUCTURE",
          };
    }
    default:
      return {
        state: ResponseState.failure,
        message: "UNHANDLED_SERVER_ERROR",
      };
  }
};

export const normalizeResponseError = (error: unknown, defaultMessage?: string): string => {
  if (axios.isAxiosError(error)) {
    const detail: string | null = get((error as AxiosError)?.response, "data.data.detail", null);

    const errorCode: string | undefined = (error as AxiosError<ApiPayload<object>>)?.response?.data
      ?.error_code;

    if (typeof errorCode === "string" && (errorCode as string).length) {
      const errorMessage = ApiErrorCodes[errorCode as keyof typeof ApiErrorCodes];

      if (errorMessage) {
        return errorMessage;
      }
    }

    if (typeof detail === "string" && (detail as string).length) {
      return detail;
    }

    const responseMessage: string | undefined = (error as AxiosError<ApiPayload<object>>)?.response
      ?.data?.message;

    if (typeof responseMessage === "string" && (responseMessage as string).length) {
      return responseMessage;
    }

    return defaultMessage ? defaultMessage : "Server error occurred";
  }

  return (error as Error).message;
};

export const getErrorMessage = (error: FetchBaseQueryError | SerializedError | undefined) => {
  const errorData = (error as FetchBaseQueryError).data as {
    error_code: keyof typeof ApiErrorCodes;
    message: string;
  };

  return ApiErrorCodes[errorData?.error_code] || errorData?.message;
};
