import api from "./api.ts";

import { ApiPayload, Citation, DocumentSummary } from "@common/types";

export const dueDiligenceApi = api.injectEndpoints({
  endpoints: (builder) => ({
    checkIsSummaryCollectionPrepared: builder.query({
      query: ({ collectionId }) => ({
        url: "due_diligence/summary/is_collection_prepared",
        params: {
          collection_id: collectionId,
        },
      }),
      transformResponse: (response: ApiPayload<boolean>) => response?.data ?? false,
    }),
    prepareSummaryCollection: builder.mutation({
      query: ({ collectionId }) => ({
        url: "due_diligence/summary/prepare_collection",
        method: "POST",
        params: {
          collection_id: collectionId,
        },
      }),
    }),
    getCollectionSummary: builder.query({
      query: ({ collectionId }) => ({
        url: "due_diligence/summary/get_summary",
        params: {
          collection_id: collectionId,
        },
      }),
      transformResponse: (response: ApiPayload<{ document_summaries: DocumentSummary[] }>) =>
        response?.data?.document_summaries ?? [],
    }),
    checkIsComparisonCollectionPrepared: builder.query({
      query: ({ collectionId }) => ({
        url: "due_diligence/comparison/is_collection_prepared",
        params: {
          collection_id: collectionId,
        },
      }),
      transformResponse: (response: ApiPayload<boolean>) => response?.data ?? false,
    }),
    prepareComparisonCollection: builder.mutation({
      query: ({ collectionId }) => ({
        url: "due_diligence/comparison/prepare_collection",
        method: "POST",
        params: {
          collection_id: collectionId,
        },
      }),
    }),
    getCollectionComparison: builder.query({
      query: ({ collectionId }) => ({
        url: "due_diligence/comparison/get_comparison",
        params: {
          collection_id: collectionId,
        },
      }),
      transformResponse: (
        response: ApiPayload<{ comparison_content: string; citations: Citation[] }>
      ) => ({
        comparison_content: response.data.comparison_content ?? "",
        citations: response.data.citations ?? [],
      }),
    }),
  }),
});

export const {
  useLazyCheckIsSummaryCollectionPreparedQuery,
  usePrepareSummaryCollectionMutation,
  useLazyGetCollectionSummaryQuery,
  useLazyCheckIsComparisonCollectionPreparedQuery,
  usePrepareComparisonCollectionMutation,
  useLazyGetCollectionComparisonQuery,
} = dueDiligenceApi;
