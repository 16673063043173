import { FC, PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "@/constants";

export const FormLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <section className="grid grid-cols-[minmax(0,1fr)_auto_minmax(0,1fr)] h-dvh w-dvw bg-ar-dark overflow-auto">
      <div className="m-5">
        <Link to={ROUTES.APP} className="font-['FinancierText'] text-2xl mx-4 my-16">
          <span className="text-white">Aracor</span>
          <span className="text-ar-accent">.</span>
        </Link>
      </div>
      <div
        className="
        self-start
        place-items-start
        px-20 py-10
        my-10
        bg-white dark:bg-slate-700
        rounded-md
        overflow-y-auto"
      >
        {children}
      </div>
      <div />
    </section>
  );
};
