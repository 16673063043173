import React from "react";
import { Dropzone, FileRejection } from "@mantine/dropzone";

import { debouncedNotifications } from "@/utils";
import { UseFormSetValue } from "react-hook-form";

interface FormValues {
  folder: string;
  files: File[];
  isRedact: boolean;
}

interface DropProps {
  files: File[];
  setValue: UseFormSetValue<FormValues>;
}

export const DragAndDrop: React.FC<DropProps> = ({ files, setValue }) => {
  const handleDrop = (uploadedFiles: File[]) => {
    setValue("files", [...files, ...Array.from(uploadedFiles)]);
  };

  const handleReject = (fileRejections: FileRejection[]) => {
    debouncedNotifications.show({
      title: "Error",
      message:
        Array.isArray(fileRejections) && fileRejections.length
          ? `Invalid file format ${fileRejections[0].file.type}`
          : "Invalid file format",
      color: "red",
    });
  };

  return (
    <Dropzone
      onDrop={handleDrop}
      onReject={handleReject}
      accept={[
        "application/pdf",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ]}
      maxSize={30 * 1024 ** 2}
      className="flex-grow flex flex-col justify-center items-center min-h-48
        rounded-xl border-2 border-dashed border-gray-200 bg-white hover:bg-gray-100
        cursor-pointer
        [&>.mantine-Dropzone-inner]:h-full"
    >
      <div
        className="flex flex-row justify-start items-center h-full w-full"
        style={{ pointerEvents: "none" }}
      >
        <div className="flex flex-row justify-center items-center h-full w-full">
          <div className="flex flex-col justify-center items-cente">
            <span className="font-semibold text-xl text-ar-dark text-center">
              <Dropzone.Accept>Drop files here</Dropzone.Accept>
              <Dropzone.Reject>Only PDF or DOCX files less than 30 MB are accepted</Dropzone.Reject>
              <Dropzone.Idle>Drag and drop files here</Dropzone.Idle>
            </span>
            <span className="hidden xl:inline-block text-ar-dark">
              <p className="text-center text-ar-brown">
                <span className="underline">or select from file browser</span>
                <sup>†</sup>
              </p>
              <p className="text-center p-4">
                <sup>†</sup>
                <span className="italic text-brown">PDF and DOCX, 30 MB max</span>
              </p>
            </span>
          </div>
        </div>
      </div>
    </Dropzone>
  );
};
