import React from "react";
import { Button, LoadingOverlay } from "@mantine/core";
import { UserService } from "@/services";
import { ResponseState } from "@common/types/api/response.ts";
import { notifications } from "@mantine/notifications";
import { usersApi } from "@/redux/api";

export const EmailConfirmationOverlay: React.FC = () => {
  const { data: userData } = usersApi.endpoints.getUserData.useQueryState(undefined);

  const isConfirmEmailOverlayVisible =
    userData?.is_email_confirmed === false &&
    process.env.VITE_RESTRICT_USERS_WITH_UNCONFIRMED_EMAIL === "true";

  const handleRequestEmailConfirmationClick = async () => {
    try {
      const requestEmailConfirmationResponse = await UserService.requestEmailConfirmation();
      if (requestEmailConfirmationResponse.state === ResponseState.success) {
        notifications.show({
          title: "Email confirmation sent.",
          message: `Email confirmation message will be sent to ${userData?.email}. Please check your inbox and your spam folder. If you do not receive the email within a few minutes, please contact us at support@aracor.ai. Please refresh this page after confirming your email.`,
          color: "green",
        });
      } else {
        throw new Error(requestEmailConfirmationResponse.message);
      }
    } catch {
      notifications.show({
        title: "Email confirmation failed.",
        message: `Email confirmation message could not be sent to ${userData?.email}. Please make sure the email address is correct. If you require further assistance, please contact us at support@aracor.ai.`,
        color: "red",
        autoClose: false,
      });
    }
  };

  return isConfirmEmailOverlayVisible ? (
    <LoadingOverlay
      visible={isConfirmEmailOverlayVisible}
      overlayProps={{ blur: 5, radius: "md" }}
      zIndex={20}
      loaderProps={{
        children: (
          <div className="flex flex-col justify-center items-center mx-10 gap-8">
            <div className="w-1/2 text-2xl text-center">
              Please confirm your email address to activate your account and to use the full
              functionality of the site. If you would like us to resend the confirmation email,
              please use the button below.
            </div>
            <Button
              leftSection={<span className="material-symbols-outlined">mail</span>}
              size="lg"
              color="ar-accent"
              onClick={handleRequestEmailConfirmationClick}
            >
              Confirm Email
            </Button>
          </div>
        ),
      }}
    />
  ) : null;
};
