import React, { useEffect, useState } from "react";
import { useAppDispatch } from "@/redux/hooks.ts";
import { setIsDocumentLoaded } from "@/redux/slices/documents.ts";
import { setHighlightedReference } from "@/redux/slices/qa.ts";

import { ActionIcon, Button, TextInput, ScrollArea, Tooltip } from "@mantine/core";
import { useForm, Controller } from "react-hook-form";
import { IconCircleX } from "@tabler/icons-react";
import { humanFileSize } from "@/utils";
import { useAddDocumentsToCollectionMutation, useUploadDocumentMutation } from "@/redux/api";
import { DragAndDrop } from "./components/DragAndDrop";
import { modals } from "@mantine/modals";
import { FileTreeItem, Modals } from "@common/types";
import { CloudDocsService } from "@/services/index.ts";
import { CheckboxCard } from "./components/CheckboxCard";
import { ROUTES } from "@/constants";
import clsx from "clsx/lite";

interface UploadProps {
  collection?: FileTreeItem;
  selectedDocumentsFullPaths?: string[];
  isFolderInputForced?: boolean;
  onCancelClick?: () => void;
}

interface FormValues {
  folder: string;
  files: File[];
  isRedact: boolean;
}

export const Upload: React.FC<UploadProps> = ({
  collection,
  selectedDocumentsFullPaths,
  isFolderInputForced = false,
  onCancelClick,
}) => {
  const [filesToUpload, setFilesToUpload] = useState<
    ({ name: string; size: number | null } | File)[]
  >([]);
  const [showInputFolder, setShowInputFolder] = useState<boolean>(false);

  const appDispatch = useAppDispatch();

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      folder: "",
      files: [],
      isRedact: false,
    },
  });

  const watchFiles = watch("files", []);
  const files = getValues("files");

  const [uploadDocument, { isLoading: isUploadDocumentLoading }] = useUploadDocumentMutation();

  const [addDocumentsToCollection, { isLoading: isAddDocumentsToCollectionLoading }] =
    useAddDocumentsToCollectionMutation();

  useEffect(() => {
    if (collection) {
      setValue("folder", collection.name);
    }
  }, [setValue, collection]);

  useEffect(() => {
    if (selectedDocumentsFullPaths?.length) {
      // This code is necessary for now due to differing file structures between documents retrieved from providers and those manually uploaded by the user.
      const transformedFiles =
        selectedDocumentsFullPaths?.map((path) => {
          const fileName = path.split("/").pop();
          return { name: fileName || "", size: null };
        }) || [];

      setFilesToUpload(transformedFiles);
    } else {
      setFilesToUpload(files);
    }
  }, [selectedDocumentsFullPaths, files]);

  const handleCheckboxChange = () => {
    if (!isFolderInputForced) {
      setShowInputFolder(!showInputFolder);
    }
  };

  const handleIsRedactedChange = () => {
    const isRedact = getValues("isRedact");
    //it is important to pass shouldDirty and shouldValidate to trigger re-render
    setValue("isRedact", !isRedact, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const closeAndResetForm = () => {
    modals.close(Modals.UPLOAD);
    reset();
  };

  const handleRemoveFile = (fileIndex: number) => {
    // The first setFilesToUpload() works for the files that are added from Provider and the part with newFilesSet works only for manual uploaded documents
    setFilesToUpload((prevFiles) => prevFiles.filter((_, index) => index !== fileIndex));
    const newFilesSet = getValues("files")?.filter((_file, index) => fileIndex !== index);

    if (newFilesSet) {
      setValue("files", newFilesSet);
      setFilesToUpload(newFilesSet);
    }
    // TODO(Ihor Dubas): Think how to use only one setFilesToUpload()
  };

  const handleUploadClick = async ({ folder, isRedact }: FormValues) => {
    if (selectedDocumentsFullPaths?.length) {
      await CloudDocsService.downloadDocumentsFromProvider(
        selectedDocumentsFullPaths,
        isRedact,
        folder
      );
    }

    const formData = new FormData();

    if (watchFiles?.length) {
      Array.from(files).forEach((file) => {
        formData.append("files", file);
      });
    }

    appDispatch(setIsDocumentLoaded(false));
    appDispatch(setHighlightedReference(null));

    const options = folder ? { folder_name: folder, redact: isRedact } : { redact: isRedact };

    if (collection) {
      await addDocumentsToCollection({ collection_id: collection.collection_id, data: formData });
    } else {
      await uploadDocument({ data: formData, ...options });
    }
    // TODO(Ihor Dubas) Replace with more elegant soltion as navigate() won't work here because current component isn't part of Router
    if (showInputFolder || isFolderInputForced) {
      window.location.href = `${ROUTES.DOCUMENTS}/folders`;
    } else {
      window.location.href = `${ROUTES.DOCUMENTS}/files`;
    }

    closeAndResetForm();
  };

  return (
    <>
      <div
        className={clsx(
          "bg-ar-dark/5 p-8 flex flex-col gap-2",
          !isFolderInputForced && "rounded-2xl"
        )}
      >
        <div className="text-2xl text-ar-dark">Upload new document</div>
        <div className="flex flex-col gap-2 overflow-y-scroll">
          <ScrollArea type="auto">
            <div className="max-h-[200px] flex flex-col gap-0.5">
              {filesToUpload?.length
                ? filesToUpload?.map(({ name, size }, index) => (
                    <div
                      className={`grid ${selectedDocumentsFullPaths?.length ? "grid-cols-[1fr_6rem]" : "grid-cols-[1fr_6rem_6rem]"} items-center gap-4 h-12 min-h-12 px-3 bg-white rounded-lg hover:bg-transparent`}
                      key={index}
                    >
                      <div className="truncate">
                        <Tooltip label={name}>
                          <span className="truncate w-full">{name}</span>
                        </Tooltip>
                      </div>
                      {size && <div className="justify-self-end">{humanFileSize(size)}</div>}
                      <div className="justify-self-end">
                        <ActionIcon
                          variant="transparent"
                          radius={50}
                          color="#D04035"
                          onClick={() => handleRemoveFile(index)}
                        >
                          <IconCircleX stroke={1.5} />
                        </ActionIcon>
                      </div>
                    </div>
                  ))
                : null}
            </div>
          </ScrollArea>
        </div>
        {!selectedDocumentsFullPaths?.length && <DragAndDrop files={files} setValue={setValue} />}
        <form onSubmit={handleSubmit(handleUploadClick)}>
          <div className="grid grid-cols-3 justify-between gap-4 pt-4">
            {process.env.VITE_IS_REDACTION_ENABLED === "true" && !collection?.collection_id ? (
              <>
                <CheckboxCard
                  onClick={handleIsRedactedChange}
                  checked={getValues("isRedact")}
                  label="Redact"
                  tooltip={
                    <>
                      <strong>
                        Note: This feature is work-in-progress and may not redact all sensitive
                        information yet.
                      </strong>
                      <br />
                      <br />
                      Redact sensitive information such as names, addresses, phone numbers, etc.
                      from the documents you upload so that your or your client's privacy is
                      protected from the AIs that we use to analyze the documents. You will have a
                      chance to check the redacted version of the document before starting a
                      conversation with the AI.
                      <br />
                      <br />
                      <i>
                        Note: Redaction of your documents takes extra resources and may noticeably
                        slow down the workflow with your redacted documents.
                      </i>
                    </>
                  }
                >
                  <div className="col-span-2 text-ar-brown">
                    Clear documents from sensitive information
                  </div>
                </CheckboxCard>
              </>
            ) : (
              <CheckboxCard
                disabled={isFolderInputForced}
                onClick={handleCheckboxChange}
                checked={isFolderInputForced || showInputFolder}
                label="Upload to folder"
                tooltip={`Allows grouping up documents into a folder and chatting with all of the documents in the folder at the same time. Use this to easily retrieve information from multiple documents, compare documents, and more.`}
              >
                <Controller
                  name="folder"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      type="text"
                      size="md"
                      placeholder="Enter folder name*"
                      error={errors.folder?.type === "required"}
                      disabled={!!collection || !(isFolderInputForced || showInputFolder)}
                      className="mr-10"
                      {...field}
                    />
                  )}
                  rules={{
                    validate: {
                      required: (value) => !showInputFolder || !!value.length,
                    },
                  }}
                />
              </CheckboxCard>
            )}
            {process.env.VITE_IS_MULTI_CHAT_ENABLED === "true" && !collection?.collection_id ? (
              <CheckboxCard
                disabled={isFolderInputForced}
                onClick={handleCheckboxChange}
                checked={isFolderInputForced || showInputFolder}
                label="Upload to folder"
                tooltip={`Allows grouping up documents into a folder and chatting with all of the documents in the folder at the same time. Use this to easily retrieve information from multiple documents, compare documents, and more.`}
              >
                <Controller
                  name="folder"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      type="text"
                      size="md"
                      placeholder="Enter folder name*"
                      error={errors.folder?.type === "required"}
                      disabled={!!collection || !(isFolderInputForced || showInputFolder)}
                      className="mr-10"
                      {...field}
                    />
                  )}
                  rules={{
                    validate: {
                      required: (value) => !showInputFolder || !!value.length,
                    },
                  }}
                />
              </CheckboxCard>
            ) : (
              <div />
            )}
            <div className="flex justify-end items-end space-x-4 mt-4">
              {collection?.collection_id ? (
                <Button
                  variant="outline"
                  color="ar-dark"
                  rightSection={<span className="material-symbols-outlined">close</span>}
                  size="lg"
                  className="data-[disabled=true]:bg-ar-gray data-[disabled=true]:text-ar-dark"
                  onClick={onCancelClick}
                >
                  Cancel
                </Button>
              ) : null}
              <Button
                color="ar-accent"
                loading={isAddDocumentsToCollectionLoading || isUploadDocumentLoading}
                rightSection={<span className="material-symbols-outlined">upload</span>}
                disabled={!filesToUpload?.length}
                size="lg"
                type="submit"
                className="data-[disabled=true]:bg-ar-gray data-[disabled=true]:text-ar-dark/40"
              >
                Upload
              </Button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
