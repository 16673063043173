const ROUTES = {
  // Public pages
  HOME: "/",
  TERMS: "/pages/tos",
  PRIVACY: "/pages/privacy",

  // Auth pages
  SIGN_IN: "/app/sign-in",
  SIGN_UP: "/app/sign-up",
  RESET_PASSWORD: "/app/reset-password",
  CONFIRM_EMAIL: "/app/confirm-email",
  NOT_AUTHORIZED: "/app/not-authorized",

  // App pages
  APP: "/app",
  WORD: "/app/how-to/word-plugin",
  VIEWER: "/app/viewer",
  DOCUMENTS: "/app/documents",
  SETTINGS: "/app/settings",
  RESEARCH: "/app/research",
  DILIGENCE: "/app/diligence",
  HELP: "/app/help",

  // Callbacks
  GOOGLE: "auth/google/callback",
  SHARE_POINT_CALLBACK: "/app/share_point_callback",
  ONE_DRIVE_CALLBACK: "/app/one_drive_callback",
  DROP_BOX_CALLBACK: "/app/drop_box_callback",
  BOX_COM_CALLBACK: "/app/box_com_callback",
};

export default ROUTES;
