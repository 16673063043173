import { useState } from "react";
import {
  EmailConfirmationOverlay,
  FilesTree,
  Logo,
  Menu,
} from "@/components/New/components/General/components";
import { ActionIcon, ScrollArea, Tooltip } from "@mantine/core";
import { Link, Outlet, useLocation } from "react-router-dom";
import { ROUTES } from "@/constants";

export const General = () => {
  const [isNavigationVisible, setIsNavigationVisible] = useState(true);

  const { pathname } = useLocation();

  const isApplication = pathname.startsWith(ROUTES.VIEWER);

  return (
    <div
      className={`h-svh w-svw grid ${isNavigationVisible ? "grid-cols-[auto_1fr] font-['National']" : ""}`}
    >
      <div
        className={`${isNavigationVisible ? "visible" : "hidden"} grid grid-rows-[auto_1fr] gap-12 max-h-dvh w-72 bg-ar-dark p-6`}
      >
        <div className="flex flex-row justify-between items-center h-14 pr-2 pb-8 pl-4 border-b-2 border-b-stone-500">
          <Link to="/" className="font-['FinancierText'] text-2xl">
            <span className="text-white">Aracor</span>
            <span className="text-ar-accent">.</span>
          </Link>
          <Tooltip label="Hide menu" position="right">
            <ActionIcon
              variant="subtle"
              size="lg"
              className="hover:!bg-ar-gray/10"
              onClick={() => setIsNavigationVisible(!isNavigationVisible)}
            >
              <span className="material-symbols-outlined text-white">left_panel_close</span>
            </ActionIcon>
          </Tooltip>
        </div>
        {isApplication ? <FilesTree /> : <Menu />}
      </div>
      <div className="relative h-dvh max-h-dvh overflow-hidden">
        <ScrollArea type="auto" h="100dvh">
          <Outlet />
        </ScrollArea>
        <EmailConfirmationOverlay />
      </div>
      {isNavigationVisible ? null : (
        <Tooltip label="Show menu" position="right">
          <ActionIcon
            color="ar-accent"
            size="xl"
            className="!absolute top-2 left-2 z-40"
            onClick={() => setIsNavigationVisible(!isNavigationVisible)}
          >
            <Logo />
          </ActionIcon>
        </Tooltip>
      )}
    </div>
  );
};
