import { Link } from "react-router-dom";
import { ScrollArea } from "@mantine/core";
import { ROUTES } from "@/constants";

const markup = {
  __html:
    '<!DOCTYPE html><!--[if IE]>  <html class="ie"> <![endif]-->\n' +
    "<html>\n" +
    "\t<head>\n" +
    '\t\t<meta charset="utf-8" />\n' +
    "\t\t<title>\n" +
    "\t\t</title>\n" +
    '\t\t<link rel="stylesheet" type="text/css" href="privacy_files/style.css" />\n' +
    "\t</head>\n" +
    "\t<body>\n" +
    '\t\t<div class="stl_02">\n' +
    '\t\t\t<div class="stl_03">\n' +
    '\t\t\t\t<object data="privacy_files/img_01.svg" type="image/svg+xml" class="stl_04" style="position:absolute; width:49.5833em; height:70.0833em;">\n' +
    '\t\t\t\t\t<embed src="privacy_files/img_01.svg" type="image/svg+xml" />\n' +
    "\t\t\t\t</object>\n" +
    "\t\t\t</div>\n" +
    '\t\t\t<div class="stl_view">\n' +
    '\t\t\t\t<div class="stl_05 stl_06">\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:5.3024em;"><span class="stl_07 stl_08 stl_09">A</span><span class="stl_10 stl_08 stl_11" style="word-spacing:0.0706em;">RACOR </span><span class="stl_07 stl_08 stl_12">P</span><span class="stl_10 stl_08 stl_13" style="word-spacing:0.0711em;">RIVACY </span><span class="stl_07 stl_08 stl_12">P</span><span class="stl_10 stl_08 stl_14">OLICY &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:7.9662em;"><span class="stl_15 stl_08 stl_16" style="word-spacing:0.3895em;">We at Aracor respect your privacy and are strongly committed to keeping secure any &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:9.0245em;"><span class="stl_15 stl_08 stl_17" style="word-spacing:0.1069em;">information we obtain from you or about you. This Privacy Policy describes our practices with &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:10.0745em;"><span class="stl_15 stl_08 stl_18" style="word-spacing:0.055em;">respect to Personal Data we collect from or about you when you use our website, applications, &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:11.1329em;"><span class="stl_15 stl_08 stl_14" style="word-spacing:0.0056em;">and services (collectively, “Services”). &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:13.2412em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.0315em;">The data protection regulations form an integral part of our Terms of Service. Capitalized words &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:14.2912em;"><span class="stl_15 stl_08 stl_20" style="word-spacing:0.0025em;">have the same meaning as in the Terms of Service currently in force. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:16.8734em;"><span class="stl_21 stl_08 stl_22">1. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.1em;top:16.8734em;"><span class="stl_21 stl_08 stl_19">W</span><span class="stl_23 stl_08 stl_19" style="word-spacing:0.0756em;">HAT THIS PRIVACY POLICY COVERS &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:19.1912em;"><span class="stl_15 stl_08 stl_24" style="word-spacing:0.0965em;">This Policy applies to visitors of our website, Aracor’s prospective clients, clients and partners &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:20.2412em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.0054em;">(“individuals” or “you”) as applicable in the course of using our Services or visiting our websites. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:22.3495em;"><span class="stl_15 stl_08 stl_25" style="word-spacing:0.1003em;">If you do not agree with our policies and practices described in this Policy, please do not use &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:23.4079em;"><span class="stl_15 stl_08 stl_18" style="word-spacing:0.0062em;">our Services, do not visit our website, and do not provide your information to us. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:25.5162em;"><span class="stl_15 stl_08 stl_17" style="word-spacing:0.1162em;">This Policy applies to personal data, as defined below in Section </span><a href="#1_0"><span class="stl_15 stl_08 stl_26">2</span></a><span class="stl_15 stl_08 stl_18" style="word-spacing:0.108em;">, we collect to provide you &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:26.5662em;"><span class="stl_15 stl_08 stl_27" style="word-spacing:0.0487em;">with Services. This Policy does not apply to anonymized, de-identified or aggregate information &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:27.6245em;"><span class="stl_15 stl_08 stl_18" style="word-spacing:0.005em;">if it is not personal data. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:29.7329em;"><span class="stl_15 stl_08 stl_28" style="word-spacing:0.0722em;">Under the General Data Protection Regulation (EU) 2016/679 (GDPR), we might be both Data &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:30.7829em;"><span class="stl_15 stl_08 stl_28" style="word-spacing:0.1224em;">Controller and Data Processor (including Data Subprocessor) depending on the nature of the &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:31.8412em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.0052em;">processing of your personal data. Please read on to find out about our different roles. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:35.4734em;"><span class="stl_21 stl_08 stl_22">2. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.1em;top:35.4734em;"><span class="stl_21 stl_08 stl_19">W</span><span class="stl_23 stl_08 stl_18" style="word-spacing:0.0782em;">HAT PERSONAL DATA WE PROCESS AND HOW &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:37.7912em;"><span class="stl_15 stl_08 stl_24" style="word-spacing:0.2687em;">Personal data is information that relates to an identified or identifiable natural person. An &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:38.8412em;"><span class="stl_15 stl_08 stl_18" style="word-spacing:0.165em;">identifiable natural person is one who can be identified, directly or indirectly, in particular by &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:39.8995em;"><span class="stl_15 stl_08 stl_18" style="word-spacing:0.1727em;">reference to an identifier such as a name, an identification number, location data, an online &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:40.9495em;"><span class="stl_15 stl_08 stl_24" style="word-spacing:0.2598em;">identifier or to one or more factors specific to the physical, physiological, genetic, mental, &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:42.0079em;"><span class="stl_15 stl_08 stl_28" style="word-spacing:0.0811em;">economic, cultural or social identity of that natural person. Personal data does not include any &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:43.0579em;"><span class="stl_15 stl_08 stl_28" style="word-spacing:0.1631em;">anonymized and depersonalized data where an identity is absent or has been removed and &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:44.1162em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.0062em;">cannot be recovered. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:46.2245em;"><span class="stl_15 stl_08 stl_24" style="word-spacing:0.0145em;">Below are the categories of information, including personal data that we may collect or share for &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:47.2745em;"><span class="stl_15 stl_08 stl_24" style="word-spacing:0.0056em;">a business purpose as permitted by law and depending on the Services you receive: &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:49.3829em;"><span class="stl_29 stl_08 stl_20" style="word-spacing:0.0003em;">(a) Information provided voluntarily by you: &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:51.4412em;"><span class="stl_29 stl_08 stl_11" style="word-spacing:0.5147em;">Contact information</span><span class="stl_15 stl_08 stl_25" style="word-spacing:0.5143em;">, such as name, business email address, phone number, billing &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:52.4912em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.0915em;">information, physical address, Zoom / WhatsApp / Skype and similar services information, call &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:53.5495em;"><span class="stl_15 stl_08 stl_27" style="word-spacing:0.0528em;">recordings, information you input in open text fields in help/contact us forms. Depending on the &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:54.5995em;"><span class="stl_15 stl_08 stl_18" style="word-spacing:0.1223em;">Services you use, you might choose to add a display name, profile photo, job title, and other &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:55.6579em;"><span class="stl_15 stl_08 stl_16" style="word-spacing:0.0025em;">details to your profile. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:57.2079em;"><span class="stl_29 stl_08 stl_11" style="word-spacing:0.0237em;">Content information</span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.0193em;">, such the information you may choose to post, send, receive and share in &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:58.2662em;"><span class="stl_15 stl_08 stl_18" style="word-spacing:0.0193em;">our Services. When you use our Services, we collect Personal Data that is included in the input, &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:59.3162em;"><span class="stl_15 stl_08 stl_28" style="word-spacing:0.0048em;">file uploads, or feedback that you provide to our Services (“Content”). &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:60.8745em;"><span class="stl_29 stl_08 stl_11" style="word-spacing:0.1463em;">Customer support information</span><span class="stl_15 stl_08 stl_28" style="word-spacing:0.1434em;">, such a summary of the problem you are experiencing, and &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:61.9245em;"><span class="stl_15 stl_08 stl_18" style="word-spacing:0.0468em;">any other documentation or information that would be helpful in resolving an issue submitted to &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:62.9829em;"><span class="stl_15 stl_08 stl_20">us. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div id="1_0" style="position:absolute;left:10.0875em;top:35.3551em;">&nbsp;</div>\n' +
    "\t\t\t\t</div>\n" +
    "\t\t\t</div>\n" +
    "\t\t</div>\n" +
    '\t\t<div class="stl_02">\n' +
    '\t\t\t<div class="stl_view">\n' +
    '\t\t\t\t<div class="stl_05 stl_06">\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:4.7412em;"><span class="stl_29 stl_08 stl_30" style="word-spacing:0.1534em;">Social Media Information</span><span class="stl_15 stl_08 stl_24" style="word-spacing:0.1588em;">: We have pages on social media sites like Instagram, Facebook, &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:5.7995em;"><span class="stl_15 stl_08 stl_25" style="word-spacing:0.0693em;">Medium, X, YouTube and LinkedIn. When you interact with our social media pages, we collect &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:6.8495em;"><span class="stl_15 stl_08 stl_16" style="word-spacing:0.2252em;">Personal Data that you choose to provide to us, such as your contact details (collectively, &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:7.9079em;"><span class="stl_15 stl_08 stl_18" style="word-spacing:0.1149em;">“Social Media Information”). In addition, the companies that host our social media pages may &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:8.9579em;"><span class="stl_15 stl_08 stl_18" style="word-spacing:0.0074em;">provide us with aggregate information and analytics about our social media activity. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:10.5162em;"><span class="stl_29 stl_08 stl_18" style="word-spacing:0.0287em;">Other Information You Provide: </span><span class="stl_15 stl_08 stl_27" style="word-spacing:0.0239em;">We collect other information that you may provide to us, such &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:11.5662em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.0178em;">as when you participate in our events or surveys or provide us with information to establish your &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:12.6245em;"><span class="stl_15 stl_08 stl_28" style="word-spacing:0.0057em;">age or identity (collectively, “Other Information You Provide”). &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:14.1745em;"><span class="stl_29 stl_08 stl_16" style="word-spacing:0.0036em;">(b) Information we collect automatically from you: &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:16.2329em;"><span class="stl_29 stl_08 stl_09" style="word-spacing:0.2007em;">Analytics information</span><span class="stl_15 stl_08 stl_26" style="word-spacing:0.211em;">, such as when you visit and interact with any of our Services. This &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:17.2829em;"><span class="stl_15 stl_08 stl_17" style="word-spacing:0.034em;">information includes the features you use; the links you click on; the type, size and filenames of &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:18.3412em;"><span class="stl_15 stl_08 stl_28" style="word-spacing:0.0139em;">attachments you upload to the Services; frequently used search terms; and your interaction with &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:19.3912em;"><span class="stl_15 stl_08 stl_13" style="word-spacing:0.0023em;">third-party integrations and others on the Services. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:20.9495em;"><span class="stl_29 stl_08 stl_31" style="word-spacing:0.3093em;">Device information</span><span class="stl_15 stl_08 stl_28" style="word-spacing:0.3127em;">, your connection type and settings, information about your operating &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:21.9995em;"><span class="stl_15 stl_08 stl_28" style="word-spacing:0.1499em;">system, browser type, IP address, URLs of referring/exit pages, device identifiers, and crash &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:23.0579em;"><span class="stl_15 stl_08 stl_18" style="word-spacing:0.0082em;">activity, collected through cookies, web beacons and similar technologies. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:24.6079em;"><span class="stl_29 stl_08 stl_16" style="word-spacing:0.0743em;">Cookies and Similar Technologies: </span><span class="stl_15 stl_08 stl_28" style="word-spacing:0.0713em;">We use cookies and similar technologies to operate and &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:25.6662em;"><span class="stl_15 stl_08 stl_16" style="word-spacing:0.0048em;">administer our Services, and improve your experience. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:27.2162em;"><span class="stl_29 stl_08 stl_25" style="word-spacing:0.2956em;">(c) Personal Data we Receive From Other Sources: </span><span class="stl_15 stl_08 stl_24" style="word-spacing:0.2912em;">We collect information from other &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:28.2745em;"><span class="stl_15 stl_08 stl_25" style="word-spacing:0.1477em;">sources, like information that is publicly available on the internet, in particular to develop the &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:29.3245em;"><span class="stl_15 stl_08 stl_28" style="word-spacing:0.0265em;">models that power our Services. We also receive information from our trusted partners, such as &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:30.3829em;"><span class="stl_15 stl_08 stl_14" style="word-spacing:0.0977em;">security partners to protect against fraud, abuse, and other security threats to our Services or &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:31.4329em;"><span class="stl_15 stl_08 stl_18" style="word-spacing:0.1056em;">marketing vendors who provide us with information about potential customers of our business &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:32.4912em;"><span class="stl_15 stl_08 stl_20">services. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:35.0734em;"><span class="stl_21 stl_08 stl_22">3. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.1em;top:35.0734em;"><span class="stl_21 stl_08 stl_14">H</span><span class="stl_23 stl_08 stl_32" style="word-spacing:0.1603em;">OW WE USE YOUR PERSONAL DATA AND WHICH LEGAL BASIS WE RELY &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.1em;top:36.6106em;"><span class="stl_23 stl_08 stl_16">ON &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:38.7245em;"><span class="stl_15 stl_08 stl_14" style="word-spacing:0.0027em;">We may use Personal Data for the following purposes: &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:8.6em;top:40.4495em;"><span class="stl_33 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:8.6em;top:41.4995em;"><span class="stl_33 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:8.6em;top:42.5579em;"><span class="stl_33 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.1em;top:40.4495em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.0047em;">To provide and maintain our Services; &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.1em;top:41.4995em;"><span class="stl_15 stl_08 stl_24" style="word-spacing:0.0055em;">To improve and develop our Services and new features and conduct research; &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.1em;top:42.5579em;"><span class="stl_15 stl_08 stl_14" style="word-spacing:0.2029em;">To communicate with you, including to send you information or marketing about our &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.1em;top:43.6079em;"><span class="stl_15 stl_08 stl_14" style="word-spacing:0.0039em;">Services and events; &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:8.6em;top:44.6662em;"><span class="stl_33 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:8.6em;top:46.7745em;"><span class="stl_33 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.1em;top:44.6662em;"><span class="stl_15 stl_08 stl_16" style="word-spacing:0.0125em;">To prevent fraud, criminal activity, or misuses of our Services, and to protect the security &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.1em;top:45.7162em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.005em;">of our systems and Services; and &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.1em;top:46.7745em;"><span class="stl_15 stl_08 stl_24" style="word-spacing:0.0682em;">To comply with legal obligations and to protect the rights, privacy, safety, or property of &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.1em;top:47.8245em;"><span class="stl_15 stl_08 stl_28" style="word-spacing:0.004em;">our users, us, our affiliates, or any third party. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:49.5495em;"><span class="stl_15 stl_08 stl_28" style="word-spacing:0.117em;">Aggregated or De-Identified Information. We aggregate or de-identify Personal Data so that it &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:50.5995em;"><span class="stl_15 stl_08 stl_16" style="word-spacing:0.0977em;">can no longer be used to identify you and use this information to analyze the effectiveness of &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:51.6579em;"><span class="stl_15 stl_08 stl_20" style="word-spacing:0.1148em;">our Services, to improve and add features to our Services, to conduct research and for other &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:52.7079em;"><span class="stl_15 stl_08 stl_18" style="word-spacing:0.0103em;">similar purposes. In addition, from time to time, we may share or publish aggregated information &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:53.7662em;"><span class="stl_15 stl_08 stl_16" style="word-spacing:0.1204em;">like general user statistics with third parties. We collect this information through the Services, &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:54.8162em;"><span class="stl_15 stl_08 stl_27" style="word-spacing:0.0081em;">through cookies, and through other means described in this Privacy Policy. We will maintain and &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:55.8745em;"><span class="stl_15 stl_08 stl_24" style="word-spacing:0.0908em;">use de-identified information in anonymous or de-identified form and we will not attempt to re- &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:56.9245em;"><span class="stl_15 stl_08 stl_16" style="word-spacing:0.0037em;">identify the information, unless required by law. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:58.6495em;"><span class="stl_15 stl_08 stl_16" style="word-spacing:0.0881em;">As noted above, we use Content you provide us to improve our Services, for example to train &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:59.6995em;"><span class="stl_15 stl_08 stl_16" style="word-spacing:0.0028em;">the models that power our Services. &nbsp;</span></div>\n' +
    "\t\t\t\t</div>\n" +
    "\t\t\t</div>\n" +
    "\t\t</div>\n" +
    '\t\t<div class="stl_02">\n' +
    '\t\t\t<div class="stl_03">\n' +
    '\t\t\t\t<object data="privacy_files/img_02.svg" type="image/svg+xml" class="stl_04" style="position:absolute; width:49.5833em; height:70.0833em;">\n' +
    '\t\t\t\t\t<embed src="privacy_files/img_02.svg" type="image/svg+xml" />\n' +
    "\t\t\t\t</object>\n" +
    "\t\t\t</div>\n" +
    '\t\t\t<div class="stl_view">\n' +
    '\t\t\t\t<div class="stl_05 stl_06">\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.7167em;top:5.4329em;"><span class="stl_29 stl_08 stl_24" style="word-spacing:0.0044em;">Type of personal data &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:22.7167em;top:5.4329em;"><span class="stl_29 stl_08 stl_27">Purposes &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:39.625em;top:5.4329em;"><span class="stl_29 stl_08 stl_28" style="word-spacing:0.0081em;">Legal basis &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.7167em;top:7.7995em;"><span class="stl_33 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.7167em;top:8.8495em;"><span class="stl_33 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.7167em;top:9.9079em;"><span class="stl_33 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.7167em;top:10.9579em;"><span class="stl_33 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.7167em;top:12.0162em;"><span class="stl_33 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.7167em;top:13.0662em;"><span class="stl_33 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.7167em;top:14.1245em;"><span class="stl_33 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.2167em;top:7.7995em;"><span class="stl_15 stl_08 stl_35" style="word-spacing:-0.0008em;">Contact information &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.2167em;top:8.8495em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.0084em;">Content information &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.2167em;top:9.9079em;"><span class="stl_15 stl_08 stl_14" style="word-spacing:0.0031em;">Customer support information &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.2167em;top:10.9579em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.005em;">Other information you provide &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.2167em;top:12.0162em;"><span class="stl_15 stl_08 stl_14" style="word-spacing:0.0046em;">Analytics information &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:39.625em;top:10.8495em;"><span class="stl_15 stl_08 stl_16">Performance &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:39.625em;top:11.8995em;"><span class="stl_15 stl_08 stl_28" style="word-spacing:0.428em;">of a contract &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:39.625em;top:12.9579em;"><span class="stl_15 stl_08 stl_25" style="word-spacing:0.0045em;">with you &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:24.2167em;top:11.0277em;"><span class="stl_36 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:25.7167em;top:10.9579em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.3381em;">To provide and maintain our &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:25.7167em;top:12.0995em;"><span class="stl_15 stl_08 stl_24">Services &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.2167em;top:13.0662em;"><span class="stl_15 stl_08 stl_25" style="word-spacing:0.0127em;">Device information &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.2167em;top:14.1245em;"><span class="stl_15 stl_08 stl_37">Cookies &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:14.8322em;top:14.1245em;"><span class="stl_15 stl_08 stl_18">and &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:18.6648em;top:14.1245em;"><span class="stl_15 stl_08 stl_38">Similar &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.2167em;top:15.2579em;"><span class="stl_15 stl_08 stl_39">Technologies &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.7167em;top:18.3745em;"><span class="stl_33 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.7167em;top:19.4329em;"><span class="stl_33 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.7167em;top:20.4829em;"><span class="stl_33 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.7167em;top:21.5412em;"><span class="stl_33 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.7167em;top:22.5912em;"><span class="stl_33 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.7167em;top:23.6495em;"><span class="stl_33 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.7167em;top:24.6995em;"><span class="stl_33 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.2167em;top:18.3745em;"><span class="stl_15 stl_08 stl_35" style="word-spacing:-0.0008em;">Contact information &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.2167em;top:19.4329em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.0084em;">Content information &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.2167em;top:20.4829em;"><span class="stl_15 stl_08 stl_14" style="word-spacing:0.0031em;">Customer support information &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.2167em;top:21.5412em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.005em;">Other information you provide &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.2167em;top:22.5912em;"><span class="stl_15 stl_08 stl_14" style="word-spacing:0.0046em;">Analytics information &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.2167em;top:23.6495em;"><span class="stl_15 stl_08 stl_25" style="word-spacing:0.0127em;">Device information &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.2167em;top:24.6995em;"><span class="stl_15 stl_08 stl_24" style="word-spacing:0.4565em;">Personal Data We Receive &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.2167em;top:25.7579em;"><span class="stl_15 stl_08 stl_17" style="word-spacing:0.0081em;">From Other Sources &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:22.4667em;top:22.5527em;"><span class="stl_36 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:23.9667em;top:22.4829em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.8211em;">To improve and develop our &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:23.9667em;top:23.5329em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.5004em;">Services and new features and &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:23.9667em;top:24.5912em;"><span class="stl_15 stl_08 stl_28" style="word-spacing:0.0082em;">conduct research &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:39.625em;top:23.0079em;"><span class="stl_15 stl_08 stl_27" style="word-spacing:0.144em;">Our legitimate &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:39.625em;top:24.0662em;"><span class="stl_15 stl_08 stl_09">interests &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.7167em;top:26.8079em;"><span class="stl_33 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.2167em;top:26.8079em;"><span class="stl_15 stl_08 stl_37">Cookies &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:14.8322em;top:26.8079em;"><span class="stl_15 stl_08 stl_18">and &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:18.6648em;top:26.8079em;"><span class="stl_15 stl_08 stl_38">Similar &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.2167em;top:27.9495em;"><span class="stl_15 stl_08 stl_39">Technologies &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.7167em;top:31.0662em;"><span class="stl_33 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.7167em;top:32.1162em;"><span class="stl_33 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.7167em;top:33.1745em;"><span class="stl_33 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.7167em;top:34.3079em;"><span class="stl_33 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.7167em;top:35.3662em;"><span class="stl_33 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.7167em;top:36.4162em;"><span class="stl_33 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.7167em;top:37.4745em;"><span class="stl_33 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.2167em;top:31.0662em;"><span class="stl_15 stl_08 stl_35" style="word-spacing:-0.0008em;">Contact information &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:39.625em;top:32.0495em;"><span class="stl_15 stl_08 stl_16">Performance &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:39.625em;top:33.0995em;"><span class="stl_15 stl_08 stl_28" style="word-spacing:0.428em;">of a contract &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:39.625em;top:34.1579em;"><span class="stl_15 stl_08 stl_18" style="word-spacing:0.064em;">with you, or, if &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.2167em;top:32.1162em;"><span class="stl_15 stl_08 stl_14" style="word-spacing:0.0031em;">Customer support information &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.2167em;top:33.1745em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.0065em;">Social Media Information &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.2167em;top:34.3079em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.005em;">Other information you provide &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.2167em;top:35.3662em;"><span class="stl_15 stl_08 stl_14" style="word-spacing:0.0046em;">Analytics information &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.2167em;top:36.4162em;"><span class="stl_15 stl_08 stl_25" style="word-spacing:0.0127em;">Device information &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.2167em;top:37.4745em;"><span class="stl_15 stl_08 stl_24" style="word-spacing:0.4565em;">Personal Data We Receive &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.2167em;top:38.5245em;"><span class="stl_15 stl_08 stl_17" style="word-spacing:0.0081em;">From Other Sources &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:22.4667em;top:35.2777em;"><span class="stl_36 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:23.9667em;top:35.2079em;"><span class="stl_15 stl_08 stl_32">To &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:26.5398em;top:35.2079em;"><span class="stl_15 stl_08 stl_22">communicate &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:33.4945em;top:35.2079em;"><span class="stl_15 stl_08 stl_39">with &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:36.6332em;top:35.2079em;"><span class="stl_15 stl_08 stl_14" style="word-spacing:1.0996em;">you, required &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:23.9667em;top:36.2662em;"><span class="stl_15 stl_08 stl_18" style="word-spacing:0.2776em;">including to send you information</span><span class="stl_15 stl_08 stl_34" style="word-spacing:1.112em;">&nbsp;</span><span class="stl_15 stl_08 stl_18">under &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:23.9667em;top:37.3162em;"><span class="stl_15 stl_08 stl_18" style="word-spacing:0.391em;">or marketing about our Services</span><span class="stl_15 stl_08 stl_34" style="word-spacing:1.1032em;">&nbsp;</span><span class="stl_15 stl_08 stl_24">applicable &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:23.9667em;top:38.3745em;"><span class="stl_15 stl_08 stl_35" style="word-spacing:0.0028em;">and events &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:39.625em;top:38.3745em;"><span class="stl_15 stl_08 stl_14" style="word-spacing:0.8996em;">law, to the &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:39.625em;top:39.4245em;"><span class="stl_15 stl_08 stl_26" style="word-spacing:1.7646em;">extent you &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.7167em;top:39.5829em;"><span class="stl_33 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.2167em;top:39.5829em;"><span class="stl_15 stl_08 stl_37">Cookies &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:14.8322em;top:39.5829em;"><span class="stl_15 stl_08 stl_18">and &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:18.6648em;top:39.5829em;"><span class="stl_15 stl_08 stl_38">Similar &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:39.625em;top:40.4829em;"><span class="stl_15 stl_08 stl_28" style="word-spacing:0.0838em;">have provided &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:39.625em;top:41.5329em;"><span class="stl_15 stl_08 stl_16" style="word-spacing:-0.0013em;">your consent &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.2167em;top:40.7162em;"><span class="stl_15 stl_08 stl_39">Technologies &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.7167em;top:44.8912em;"><span class="stl_33 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.7167em;top:45.9412em;"><span class="stl_33 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.7167em;top:46.9995em;"><span class="stl_33 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.7167em;top:48.0495em;"><span class="stl_33 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.7167em;top:49.1912em;"><span class="stl_33 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.7167em;top:50.2412em;"><span class="stl_33 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.7167em;top:51.2995em;"><span class="stl_33 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.7167em;top:52.3495em;"><span class="stl_33 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.2167em;top:44.8912em;"><span class="stl_15 stl_08 stl_35" style="word-spacing:-0.0008em;">Contact information &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.2167em;top:45.9412em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.0084em;">Content information &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.2167em;top:46.9995em;"><span class="stl_15 stl_08 stl_14" style="word-spacing:0.0031em;">Customer support information &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.2167em;top:48.0495em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.0065em;">Social Media Information &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.2167em;top:49.1912em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.005em;">Other information you provide &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.2167em;top:50.2412em;"><span class="stl_15 stl_08 stl_14" style="word-spacing:0.0046em;">Analytics information &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.2167em;top:51.2995em;"><span class="stl_15 stl_08 stl_25" style="word-spacing:0.0127em;">Device information &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.2167em;top:52.3495em;"><span class="stl_15 stl_08 stl_24" style="word-spacing:0.4565em;">Personal Data We Receive &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.2167em;top:53.4079em;"><span class="stl_15 stl_08 stl_17" style="word-spacing:0.0081em;">From Other Sources &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:22.4667em;top:49.1027em;"><span class="stl_36 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:23.9667em;top:49.0329em;"><span class="stl_15 stl_08 stl_16" style="word-spacing:0.1933em;">To prevent fraud, criminal activity, &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:23.9667em;top:50.0912em;"><span class="stl_15 stl_08 stl_14" style="word-spacing:0.1024em;">or misuses of our Services, and to &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:23.9667em;top:51.1412em;"><span class="stl_15 stl_08 stl_11" style="word-spacing:0.095em;">protect the security of our systems &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:23.9667em;top:52.1995em;"><span class="stl_15 stl_08 stl_24" style="word-spacing:0.0042em;">and Services &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:39.625em;top:49.5662em;"><span class="stl_15 stl_08 stl_32">To &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:42.5483em;top:49.5662em;"><span class="stl_15 stl_08 stl_28">comply &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:39.625em;top:50.6162em;"><span class="stl_15 stl_08 stl_32" style="word-spacing:0.6812em;">with a legal &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:39.625em;top:51.6745em;"><span class="stl_15 stl_08 stl_39">obligation &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.7167em;top:54.4579em;"><span class="stl_33 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.2167em;top:54.4579em;"><span class="stl_15 stl_08 stl_37">Cookies &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:14.8322em;top:54.4579em;"><span class="stl_15 stl_08 stl_18">and &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:18.6648em;top:54.4579em;"><span class="stl_15 stl_08 stl_38">Similar &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.2167em;top:55.5995em;"><span class="stl_15 stl_08 stl_39">Technologies &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.7167em;top:58.7162em;"><span class="stl_33 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.7167em;top:59.7662em;"><span class="stl_33 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.7167em;top:60.8245em;"><span class="stl_33 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.7167em;top:61.8745em;"><span class="stl_33 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.7167em;top:63.0162em;"><span class="stl_33 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.2167em;top:58.7162em;"><span class="stl_15 stl_08 stl_35" style="word-spacing:-0.0008em;">Contact information &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.2167em;top:59.7662em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.0084em;">Content information &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.2167em;top:60.8245em;"><span class="stl_15 stl_08 stl_14" style="word-spacing:0.0031em;">Customer support information &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.2167em;top:61.8745em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.0065em;">Social Media Information &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:22.4667em;top:58.786em;"><span class="stl_36 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:23.9667em;top:58.7162em;"><span class="stl_15 stl_08 stl_17" style="word-spacing:0.4059em;">To comply with legal obligations</span><span class="stl_15 stl_08 stl_34" style="word-spacing:1.1115em;">&nbsp;</span><span class="stl_15 stl_08 stl_32">To &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:42.5483em;top:58.7162em;"><span class="stl_15 stl_08 stl_28">comply &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:23.9667em;top:59.7662em;"><span class="stl_15 stl_08 stl_16" style="word-spacing:0.2425em;">and to protect the rights, privacy,</span><span class="stl_15 stl_08 stl_34" style="word-spacing:1.1109em;">&nbsp;</span><span class="stl_15 stl_08 stl_32" style="word-spacing:0.6812em;">with a legal &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:23.9667em;top:60.8245em;"><span class="stl_15 stl_08 stl_24" style="word-spacing:0.0362em;">safety, or property of our users, us,</span><span class="stl_15 stl_08 stl_34" style="word-spacing:1.1223em;">&nbsp;</span><span class="stl_15 stl_08 stl_39">obligation &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:23.9667em;top:61.8745em;"><span class="stl_15 stl_08 stl_35" style="word-spacing:0.002em;">our affiliates, or any third party &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.2167em;top:63.0162em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.005em;">Other information you provide &nbsp;</span></div>\n' +
    "\t\t\t\t</div>\n" +
    "\t\t\t</div>\n" +
    "\t\t</div>\n" +
    '\t\t<div class="stl_02">\n' +
    '\t\t\t<div class="stl_03">\n' +
    '\t\t\t\t<object data="privacy_files/img_03.svg" type="image/svg+xml" class="stl_04" style="position:absolute; width:49.5833em; height:70.0833em;">\n' +
    '\t\t\t\t\t<embed src="privacy_files/img_03.svg" type="image/svg+xml" />\n' +
    "\t\t\t\t</object>\n" +
    "\t\t\t</div>\n" +
    '\t\t\t<div class="stl_view">\n' +
    '\t\t\t\t<div class="stl_05 stl_06">\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.7167em;top:5.4329em;"><span class="stl_33 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.7167em;top:6.4828em;"><span class="stl_33 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.7167em;top:7.5412em;"><span class="stl_33 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.2167em;top:5.4329em;"><span class="stl_15 stl_08 stl_14" style="word-spacing:0.0046em;">Analytics information &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.2167em;top:6.4828em;"><span class="stl_15 stl_08 stl_25" style="word-spacing:0.0127em;">Device information &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.2167em;top:7.5412em;"><span class="stl_15 stl_08 stl_24" style="word-spacing:0.4565em;">Personal Data We Receive &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.2167em;top:8.5912em;"><span class="stl_15 stl_08 stl_17" style="word-spacing:0.0081em;">From Other Sources &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.7167em;top:9.6495em;"><span class="stl_33 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.2167em;top:9.6495em;"><span class="stl_15 stl_08 stl_37">Cookies &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:14.8322em;top:9.6495em;"><span class="stl_15 stl_08 stl_18">and &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:18.6648em;top:9.6495em;"><span class="stl_15 stl_08 stl_38">Similar &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.2167em;top:10.7829em;"><span class="stl_15 stl_08 stl_39">Technologies &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:14.8067em;"><span class="stl_21 stl_08 stl_22">4. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.1em;top:14.8067em;"><span class="stl_21 stl_08 stl_14">C</span><span class="stl_23 stl_08 stl_40">HILDREN</span><span class="stl_21 stl_08 stl_41">’</span><span class="stl_23 stl_08 stl_28" style="word-spacing:0.0659em;">S PRIVACY &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:17.1162em;"><span class="stl_15 stl_08 stl_34" style="word-spacing:0.0608em;">We do not knowingly collect or ask for information from minors. Children (as defined under the &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:18.2579em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.0721em;">applicable data privacy laws) may only access or use our services with the consent and under &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:19.3912em;"><span class="stl_15 stl_08 stl_14" style="word-spacing:0.0281em;">the supervision of a parent or legal guardian who agrees to be bound by the Policy. If you are a &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:20.5329em;"><span class="stl_15 stl_08 stl_28" style="word-spacing:0.0308em;">parent or legal guardian of a child, you agree to be fully responsible for the acts or omissions of &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:21.6662em;"><span class="stl_15 stl_08 stl_14" style="word-spacing:0.0028em;">such user in connection with access to and use of the Services. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:24.3317em;"><span class="stl_21 stl_08 stl_22">5. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.1em;top:24.3317em;"><span class="stl_21 stl_08 stl_14">R</span><span class="stl_23 stl_08 stl_42">ETENTION &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:26.6495em;"><span class="stl_15 stl_08 stl_28" style="word-spacing:0.0126em;">We retain personal data about you necessary to fulfil the purpose for which that information was &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:27.6995em;"><span class="stl_15 stl_08 stl_18" style="word-spacing:0.0085em;">collected, consistent with applicable laws. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:29.8079em;"><span class="stl_15 stl_08 stl_18" style="word-spacing:0.0051em;">How long we retain Personal Data will depend on a number of factors, such as: &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:8.6em;top:31.9162em;"><span class="stl_33 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.1em;top:31.9162em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.163em;">Our purpose for processing the data (such as whether we need to retain the data to &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.1em;top:32.9745em;"><span class="stl_15 stl_08 stl_16" style="word-spacing:0.0052em;">provide our Services); &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:8.6em;top:34.0245em;"><span class="stl_33 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:8.6em;top:35.0829em;"><span class="stl_33 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:8.6em;top:36.1329em;"><span class="stl_33 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.1em;top:34.0245em;"><span class="stl_15 stl_08 stl_28" style="word-spacing:0.0047em;">The amount, nature, and sensitivity of the data; &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.1em;top:35.0829em;"><span class="stl_15 stl_08 stl_28" style="word-spacing:0.0037em;">The potential risk of harm from unauthorized use or disclosure of the data; &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.1em;top:36.1329em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.0053em;">Any legal requirements that we are subject to. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:38.2412em;"><span class="stl_15 stl_08 stl_28" style="word-spacing:0.1384em;">When we destroy your personal data, we do so in a way that prevents that information from &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:39.2995em;"><span class="stl_15 stl_08 stl_16" style="word-spacing:0.0046em;">being restored or reconstructed. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:41.8817em;"><span class="stl_21 stl_08 stl_22">6. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.1em;top:41.8817em;"><span class="stl_21 stl_08 stl_14">D</span><span class="stl_23 stl_08 stl_19" style="word-spacing:0.0763em;">ISCLOSURE OF PERSONAL DATA &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:44.6912em;"><span class="stl_15 stl_08 stl_24" style="word-spacing:0.0054em;">In certain circumstances we may disclose your Personal Data to: &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.1em;top:47.2495em;"><span class="stl_15 stl_08 stl_24" style="word-spacing:0.0512em;">Vendors and Service Providers: To assist us in meeting business operations needs and &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.1em;top:48.2995em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.1495em;">to perform certain services and functions, we may disclose Personal Data to vendors &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.1em;top:49.3579em;"><span class="stl_15 stl_08 stl_14" style="word-spacing:0.0084em;">and service providers, including providers of hosting services, customer service vendors, &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.1em;top:50.4079em;"><span class="stl_15 stl_08 stl_16" style="word-spacing:0.1035em;">cloud services, content delivery services, data warehouse services, support and safety &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.1em;top:51.4662em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.2204em;">monitoring services, email communication software, web analytics services, payment &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.1em;top:52.5162em;"><span class="stl_15 stl_08 stl_28" style="word-spacing:0.0064em;">and transaction providers, and other information technology services providers. Pursuant &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.1em;top:53.5745em;"><span class="stl_15 stl_08 stl_16" style="word-spacing:0.0397em;">to our instructions, these parties will access, process, or store Personal Data only in the &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.1em;top:54.6245em;"><span class="stl_15 stl_08 stl_28" style="word-spacing:0.004em;">course of performing their duties to us. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.1em;top:57.1829em;"><span class="stl_15 stl_08 stl_28" style="word-spacing:0.4899em;">Business Transfers: If we are involved in strategic transactions, reorganization, &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.1em;top:58.2329em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.2877em;">bankruptcy, receivership, or transition of service to another provider (collectively, a &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.1em;top:59.2912em;"><span class="stl_15 stl_08 stl_14" style="word-spacing:0.3159em;">“Transaction”), your Personal Data and other information may be disclosed in the &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.1em;top:60.3412em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.2556em;">diligence process with counterparties and others assisting with the Transaction and &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.1em;top:61.3995em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.0052em;">transferred to a successor or affiliate as part of that Transaction along with other assets. &nbsp;</span></div>\n' +
    "\t\t\t\t</div>\n" +
    "\t\t\t</div>\n" +
    "\t\t</div>\n" +
    '\t\t<div class="stl_02">\n' +
    '\t\t\t<div class="stl_03">\n' +
    '\t\t\t\t<object data="privacy_files/img_04.svg" type="image/svg+xml" class="stl_04" style="position:absolute; width:49.5833em; height:70.0833em;">\n' +
    '\t\t\t\t\t<embed src="privacy_files/img_04.svg" type="image/svg+xml" />\n' +
    "\t\t\t\t</object>\n" +
    "\t\t\t</div>\n" +
    '\t\t\t<div class="stl_view">\n' +
    '\t\t\t\t<div class="stl_05 stl_06">\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.1em;top:4.7412em;"><span class="stl_15 stl_08 stl_20" style="word-spacing:0.2471em;">Government Authorities or Other Third Parties: We may share your Personal Data, &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.1em;top:5.7995em;"><span class="stl_15 stl_08 stl_18" style="word-spacing:0.4938em;">including information about your interaction with our Services, with government &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.1em;top:6.8495em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.0406em;">authorities, industry peers, or other third parties in compliance with the law (i) if required &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.1em;top:7.9079em;"><span class="stl_15 stl_08 stl_18" style="word-spacing:0.044em;">to do so by law or in the good faith belief that such action is necessary to comply with a &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.1em;top:8.9579em;"><span class="stl_15 stl_08 stl_24" style="word-spacing:0.0902em;">legal obligation, (ii) to protect and defend our rights or property, (iii) if we determine, in &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.1em;top:10.0162em;"><span class="stl_15 stl_08 stl_27" style="word-spacing:0.0107em;">our sole discretion, that there is a violation of our terms, policies, or the law; (iv) to detect &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.1em;top:11.0662em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.0261em;">or prevent fraud or other illegal activity; (v) to protect the safety, security, and integrity of &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.1em;top:12.1245em;"><span class="stl_15 stl_08 stl_27" style="word-spacing:0.0071em;">our products, employees, or users, or the public, or (vi) to protect against legal liability. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.1em;top:14.6745em;"><span class="stl_15 stl_08 stl_24" style="word-spacing:0.2998em;">Affiliates: We may disclose Personal Data to our affiliates, meaning an entity that &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.1em;top:15.7329em;"><span class="stl_15 stl_08 stl_18" style="word-spacing:0.0281em;">controls, is controlled by, or is under common control with Aracor. Our affiliates may use &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.1em;top:16.7829em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.0039em;">the Personal Data we share in a manner consistent with this Privacy Policy. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.1em;top:19.3412em;"><span class="stl_15 stl_08 stl_18" style="word-spacing:0.0193em;">Other Users and Third Parties You Share Information With: Certain features allow you to &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.1em;top:20.3912em;"><span class="stl_15 stl_08 stl_16" style="word-spacing:0.2665em;">display or share information with other users. For example, you may share Aracor &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.1em;top:21.4495em;"><span class="stl_15 stl_08 stl_35" style="word-spacing:0.3462em;">conversations with other users via shared links or send information to third-party &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.1em;top:22.4995em;"><span class="stl_15 stl_08 stl_25" style="word-spacing:0.0091em;">applications via custom actions. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:25.0817em;"><span class="stl_21 stl_08 stl_22">7. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.1em;top:25.0817em;"><span class="stl_21 stl_08 stl_14">D</span><span class="stl_23 stl_08 stl_17" style="word-spacing:0.0757em;">ATA SECURITY &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:27.3995em;"><span class="stl_15 stl_08 stl_28" style="word-spacing:0.0041em;">The security of your personal information is important to us. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:29.5079em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.0147em;">We follow generally accepted security standards and ae have adopted commercially reasonable &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:30.5579em;"><span class="stl_15 stl_08 stl_20" style="word-spacing:0.021em;">security measures to protect your personal data against accidental or unlawful destruction, loss, &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:31.6162em;"><span class="stl_15 stl_08 stl_20" style="word-spacing:0.0559em;">alteration, or unauthorized disclosure or access. We may use third-party products and services &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:32.6662em;"><span class="stl_15 stl_08 stl_35" style="word-spacing:-0.0007em;">to secure or store your information. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:34.7745em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.1855em;">Depending on where you live, you may have a legal right to be notified in case of security &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:35.8329em;"><span class="stl_15 stl_08 stl_35">breach. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:37.9412em;"><span class="stl_15 stl_08 stl_28" style="word-spacing:0.0535em;">We may, subject to applicable law, transfer your information outside the country where you are &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:38.9912em;"><span class="stl_15 stl_08 stl_20" style="word-spacing:0.0563em;">located and to where information protection standards may differ (e.g. your information may be &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:40.0495em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.0102em;">stored on servers located in other jurisdictions). We will utilise appropriate safeguards governing &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:41.0995em;"><span class="stl_15 stl_08 stl_16" style="word-spacing:0.0038em;">the transfer and usage of your personal information. See Section 8 for further information. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:43.2079em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.1174em;">We will never request your account credentials. You should never share your Aracor account &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:44.2662em;"><span class="stl_15 stl_08 stl_28" style="word-spacing:0.0184em;">information, including your username and password, with anyone else. We recommend that you &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:45.3162em;"><span class="stl_15 stl_08 stl_24" style="word-spacing:0.0553em;">use a unique password for your Aracor account that is not associated with other websites. You &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:46.3745em;"><span class="stl_15 stl_08 stl_14" style="word-spacing:0.1662em;">should check your Aracor account regularly to ensure that your personal data has not been &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:47.4245em;"><span class="stl_15 stl_08 stl_28" style="word-spacing:0.0047em;">tampered with or altered. You should only use the Services within a secure environment. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:49.5329em;"><span class="stl_15 stl_08 stl_28" style="word-spacing:0.0393em;">Overall, if you have any reason to believe that your interactions with the Services are no longer &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:50.5912em;"><span class="stl_15 stl_08 stl_13" style="word-spacing:0.0047em;">secure, please notify us immediately at privacy@aracor.ai. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:53.1734em;"><span class="stl_21 stl_08 stl_22">8. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.1em;top:53.1734em;"><span class="stl_21 stl_08 stl_14">D</span><span class="stl_23 stl_08 stl_26" style="word-spacing:0.0761em;">ATA TRANSFERS &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:55.4829em;"><span class="stl_15 stl_08 stl_28" style="word-spacing:0.0306em;">Your personal data may be transferred to, and processed in, countries other than the country in &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:56.5412em;"><span class="stl_15 stl_08 stl_28" style="word-spacing:0.2724em;">which you are based, where our affiliates, subsidiaries, partners or our third-party service &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:57.5912em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.0052em;">providers operate. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:59.6995em;"><span class="stl_15 stl_08 stl_14" style="word-spacing:0.1281em;">Some of these countries might not be considered as </span><a href="https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_en" target="_blank"><span class="stl_15 stl_08 stl_13" style="word-spacing:0.1278em;">adequate </span></a><span class="stl_15 stl_08 stl_28" style="word-spacing:0.1235em;">by the European Commission &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:60.7579em;"><span class="stl_15 stl_08 stl_20" style="word-spacing:0.2472em;">and therefore not offer an adequate level of protection and security. As a result, we take &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:61.8079em;"><span class="stl_15 stl_08 stl_14" style="word-spacing:0.3569em;">measures to ensure that your information is safe and protected in compliance with the &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:62.8662em;"><span class="stl_15 stl_08 stl_28" style="word-spacing:0.0051em;">applicable data protection laws, including: &nbsp;</span></div>\n' +
    "\t\t\t\t</div>\n" +
    "\t\t\t</div>\n" +
    "\t\t</div>\n" +
    '\t\t<div class="stl_02">\n' +
    '\t\t\t<div class="stl_view">\n' +
    '\t\t\t\t<div class="stl_05 stl_06">\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.95em;top:4.811em;"><span class="stl_36 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.95em;top:6.9193em;"><span class="stl_36 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.95em;top:9.0277em;"><span class="stl_36 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:12.45em;top:4.7412em;"><span class="stl_15 stl_08 stl_16" style="word-spacing:0.4083em;">reliance of Standard Contractual Clauses to transfer your data outside the &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:12.45em;top:5.7995em;"><span class="stl_15 stl_08 stl_25" style="word-spacing:0.0089em;">European Economic Area, United Kingdom and Switzerland; &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:12.45em;top:6.8495em;"><span class="stl_15 stl_08 stl_28" style="word-spacing:0.0329em;">implementation of organisational, contractual and technical security measures with &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:12.45em;top:7.9079em;"><span class="stl_15 stl_08 stl_26" style="word-spacing:0.0093em;">Our third-party service providers; &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:12.45em;top:8.9579em;"><span class="stl_15 stl_08 stl_14" style="word-spacing:0.1665em;">establishment of an intra-group data transfer agreement for internal transfers of &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:12.45em;top:10.0162em;"><span class="stl_15 stl_08 stl_27" style="word-spacing:0.0071em;">personal data within Our group of companies around the world. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:12.1245em;"><span class="stl_15 stl_08 stl_28" style="word-spacing:0.2721em;">By using our Services, you understand and acknowledge that your Personal Data will be &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:13.1745em;"><span class="stl_15 stl_08 stl_16" style="word-spacing:0.0483em;">processed and stored in our facilities and servers in the United States and may be disclosed to &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:14.2329em;"><span class="stl_15 stl_08 stl_24" style="word-spacing:0.0055em;">our service providers and affiliates in other jurisdictions. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:16.8127em;"><span class="stl_43 stl_44 stl_34">9. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:10.1em;top:16.815em;"><span class="stl_21 stl_08 stl_45">Y</span><span class="stl_23 stl_08 stl_28" style="word-spacing:0.0765em;">OUR PRIVACY RIGHTS &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:19.1495em;"><span class="stl_15 stl_08 stl_16" style="word-spacing:0.2034em;">You may make certain choices regarding your personal data as permitted under applicable &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:20.2079em;"><span class="stl_15 stl_08 stl_18" style="word-spacing:0.0468em;">privacy laws. You may make these choices free of charge except as otherwise permitted under &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:21.2579em;"><span class="stl_15 stl_08 stl_28" style="word-spacing:0.0707em;">applicable law. We may ask you to further confirm your identity and limit Our response to your &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:22.3162em;"><span class="stl_15 stl_08 stl_28" style="word-spacing:0.0052em;">requests as permitted under applicable law. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:24.4245em;"><span class="stl_29 stl_08 stl_24" style="word-spacing:0.0104em;">Individual Privacy Rights &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:25.4745em;"><span class="stl_15 stl_08 stl_24" style="word-spacing:0.0035em;">Your privacy rights include: &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:26.6027em;"><span class="stl_36 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.4583em;top:26.5329em;"><span class="stl_29 stl_08 stl_28" style="word-spacing:0.0599em;">Right to be Informed: </span><span class="stl_15 stl_08 stl_28" style="word-spacing:0.0577em;">You have the right to be informed about the collection and use of &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.4583em;top:27.5829em;"><span class="stl_15 stl_08 stl_13" style="word-spacing:0.003em;">your personal data. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:28.711em;"><span class="stl_36 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.4583em;top:28.6412em;"><span class="stl_29 stl_08 stl_24" style="word-spacing:0.0535em;">Right to Access: </span><span class="stl_15 stl_08 stl_19" style="word-spacing:0.0491em;">You have the right to view and request copies of your personal data. If &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.4583em;top:29.6912em;"><span class="stl_15 stl_08 stl_18" style="word-spacing:0.0141em;">you have an online account or profile with us, you may access your personal data via your &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.4583em;top:30.7495em;"><span class="stl_15 stl_08 stl_24" style="word-spacing:0.0054em;">account through the website or mobile app. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:31.8693em;"><span class="stl_36 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:37.1443em;"><span class="stl_36 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.4583em;top:31.7995em;"><span class="stl_29 stl_08 stl_28" style="word-spacing:0.0243em;">Right to Rectify or Correct: </span><span class="stl_15 stl_08 stl_28" style="word-spacing:0.0215em;">You have the right to update the personal data that we have &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.4583em;top:32.8579em;"><span class="stl_15 stl_08 stl_28" style="word-spacing:0.1262em;">collected about your directly or indirectly, including personal data collected by a service &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.4583em;top:33.9079em;"><span class="stl_15 stl_08 stl_24" style="word-spacing:0.068em;">provider or contractor on our behalf. If you have an online account or profile with us, you &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.4583em;top:34.9662em;"><span class="stl_15 stl_08 stl_13" style="word-spacing:0.2604em;">may update your personal data by accessing your account through the website and &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.4583em;top:36.0162em;"><span class="stl_15 stl_08 stl_17" style="word-spacing:0.005em;">mobile app. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.4583em;top:37.0745em;"><span class="stl_29 stl_08 stl_19" style="word-spacing:0.173em;">Right to Request Erasure (Right to be Forgotten): </span><span class="stl_15 stl_08 stl_18" style="word-spacing:0.1677em;">You may request that we delete &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.4583em;top:38.1245em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.0815em;">your personal data, subject to certain limited exceptions. For example, we may retain an &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.4583em;top:39.1829em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.3492em;">archived copy of your records consistent with applicable law, to comply with legal &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.4583em;top:40.2329em;"><span class="stl_15 stl_08 stl_24" style="word-spacing:0.4868em;">obligations, or for other legitimate business purposes. We will use commercially &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.4583em;top:41.2912em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.0057em;">reasonable efforts to honour your requests. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:42.411em;"><span class="stl_36 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:44.5193em;"><span class="stl_36 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:46.6277em;"><span class="stl_36 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:48.736em;"><span class="stl_36 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:50.8443em;"><span class="stl_36 stl_08 stl_34"></span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.4583em;top:42.3412em;"><span class="stl_29 stl_08 stl_19" style="word-spacing:0.1544em;">Right to Data Portability: </span><span class="stl_15 stl_08 stl_19" style="word-spacing:0.1487em;">You have the right to request that we transfer data that we &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.4583em;top:43.3995em;"><span class="stl_15 stl_08 stl_18" style="word-spacing:0.0065em;">have collected to another organization, or directly to you under certain conditions. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.4583em;top:44.4495em;"><span class="stl_29 stl_08 stl_24" style="word-spacing:0.3618em;">Right to Restrict Processing: </span><span class="stl_15 stl_08 stl_13" style="word-spacing:0.3508em;">You have the right to request that we restrict the &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.4583em;top:45.5079em;"><span class="stl_15 stl_08 stl_14" style="word-spacing:0.0035em;">processing of your personal data under certain conditions. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.4583em;top:46.5579em;"><span class="stl_29 stl_08 stl_19" style="word-spacing:0.0755em;">Right to Withdraw Consent: </span><span class="stl_15 stl_08 stl_14" style="word-spacing:0.0704em;">You have the right to withdraw previously granted consent &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.4583em;top:47.6162em;"><span class="stl_15 stl_08 stl_22" style="word-spacing:0.0008em;">to process your personal data. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.4583em;top:48.6662em;"><span class="stl_29 stl_08 stl_16" style="word-spacing:0.124em;">Right to Object to Processing: </span><span class="stl_15 stl_08 stl_18" style="word-spacing:0.1226em;">You have the right to object to our processing of your &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.4583em;top:49.7245em;"><span class="stl_15 stl_08 stl_14" style="word-spacing:0.0025em;">personal data, under certain conditions. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.4583em;top:50.7745em;"><span class="stl_29 stl_08 stl_16" style="word-spacing:0.1636em;">Right to Object to Automated Processing: </span><span class="stl_15 stl_08 stl_18" style="word-spacing:0.1579em;">You have the right to object to decisions &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.4583em;top:51.8329em;"><span class="stl_15 stl_08 stl_27" style="word-spacing:0.0294em;">being made with your information solely based on automated decision making or profiling. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:9.4583em;top:52.8829em;"><span class="stl_15 stl_08 stl_28" style="word-spacing:0.0048em;">We do not currently engage in automated decision-making or profiling. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:54.9912em;"><span class="stl_15 stl_08 stl_20" style="word-spacing:0.0043em;">To exercise any of your above rights you may submit your request at privacy@aracor.ai. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:57.0995em;"><span class="stl_29 stl_08 stl_14" style="word-spacing:0.0051em;">Aracor Service-related Communications &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:58.1579em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.0757em;">We may send a welcome email to clients for billing purposes, and at times may send Service- &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:59.2079em;"><span class="stl_15 stl_08 stl_28" style="word-spacing:0.0443em;">related announcements. A client may not opt out of service-related emails, as this is part of our &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:60.2662em;"><span class="stl_15 stl_08 stl_28">Services. &nbsp;</span></div>\n' +
    "\t\t\t\t</div>\n" +
    "\t\t\t</div>\n" +
    "\t\t</div>\n" +
    '\t\t<div class="stl_02">\n' +
    '\t\t\t<div class="stl_view">\n' +
    '\t\t\t\t<div class="stl_05 stl_06">\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:4.7412em;"><span class="stl_29 stl_08 stl_20" style="word-spacing:0.1372em;">Marketing Communications. </span><span class="stl_15 stl_08 stl_19" style="word-spacing:0.135em;">You may choose to stop receiving our newsletter or marketing &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:5.7995em;"><span class="stl_15 stl_08 stl_24" style="word-spacing:0.1646em;">emails or texts by following the unsubscribe instructions included in each of these emails or &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:6.8495em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.0054em;">texts, or you can contact us. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:8.9579em;"><span class="stl_29 stl_08 stl_19" style="word-spacing:0.0039em;">Privacy Questions and Concerns &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:10.0162em;"><span class="stl_15 stl_08 stl_28" style="word-spacing:0.0054em;">To speak with us or file a complaint, please contact us at privacy@aracor.ai. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:12.1245em;"><span class="stl_15 stl_08 stl_16" style="word-spacing:0.2481em;">You may also lodge a complaint with your supervisory authority. If you are and individual &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:13.1745em;"><span class="stl_15 stl_08 stl_25" style="word-spacing:0.2012em;">located in the EU, UK or Switzerland, please consult the below websites for the applicable &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:14.2329em;"><span class="stl_15 stl_08 stl_14" style="word-spacing:0.0047em;">supervisory authority: &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:15.2829em;"><a href="https://edpb.europa.eu/about-edpb/about-edpb/members_en" target="_blank"><span class="stl_15 stl_08 stl_31">https://edpb.europa.eu/about-edpb/about-edpb/members_en &nbsp;</span></a></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:16.3412em;"><a href="https://www.edoeb.admin.ch/edoeb/en/home.html" target="_blank"><span class="stl_15 stl_08 stl_31">https://www.edoeb.admin.ch/edoeb/en/home.html. &nbsp;</span></a></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:17.3912em;"><a href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/" target="_blank"><span class="stl_15 stl_08 stl_34">https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/. &nbsp;</span></a></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:21.0317em;"><span class="stl_21 stl_08 stl_46" style="word-spacing:0.9164em;">10. T</span><span class="stl_23 stl_08 stl_25" style="word-spacing:0.0794em;">HIRD PARTY LINKS AND APPLICATIONS &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:23.3412em;"><span class="stl_15 stl_08 stl_25" style="word-spacing:0.0231em;">Our website may include link to other websites whose privacy practices may differ from those of &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:24.3995em;"><span class="stl_15 stl_08 stl_14" style="word-spacing:0.1847em;">Aracor. If you submit information to any of those sites, your information is governed by the &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:25.4495em;"><span class="stl_15 stl_08 stl_28" style="word-spacing:0.0399em;">privacy policies that apply to those sites. We encourage you to carefully read the privacy notice &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:26.5079em;"><span class="stl_15 stl_08 stl_26" style="word-spacing:0.0082em;">of any website or application you visit. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:29.1401em;"><span class="stl_21 stl_08 stl_47" style="word-spacing:0.9145em;">11. C</span><span class="stl_23 stl_08 stl_26" style="word-spacing:0.0761em;">HANGES TO OUR </span><span class="stl_21 stl_08 stl_48">P</span><span class="stl_23 stl_08 stl_39" style="word-spacing:0.0798em;">RIVACY </span><span class="stl_21 stl_08 stl_49">P</span><span class="stl_23 stl_08 stl_27">OLICY &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:31.4579em;"><span class="stl_15 stl_08 stl_16" style="word-spacing:0.1056em;">We reserve the right to amend this Policy at our discretion and at any time. We will do so by &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:32.5079em;"><span class="stl_15 stl_08 stl_18" style="word-spacing:0.1144em;">updating this Policy. Amended terms take effect upon being incorporated into this Policy, and &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:33.5662em;"><span class="stl_15 stl_08 stl_13" style="word-spacing:0.0028em;">your continued use of the website constitutes acceptance of any updated terms. &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:36.1984em;"><span class="stl_21 stl_08 stl_47" style="word-spacing:0.9145em;">12. C</span><span class="stl_23 stl_08 stl_25" style="word-spacing:0.0729em;">ONTACT INFORMATION &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:38.5162em;"><span class="stl_15 stl_08 stl_18" style="word-spacing:0.1651em;">If you have questions or comments about this Policy, our other privacy notices, the ways in &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:39.6495em;"><span class="stl_15 stl_08 stl_27" style="word-spacing:0.0341em;">which we collect and use your information, your choices, and rights regarding such use, wish to &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:40.7912em;"><span class="stl_15 stl_08 stl_19" style="word-spacing:0.622em;">exercise your rights under GDPR, or want to message us please contact us at &nbsp;</span></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:41.9245em;"><a href="mailto:privacy@aracor.ai." target="_blank"><span class="stl_15 stl_08 stl_34">privacy@aracor.ai. &nbsp;</span></a></div>\n' +
    '\t\t\t\t\t<div class="stl_01" style="left:7.1em;top:45.5329em;"><span class="stl_15 stl_08 stl_20" style="word-spacing:0.0028em;">Last updated: February 26, 2024 &nbsp;</span></div>\n' +
    "\t\t\t\t</div>\n" +
    "\t\t\t</div>\n" +
    "\t\t</div>\n" +
    "\t</body>\n" +
    "</html>",
};

export const Privacy = () => {
  return (
    <section className="grid grid-cols-[minmax(0,1fr)_auto_minmax(0,1fr)] h-dvh w-dvw bg-ar-dark">
      <div className="h-12 w-1/2 m-4 border-b border-b-white/10">
        <Link to={ROUTES.APP} className="font-['FinancierText'] text-2xl">
          <span className="text-white">Aracor</span>
          <span className="text-ar-accent">.</span>
        </Link>
      </div>
      <ScrollArea>
        <div dangerouslySetInnerHTML={markup} className="bg-white" />
      </ScrollArea>
    </section>
  );
};
